import { BatteryHalfIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { ChargeLevelUnit } from 'src/containers/charge-levels/types';
import { EnergyUnit, formatEnergy, formatPercents } from 'src/utils/units';

import Preference from '../Preference/Preference';
import Property from '../Property/Property';

interface Props {
  minimum?: number;
  maximum?: number;
  unit: ChargeLevelUnit;
  onEditPress: VoidFunction;
}

const ChargeLevels: FC<Props> = ({ minimum, maximum, unit, onEditPress }) => {
  const intl = useIntl();

  const formatValue = (value: number) => (unit === '%' ? formatPercents(value) : formatEnergy(value, EnergyUnit.KWH));

  return (
    <Preference
      title={intl.formatMessage({ id: 'common.deviceOverview.chargeLevels.title' })}
      icon={BatteryHalfIcon}
      onEditPress={onEditPress}
    >
      <Property
        name={intl.formatMessage({ id: 'common.deviceOverview.chargeLevels.minimum' })}
        value={minimum === undefined ? undefined : formatValue(minimum)}
      />
      <Property
        name={intl.formatMessage({ id: 'common.deviceOverview.chargeLevels.maximum' })}
        value={maximum === undefined ? undefined : formatValue(maximum)}
      />
    </Preference>
  );
};

export default React.memo(ChargeLevels);
