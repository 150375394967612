import { Time } from '@hiven-energy/hiven-client';

import { WeekDay } from 'src/hooks/useWeekDays';

export const parseReadyTime = (weekDays: [WeekDay, string][], time: Record<WeekDay, Time>): [Time, string][] => {
  return Object.entries<number[]>(
    weekDays.reduce((acc, [weekDayType], currentIndex) => {
      const weekDayTime = time[weekDayType];
      const aggregatedTime = acc[weekDayTime];
      if (currentIndex === 0 || !aggregatedTime) {
        acc[weekDayTime] = [currentIndex];
      } else {
        acc[weekDayTime] = [...aggregatedTime, currentIndex];
      }

      return acc;
    }, {} as Record<Time, number[]>),
  ).map(([weekDayTime, days]) => {
    if (days.length === 1) return [weekDayTime as Time, `${weekDays[days[0]][1]}`];

    const daysRange = daysToRanges(days);

    return [
      weekDayTime as Time,
      daysRange
        .map(range =>
          range.length === 1
            ? weekDays[range[0]][1]
            : `${weekDays[range[0]][1]} - ${weekDays[range[range.length - 1]][1]}`,
        )
        .join(', '),
    ];
  });
};

const daysToRanges = (days: number[]) => {
  return days.reduce<number[][]>((acc, day) => {
    if (day === 0) {
      acc.push([day]);
    } else {
      const group = acc.find(gr => gr.includes(day - 1));
      if (group) {
        group.push(day);
      } else {
        acc.push([day]);
      }
    }

    return acc;
  }, []);
};
