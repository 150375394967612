import { Container as _Container, spacings, TouchableOpacityContainer, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import _Divider from 'src/components/Divider/Divider';
import { colors } from 'src/theme';

export const Container = styled(_Container)<{ $pressed: boolean; $open: boolean }>`
  gap: ${spacings.xxxxs}px;
  margin-top: ${spacings.xxs}px;
  padding: ${spacings.xxs}px;
  border-width: 1px;
  border-radius: 12px;
  border-color: ${p => (p.$pressed || p.$open ? colors.aqua : colors.pidgeonGray)};
`;

export const Label = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.stoneGrey};
`;

export const Header = styled(_Container)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Name = styled(Typography).attrs({ variant: 'h4' })`
  flex: 1;
  padding-left: ${spacings.xxxs}px;
  padding-right: ${spacings.xxxs}px;
`;

export const Divider = styled(_Divider)`
  margin-top: ${spacings.xxs}px;
  margin-bottom: ${spacings.xs}px;
`;

export const ChargerDetailsButton = styled(TouchableOpacityContainer).attrs({ activeOpacity: 0.5 })`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ChargerDetailsLabelWrapper = styled(_Container)`
  flex-direction: row;
  align-items: center;
  gap: ${spacings.xxxs}px;
`;

export const ChargerDetailsLabel = styled(Typography).attrs({ fontWeight: 'medium' })`
  font-size: 16px;
  line-height: 24px;
`;
