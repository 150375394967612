import { useState } from 'react';

import { useInterval } from './useInterval';

export const usePeriodicalUpdate = (interval: number) => {
  const [, setValue] = useState(false);

  useInterval(() => {
    setValue(v => !v);
  }, interval);
};
