import { VehicleChargingBehavior } from '@hiven-energy/hiven-client';
import { FlashIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PreferenceOverview from 'src/components/PreferenceOverview/PreferenceOverview';
import { MessageId } from 'src/i18n';

import * as styled from './styles';

interface Props {
  value: VehicleChargingBehavior;
  disabled: boolean;
  onEditPress: VoidFunction;
}

const chargingBehaviorToMessageId: Record<VehicleChargingBehavior, MessageId> = {
  [VehicleChargingBehavior.SMART_CHARGE_ANYWHERE]: 'vehicle.chargingBehavior.SMART_CHARGE_ANYWHERE',
  [VehicleChargingBehavior.SMART_CHARGE_AT_LOCATION]: 'vehicle.chargingBehavior.SMART_CHARGE_AT_LOCATION',
  [VehicleChargingBehavior.SMART_CHARGE_OFF]: 'vehicle.chargingBehavior.SMART_CHARGE_OFF',
};

const ChargingBehavior: FC<Props> = ({ value, disabled, onEditPress }) => {
  const intl = useIntl();

  return (
    <PreferenceOverview
      title={intl.formatMessage({ id: 'VehiclePreferences.chargingBehavior.overview.title' })}
      icon={FlashIcon}
      onEditPress={onEditPress}
      analyticsPlace="VehiclePreferences.chargingBehavior"
      disabledText={
        disabled ? intl.formatMessage({ id: 'VehiclePreferences.chargingBehavior.disabled.text' }) : undefined
      }
    >
      <styled.BehaviorText $disabled={disabled}>
        <FormattedMessage id={chargingBehaviorToMessageId[value]} />
      </styled.BehaviorText>
    </PreferenceOverview>
  );
};

export default React.memo(ChargingBehavior);
