import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const Container = styled(_Container)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Label = styled(Typography)`
  margin-left: ${spacings.xxxs}px;
`;
