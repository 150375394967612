import { MIXPANEL_TOKEN, PARTNER } from '@env';
import mixpanel from 'mixpanel-browser';
import { MixpanelProperties } from 'mixpanel-react-native';

import { CommonAnalyticsManager } from './analyticsManager';
import { debugAnalytics } from './consts';
import { MixpanelEvents } from './mixpanelEvents';
import { AnalyticsManager, MixpanelEventProps } from './types';

class MixpanelManager extends CommonAnalyticsManager implements AnalyticsManager {
  constructor() {
    super();
    mixpanel.init(MIXPANEL_TOKEN, { debug: debugAnalytics, ignore_dnt: debugAnalytics });
    mixpanel.set_config({ persistence: 'localStorage' });
    mixpanel.register_once({ Partner: PARTNER });
  }

  track<T extends MixpanelEvents>(type: T, props?: MixpanelEventProps<T> & MixpanelProperties) {
    mixpanel.track(type, props);
  }

  timeEvent(event: MixpanelEvents) {
    mixpanel.time_event(event);
  }

  reset() {
    mixpanel.reset();
  }

  identify(userId: string): Promise<void> {
    return mixpanel.identify(userId);
  }

  setPeople(people: { $email: string; $name: string }) {
    mixpanel.people.set(people);
  }
}

export const createMixpanelManager = () => new MixpanelManager();
