import { Language } from 'src/i18n';

export enum CognitoAction {
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  SEND_CODE = 'sendCode',
  RESEND_CODE = 'resendCode',
  RESET_PASSWORD = 'resetPassword',
  CHANGE_PASSWORD = 'changePassword',
  CONFIRM_SIGN_UP = 'confirmSignUp',
}

export interface UserAttributes {
  userId: string;
  email: string;
  language: Language;
  termsAndConditions: boolean;
  privacyPolicy: boolean;
}

export interface SignOutOptions {
  global?: boolean;
}
