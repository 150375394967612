import { Device, Vehicle } from '@hiven-energy/hiven-client';
import { spacings } from '@hiven-energy/hiven-ui';
import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FlatList, ListRenderItem, RefreshControl } from 'react-native';

import { useA11y } from 'src/a11y';
import BaseView from 'src/components/BaseView/BaseView';
import { usePartner } from 'src/hooks/usePartner';
import { useUserRefresh } from 'src/hooks/useUserRefresh';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useDevices } from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';
import { colors } from 'src/theme';
import { isCharger, isVehicle } from 'src/utils/device';

import DeviceItem from './DeviceItem/DeviceItem';
import * as styled from './styles';
import { filterOutAssociatedChargers } from './utils';

type Props = ScreenProps<RouteId.MyVehiclesAndChargers>;

export const MyVehiclesAndChargers: FC<Props> = ({ navigation }) => {
  const intl = useIntl();
  const a11y = useA11y();
  const partner = usePartner();
  const { trackRefresh } = useAnalytics();

  const devicesQuery = useDevices();
  const { data: devices } = devicesQuery;

  const items = useMemo(() => (devices ? filterOutAssociatedChargers(devices) : []), [devices]);

  const refresh = useCallback(async () => {
    trackRefresh();
    await devicesQuery.refetch();
  }, [devicesQuery.refetch, trackRefresh]);

  const [refreshing, handleRefresh] = useUserRefresh(refresh);

  const handleDevicePress = useCallback(
    (device: Device) => {
      if (isVehicle(device)) {
        navigation.navigate(RouteId.VehicleOverview, { deviceId: device.id });
      } else if (isCharger(device)) {
        navigation.navigate(RouteId.ChargerOverview, { deviceId: device.id });
      }
    },
    [navigation],
  );

  const handleNewDevicePress = () => {
    if (partner.isWallbox || partner.isUniperSe) {
      navigation.navigate(RouteId.ChargerBrands);
    } else {
      navigation.navigate(RouteId.BrandGroups);
    }
  };

  const prepareVehicleChips = useCallback(
    (device: Vehicle) =>
      device.associatedChargerIds
        .map(id => {
          const charger = devices?.find(device => device.id === id);
          return charger?.attributes.name;
        })
        .filter(Boolean) as string[],
    [devices],
  );

  const renderItem: ListRenderItem<Device> = useCallback(
    ({ item: device }) => {
      const chips = isVehicle(device) ? prepareVehicleChips(device) : [];
      return <DeviceItem device={device} chips={chips} onPress={() => handleDevicePress(device)} />;
    },
    [prepareVehicleChips, handleDevicePress],
  );

  return (
    <BaseView>
      <FlatList
        data={items}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        contentContainerStyle={{ paddingTop: spacings.xs, paddingHorizontal: spacings.xs }}
        refreshControl={
          <RefreshControl tintColor={colors.deepNavy} refreshing={refreshing} onRefresh={handleRefresh} />
        }
      />
      <styled.NewDeviceButton
        title={intl.formatMessage({ id: 'common.addDevice' })}
        testID={a11y.formatLabel('common.addDevice')}
        onPress={handleNewDevicePress}
      />
    </BaseView>
  );
};

const keyExtractor = (device: Device) => device.id;
