import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScrollView } from 'react-native';

import * as styled from './styles';

interface Props {
  refreshControl: JSX.Element;
}

export const Empty: FC<Props> = ({ refreshControl }) => (
  <ScrollView
    showsVerticalScrollIndicator={false}
    refreshControl={refreshControl}
    contentContainerStyle={{ alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}
  >
    <styled.Title variant="h3">
      <FormattedMessage id="deviceList.empty.title" />
    </styled.Title>
    <styled.Text>
      <FormattedMessage id="deviceList.empty.text" />
    </styled.Text>
  </ScrollView>
);
