import { Device, DeviceType } from '@hiven-energy/hiven-client';
import groupBy from 'lodash/groupBy';

import { deviceTypeByOrder, isCharger, isVehicle } from 'src/utils/device';

import { DeviceSection } from './types';

export const getAssociatedChargerIds = (devices: Device[]): string[] =>
  devices.filter(isVehicle).flatMap(device => device.associatedChargerIds);

export const filterOutAssociatedChargers = (devices: Device[], associatedChargerIds: string[]): Device[] =>
  devices.filter(device => (isCharger(device) ? !associatedChargerIds.includes(device.id) : true));

export const prepareDeviceSections = (devices: Device[]): DeviceSection[] => {
  const associatedChargerIds = getAssociatedChargerIds(devices);
  const filteredDevices = filterOutAssociatedChargers(devices, associatedChargerIds);
  const groupedByType = groupBy(filteredDevices, device => device.type);
  return deviceTypeByOrder
    .map(deviceType => ({
      type: deviceType,
      data: groupedByType[deviceType] || [],
    }))
    .filter(({ data }) => data.length);
};

export const deviceTypeToSectionHeaderMap: Record<DeviceType, string> = {
  [DeviceType.VEHICLE]: 'deviceList.vehicleSectionHeader',
  [DeviceType.CHARGER]: 'deviceList.chargerSectionHeader',
};
