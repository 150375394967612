import { Button, ContainerStyle, Modal, spacings } from '@hiven-energy/hiven-ui';
import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import { useAnalytics } from 'src/services/analytics';
import { colors, MAX_CONTENT_WIDTH } from 'src/theme';

import * as styled from './styles';

interface Props {
  title: string;
  content: string;
  confirmLabel?: string;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
}

const ConfirmationModal: FC<Props> = ({ title, content, onConfirm, onCancel, confirmLabel }) => {
  const intl = useIntl();
  const a11y = useA11y();
  const { trackButtonClick } = useAnalytics();

  const modalRootStyle: ContainerStyle = { maxWidth: MAX_CONTENT_WIDTH };

  const handleCancel = useCallback(() => {
    trackButtonClick('common.cancel');
    onCancel();
  }, [onCancel, trackButtonClick]);

  const handleConfirm = useCallback(() => {
    trackButtonClick('common.confirm');
    onConfirm();
  }, [onConfirm, trackButtonClick]);

  return (
    <Modal visible title={title} onClose={onCancel} styles={{ root: modalRootStyle }}>
      {content && <styled.Content>{content}</styled.Content>}
      <styled.Actions>
        <Button
          styles={{
            title: {
              marginLeft: spacings.xxs,
              marginRight: spacings.xxs,
            },
          }}
          type="tertiary"
          title={intl.formatMessage({ id: 'common.cancel' })}
          onPress={handleCancel}
          testID={a11y.formatLabel('common.cancel')}
        />
        <Button
          colors={{
            enabled: { titleColor: colors.raspberryPink },
            pressed: {
              titleColor: colors.white,
              borderColor: colors.raspberryPink,
              backgroundColor: colors.raspberryPink,
            },
          }}
          styles={{
            title: {
              marginLeft: spacings.xxs,
              marginRight: spacings.xxs,
            },
          }}
          type="tertiary"
          title={confirmLabel ?? intl.formatMessage({ id: 'common.confirm' })}
          onPress={handleConfirm}
          testID={a11y.formatLabel('common.confirm')}
        />
      </styled.Actions>
    </Modal>
  );
};

export default React.memo(ConfirmationModal);
