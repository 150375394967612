import { IntlShape } from 'react-intl';
import isStrongPassword from 'validator/lib/isStrongPassword';
import { z } from 'zod';

import { formatBatteryCapacityToDisplayValue } from 'src/screens/charger/Preferences/BatteryCapacity/utils';

const CODE_LENGTH = 6;

export const email = (intl: IntlShape) =>
  z
    .string({
      required_error: intl.formatMessage({ id: 'validation.required' }),
    })
    .email(intl.formatMessage({ id: 'validation.email' }));

export const password = (intl: IntlShape, disableStrengthValidation: boolean) =>
  z
    .string({
      required_error: intl.formatMessage({ id: 'validation.required' }),
    })
    .min(8, intl.formatMessage({ id: 'validation.password.minLength' }))
    .refine(
      password => disableStrengthValidation || isStrongPassword(password),
      intl.formatMessage({ id: 'validation.password.strength' }),
    );

export const code = (intl: IntlShape) =>
  z
    .string({
      required_error: intl.formatMessage({ id: 'validation.required' }),
    })
    .length(CODE_LENGTH, intl.formatMessage({ id: 'validation.code.invalidLength' }))
    .regex(/^[0-9]+$/, intl.formatMessage({ id: 'validation.code.invalidFormat' }));

export const batteryCapacity = (intl: IntlShape, minValue: number, maxValue: number) =>
  z
    .string()
    .min(1, { message: intl.formatMessage({ id: 'validation.batteryCapacity.required' }) })
    .superRefine((value, ctx) => {
      const numericValue = formatBatteryCapacityToDisplayValue(value);

      if (numericValue < minValue) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intl.formatMessage({ id: 'validation.batteryCapacity.minValue' }, { value: minValue }),
          fatal: true,
        });

        return z.NEVER;
      }

      if (numericValue > maxValue) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intl.formatMessage({ id: 'validation.batteryCapacity.maxValue' }, { value: maxValue }),
        });
      }
    });
