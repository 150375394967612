import { Container as _Container, IconButton, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  flex-direction: row;
  align-items: center;
`;

export const ActionButton = IconButton;

export const Title = styled(Typography)`
  margin-left: ${spacings.xxs}px;
  color: ${colors.white};
`;
