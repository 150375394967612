import {
  Button,
  ChevronRightIcon,
  LanguageIcon,
  Link,
  ProfileIcon,
  SignOutIcon,
  TermsIcon,
  Typography,
} from '@hiven-energy/hiven-ui';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';

import BaseView from 'src/components/BaseView/BaseView';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import { ScreenScrollView } from 'src/components/ScreenScrollView/ScreenScrollView';
import { Language } from 'src/i18n';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useDeleteAccount, useSignOut } from 'src/queries/session';
import { useAnalytics } from 'src/services/analytics';
import { useHivenClient } from 'src/services/hiven';
import { useSession } from 'src/store/session';
import { colors } from 'src/theme';

import * as styled from './styles';

type Props = ScreenProps<RouteId.Account>;

const Account: FC<Props> = ({ navigation }) => {
  const intl = useIntl();
  const hivenClient = useHivenClient();
  const session = useSession();
  const toast = useToast();
  const { trackButtonClick, trackDeleteAccount, trackLoggedOut } = useAnalytics();

  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);

  const signOutMutation = useSignOut({
    onSuccess: () => {
      trackLoggedOut('Account.logout');
      session.resetState();
    },
    onError: error => {
      toast.show(intl.formatMessage({ id: 'Account.logout.error' }), { type: 'danger' });
      console.error('Could not sign out', error);
    },
  });

  const deleteAccountMutation = useDeleteAccount();

  const handleDeleteAccountPress = () => {
    trackButtonClick('Account.delete');
    setDeleteAccountModalOpen(true);
  };

  const handleDeleteAccountConfirm = async () => {
    try {
      await hivenClient.signOut(session.userId);
      await deleteAccountMutation.mutateAsync();
      trackDeleteAccount('Account.delete.modal.title');
      setDeleteAccountModalOpen(false);
      signOutMutation.mutate({ global: true });
    } catch (error) {
      console.error('Could not remove user data', error);
      toast.show(intl.formatMessage({ id: 'Account.delete.error' }), { type: 'danger' });
    }
  };

  const handleDeleteAccountCancel = () => {
    setDeleteAccountModalOpen(false);
  };

  const handleLogout = () => {
    signOutMutation.mutate({ global: false });
  };

  return (
    <BaseView>
      <ScreenScrollView>
        <styled.Item>
          <styled.ItemColumn>
            <ProfileIcon />
          </styled.ItemColumn>
          <styled.ItemColumn>
            <Typography fontWeight="semiBold">
              <FormattedMessage id="Account.details" />
            </Typography>
            <styled.Value>{session.email}</styled.Value>
            <Link
              onPress={() => {
                trackButtonClick('Account.changePassword');
                navigation.navigate(RouteId.ChangePassword);
              }}
              styles={{
                title: {
                  fontSize: 14,
                },
              }}
            >
              <FormattedMessage id="Account.changePassword" />
            </Link>
          </styled.ItemColumn>
          <styled.ItemColumn />
        </styled.Item>
        <styled.PressabledItem
          onPress={() => {
            trackButtonClick('Account.changeLanguage');
            navigation.navigate(RouteId.ChangeLanguage);
          }}
        >
          <styled.ItemColumn>
            <LanguageIcon />
          </styled.ItemColumn>
          <styled.ItemColumn>
            <Typography fontWeight="semiBold">
              <FormattedMessage id="Account.changeLanguage" />
            </Typography>
            <styled.Value>
              <FormattedMessage
                id={session.language === Language.EN ? 'common.language.english' : 'common.language.finnish'}
              />
            </styled.Value>
          </styled.ItemColumn>
          <styled.ItemColumn $stretch>
            <ChevronRightIcon color={colors.hintGrey} />
          </styled.ItemColumn>
        </styled.PressabledItem>
        <styled.PressabledItem
          onPress={() => {
            trackButtonClick('Account.consents');
            navigation.navigate(RouteId.Consents);
          }}
        >
          <styled.ItemColumn>
            <TermsIcon />
          </styled.ItemColumn>
          <styled.ItemColumn>
            <Typography fontWeight="semiBold">
              <FormattedMessage id="Account.consents" />
            </Typography>
            <styled.Value>
              <FormattedMessage id="common.termsAndConditions" />
            </styled.Value>
          </styled.ItemColumn>
          <styled.ItemColumn $stretch>
            <ChevronRightIcon color={colors.hintGrey} />
          </styled.ItemColumn>
        </styled.PressabledItem>
        <styled.Actions>
          <styled.DeleteAccountButton
            type="secondary"
            title={intl.formatMessage({ id: 'Account.delete' })}
            onPress={handleDeleteAccountPress}
          />
          <Button
            type="secondary"
            title={intl.formatMessage({ id: 'Account.logout' })}
            onPress={handleLogout}
            rightIcon={SignOutIcon}
            rightIconProps={{
              color: colors.stoneGrey,
            }}
          />
        </styled.Actions>
        {deleteAccountModalOpen && (
          <ConfirmationModal
            title={intl.formatMessage({ id: 'Account.delete.modal.title' })}
            content={intl.formatMessage({ id: 'Account.delete.modal.content' })}
            onConfirm={handleDeleteAccountConfirm}
            onCancel={handleDeleteAccountCancel}
          />
        )}
      </ScreenScrollView>
    </BaseView>
  );
};

export default Account;
