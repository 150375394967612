import { PinIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import Map from 'src/components/Map/Map';
import { defaultRegion } from 'src/screens/vehicle/constants';

import * as styled from './styles';

const DEFAULT_ZOOM = 10;

interface Props {
  onAddLocationPress: VoidFunction;
}

const LocationNotSetView: FC<Props> = ({ onAddLocationPress }) => {
  const intl = useIntl();
  const a11y = useA11y();

  return (
    <styled.EmptyContent
      pointerEvents="box-only"
      onPress={onAddLocationPress}
      testID={a11y.formatLabel('Preferences.chargingLocation.overview.searchMap')}
    >
      <Map region={defaultRegion} initialZoom={DEFAULT_ZOOM} interactive={false} showMarker={false} />
      <styled.AddressInput
        value=""
        leftIcon={PinIcon}
        testID={a11y.formatLabel('Preferences.chargingLocation.overview.input.search')}
        accessibilityLabel={a11y.formatLabel('Preferences.chargingLocation.overview.input.search')}
        placeholder={intl.formatMessage({ id: 'common.preferences.chargingLocation.overview.input.placeholder' })}
      />
    </styled.EmptyContent>
  );
};

export default LocationNotSetView;
