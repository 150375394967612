import { spacings } from '@hiven-energy/hiven-ui';
import { Animated } from 'react-native';
import styled from 'styled-components/native';

export const ScrollView = styled(Animated.ScrollView).attrs({
  contentContainerStyle: {
    width: '100%',
    flexGrow: 1,
    paddingVertical: spacings.xs,
    paddingHorizontal: spacings.s,
  },
})``;
