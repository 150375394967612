import { CheckIcon, Modal, ModalProps } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import backgroundBottom from 'src/assets/chargerConnect/success/backgroundBottom.png';
import backgroundTop from 'src/assets/chargerConnect/success/backgroundTop.png';
import Hexagon from 'src/components/Hexagon/Hexagon';
import { colors } from 'src/theme';

import * as styled from './styles';

const modalStyles: ModalProps['styles'] = {
  overlay: {
    backgroundColor: colors.deepNavy,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  root: {
    backgroundColor: colors.deepNavy,
    height: '100%',
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
};

interface Props {
  onContinue: VoidFunction;
}

const Success: FC<Props> = ({ onContinue }) => {
  const intl = useIntl();

  return (
    <Modal visible styles={modalStyles}>
      <styled.Container>
        <Hexagon color={colors.sunshineYellow}>
          <CheckIcon />
        </Hexagon>
        <styled.Title variant="h1">
          <FormattedMessage id="ChargerConnect.success" />
        </styled.Title>
        <styled.Button type="tertiary" title={intl.formatMessage({ id: 'common.continue' })} onPress={onContinue} />
        <styled.ImageBackgroundTop source={backgroundTop} />
        <styled.ImageBackgroundBottom source={backgroundBottom} />
      </styled.Container>
    </Modal>
  );
};

export default Success;
