import { ErrorMessage } from '@hiven-energy/hiven-ui';
import React, { FC, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

import * as styled from './styles';

interface Props {
  error?: FieldError | false;
  withErrorHeight?: boolean;
  children: ReactNode;
}

const FormField: FC<Props> = ({ error, withErrorHeight = false, children }) => (
  <styled.Container $withError={!!error} $withErrorHeight={withErrorHeight}>
    {children}
    {error && (
      <styled.Error>
        <ErrorMessage style={{ fontSize: 12 }}>{error.message}</ErrorMessage>
      </styled.Error>
    )}
  </styled.Container>
);

export default FormField;
