import { spacings } from '@hiven-energy/hiven-ui';
import { useHeaderHeight } from '@react-navigation/elements';
import React, { FC } from 'react';
import { Platform, ViewProps } from 'react-native';
import { Edge } from 'react-native-safe-area-context';

import * as styled from './styles';

interface Props extends ViewProps {
  safeAreaEdges?: Edge[];
}

const BaseView: FC<Props> = ({ children, safeAreaEdges = ['left', 'right', 'bottom'], ...props }) => {
  const headerHeight = useHeaderHeight();

  return (
    <styled.Container edges={safeAreaEdges}>
      <styled.KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={headerHeight + spacings.s}
        {...props}
      >
        {children}
      </styled.KeyboardAvoidingView>
    </styled.Container>
  );
};

export default BaseView;
