import { Container as _Container, spacings } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  flex-direction: row;
  margin-top: 12px;
  border-left-width: 2px;
  border-left-color: ${colors.disabledGrey};
`;

export const Content = styled(_Container)`
  flex-grow: 1;
  padding-left: ${spacings.xxxs}px;
`;
