import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';

const IS_MOBILE = Platform.OS === 'android' || Platform.OS === 'ios';

export const requestDevicePushToken = async () => {
  let token: string | undefined = undefined;

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#f1f1f1',
    });
  }

  if (IS_MOBILE) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      console.log('Permission to obtain device push token was not granted');
      return;
    }
    token = (await Notifications.getDevicePushTokenAsync()).data;
    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: false,
      }),
    });
  } else {
    console.log('Cannot obtain device push token for not mobile device');
  }

  return token;
};
