import { Container as _Container, Link as _Link, spacings } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const Container = styled(_Container)`
  flex-direction: column;
  justify-content: flex-end;
  padding: ${spacings.xs}px ${spacings.s}px ${spacings.s}px ${spacings.s}px;
`;

export const Link = styled(_Link)`
  margin-bottom: ${spacings.s}px;
  align-items: center;
`;
