import { spacings } from '@hiven-energy/hiven-ui';
import min from 'lodash/min';
import round from 'lodash/round';
import { useWindowDimensions } from 'react-native';

import * as theme from 'src/theme';

interface Options {
  ratio: number;
  maxHeight?: number;
}

export interface RatioDimensions {
  width: number;
  height: number;
}

export const useRatioDimensions = ({ ratio, maxHeight }: Options): RatioDimensions => {
  const windowDimensions = useWindowDimensions();

  const width = min([theme.MAX_CONTENT_WIDTH, windowDimensions.width])! - spacings.xxs;
  const height = min([round(width * ratio), maxHeight])!;

  return { width, height };
};
