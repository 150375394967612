import {
  Checkbox as _Checkbox,
  Container as _Container,
  Link as _Link,
  spacings,
  Typography,
} from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

export const Container = styled(BaseView)`
  padding: ${spacings.s}px;
  flex: 1;
`;

export const Consents = styled(_Container)`
  gap: ${spacings.xs}px;
`;

export const Consent = styled(_Container)`
  padding: ${spacings.xxs}px;
  border: 1px solid ${colors.pidgeonGray};
  border-radius: 12px;
`;

export const Checkbox = styled(_Checkbox).attrs({
  styles: {
    label: {
      fontWeight: '600',
    },
  },
})`
  margin-bottom: ${spacings.xxxs}px;
`;

export const Hint = styled(_Container)`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export const Text = styled(Typography)`
  margin-left: 36px;
  margin-right: ${spacings.xxxxs}px;
  color: ${colors.stoneGrey};
`;

export const Link = styled(_Link).attrs({
  styles: {
    title: {
      color: colors.stoneGrey,
    },
  },
})`
  text-decoration: underline;
  text-decoration-color: ${colors.stoneGrey};
`;

export const Footer = styled(_Container)`
  flex: 1;
  justify-content: flex-end;
`;
