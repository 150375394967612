import { useCallback, useState } from 'react';

export const useUserRefresh = (refresh: () => Promise<unknown>): [boolean, () => Promise<void>] => {
  const [refreshing, setRefreshing] = useState(false);

  const handler = useCallback(async () => {
    setRefreshing(true);
    try {
      await refresh();
    } finally {
      setRefreshing(false);
    }
  }, [setRefreshing, refresh]);

  return [refreshing, handler];
};
