import { Time } from '@hiven-energy/hiven-client';
import dayjs from 'dayjs';
import { IntlShape } from 'react-intl';

export const parseTime = (time: Time) => time.split(':').map(Number) as [number, number];

export const prepareTime = (hour: number, minute: number) =>
  [hour.toString().padStart(2, '0'), minute.toString().padStart(2, '0')].join(':') as Time;

export const prepareTimeToDisplay = (hour: number, minute: number) =>
  [hour.toString(), minute.toString().padStart(2, '0')].join(':') as Time;

export const formatTime = (time: Time) => {
  const [hours, minutes] = time.split(':');
  return `${Number(hours)}:${minutes}`;
};

export const formatSecondsToMinutesAndSeconds = (seconds: number): `${string}:${string}` => {
  const _seconds = seconds < 0 ? 0 : seconds;
  const minutes = Math.floor(_seconds / 60);
  const remainingSeconds = _seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

export const formatRelativeTime = (timestamp: string, intl: IntlShape): string => {
  const now = dayjs();
  const date = dayjs(timestamp);
  const time = date.format('H:mm');

  if (date.isSame(now, 'day')) {
    return intl.formatMessage({ id: 'common.time.relative.today' }, { time });
  }

  if (date.isSame(now.add(1, 'day'), 'day')) {
    return intl.formatMessage({ id: 'common.time.relative.tomorrow' }, { time });
  }

  if (date.isAfter(now)) {
    return intl.formatMessage({ id: 'common.time.relative.daysInFuture' }, { days: date.diff(now, 'day') });
  }

  if (date.isSame(now.subtract(1, 'day'), 'day')) {
    return intl.formatMessage({ id: 'common.time.relative.yesterday' }, { time });
  }

  return intl.formatMessage({ id: 'common.time.relative.daysAgo' }, { days: now.diff(date, 'day') });
};
