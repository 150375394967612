import { Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const Content = styled(Typography)`
  margin-bottom: ${spacings.s}px;
`;

export const Actions = styled(Container)`
  flex-direction: row;
  gap: ${spacings.xxxs}px;
  justify-content: flex-end;
`;
