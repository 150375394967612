import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

import { Language } from 'src/i18n';

export enum DateFormat {
  LOCAL = 'YYYY/MM/DD HH:mm',
  LT = 'h:mm A',
  LLL = 'MMM D, YYYY h:mm A',
  HM = 'HH:mm',
  UTC = 'YYYY-MM-DDTHH:mm:ss[Z]',
}

export const getDate = (dateString?: string) => dayjs(dateString);

export const formatDate = (date: Dayjs, format = DateFormat.LOCAL) =>
  format === DateFormat.UTC ? date.utc().format(format) : date.format(format);

export const diffSeconds = (start: Dayjs, end: Dayjs) => start.diff(end, 'seconds');

export const configureDateLibrary = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(updateLocale);
  dayjs.extend(localeData);
  dayjs.extend(relativeTime, {
    thresholds: [
      { l: 's', r: 1 },
      { l: 'ss', r: 59, d: 'second' },
      { l: 'm', r: 1 },
      { l: 'mm', r: 59, d: 'minute' },
      { l: 'h', r: 1 },
      { l: 'hh', r: 23, d: 'hour' },
      { l: 'd', r: 1 },
      { l: 'dd', r: 30, d: 'day' },
      { l: 'M', r: 1 },
      { l: 'MM', r: 11, d: 'month' },
      { l: 'y', r: 1 },
      { l: 'yy', d: 'year' },
    ],
  });
  dayjs.updateLocale(Language.EN, {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a second',
      ss: '%d seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d days',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years',
    },
  });
  dayjs.updateLocale(Language.FI, {
    relativeTime: {
      future: '%s päästä',
      past: '%s sitten',
      s: 'sekunti',
      ss: '%d sekuntia',
      m: 'minuutin',
      mm: '%d minuutin',
      h: 'tunnin',
      hh: '%d tunnin',
      d: 'päivän',
      dd: '%d päivän',
      M: 'kuukauden',
      MM: '%d kuukauden',
      y: 'vuoden',
      yy: '%d vuoden',
    },
  });
};
