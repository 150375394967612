import { Container as _Container, spacings } from '@hiven-energy/hiven-ui';
import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';

export const Container = styled(BaseView)`
  ${() =>
    Platform.OS === 'web' &&
    css`
      overflow: hidden;
    `}
`;

export const Dashboard = styled(_Container)`
  padding-top: ${spacings.xs}px;
`;

export const Section = styled(_Container)`
  margin-bottom: ${spacings.xs}px;
`;
