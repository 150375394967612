import round from 'lodash/round';

export const checkIfContainsMultipleCommasOrDots = (value: string) => {
  const dotsNumber = value.split('.').length - 1;
  const commasNumber = value.split(',').length - 1;
  return commasNumber > 1 || dotsNumber > 1 || (commasNumber === 1 && dotsNumber === 1);
};

export const formatBatteryCapacityToDisplayValue = (value: string, precision = 2) =>
  round(parseFloat(value.replace(',', '.')), precision);

export const formatBatteryCapacityToInputValue = (value: number) => value.toString().replace('.', ',');
