import { DeviceType } from '@hiven-energy/hiven-client';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { usePeriodicalUpdate } from 'src/hooks/usePeriodicalUpdate';
import { isStatusOutdated } from 'src/utils/device';

import * as styled from './styles';

const UPDATE_INTERVAL_MS = 1000;

interface Props {
  deviceType: DeviceType;
  name: string;
  heartbeat?: string;
}

const Header: FC<Props> = ({ deviceType, name, heartbeat }) => {
  usePeriodicalUpdate(UPDATE_INTERVAL_MS);

  const offline = !heartbeat || isStatusOutdated(heartbeat);

  return (
    <styled.Container>
      <styled.StatusBadgeWrapper>
        <styled.StatusBadge $offline={offline}>
          <styled.StatusIndicator $offline={offline} />
          <styled.StatusLabel>
            {offline ? <FormattedMessage id="common.offline" /> : <FormattedMessage id="common.online" />}
          </styled.StatusLabel>
        </styled.StatusBadge>
      </styled.StatusBadgeWrapper>
      <styled.DeviceName $deviceType={deviceType}>{name}</styled.DeviceName>
      {heartbeat && (
        <styled.Heartbeat>
          <FormattedMessage
            id="common.deviceOverview.header.lastHeartbeat"
            values={{ value: dayjs(heartbeat).fromNow() }}
          />
        </styled.Heartbeat>
      )}
    </styled.Container>
  );
};

export default React.memo(Header);
