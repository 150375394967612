import React, { FC, PropsWithChildren } from 'react';

import * as styled from './styles';

interface Props {
  label: string;
}

export const Card: FC<PropsWithChildren<Props>> = ({ label, children }) => (
  <styled.Container>
    <styled.Title variant="h3">{label}</styled.Title>
    {children}
  </styled.Container>
);
