import {
  Container as _Container,
  Button,
  spacings,
  TouchableOpacityContainer,
  Typography,
} from '@hiven-energy/hiven-ui';
import styled, { css } from 'styled-components/native';

import { colors } from 'src/theme';

const commonItemStyles = css`
  padding: ${spacings.xs}px 0;
  flex-direction: row;
  gap: 12px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.disabledGrey};
`;

export const Item = styled(_Container)`
  ${commonItemStyles};
`;

export const ItemColumn = styled(_Container)<{ $stretch?: boolean }>`
  gap: ${spacings.xxs}px;
  flex-shrink: 1;

  ${props =>
    props.$stretch &&
    css`
      flex: auto;
      align-items: flex-end;
    `};
`;

export const PressabledItem = styled(TouchableOpacityContainer).attrs({ activeOpacity: 0.5 })`
  ${commonItemStyles};
`;

export const Value = styled(Typography)`
  color: ${colors.stoneGrey};
`;

export const Actions = styled(_Container)`
  margin-top: ${spacings.xs}px;
  gap: ${spacings.xs}px;
`;

export const DeleteAccountButton = styled(Button).attrs({
  colors: {
    enabled: { titleColor: colors.raspberryPink, borderColor: colors.raspberryPink },
    pressed: {
      titleColor: colors.white,
      borderColor: colors.raspberryPink,
      backgroundColor: colors.raspberryPink,
    },
  },
})``;
