import { CognitoUser } from '@aws-amplify/auth';
import { ISignUpResult } from 'amazon-cognito-identity-js';

import { getSystemLanguage } from 'src/i18n';
import * as DemoStorage from 'src/storage/demo';
import { log } from 'src/utils/demo';

export const isUserAuthenticated = async () => {
  const authenticated = await DemoStorage.isUserAuthenticated();
  log(`User is ${authenticated ? '' : 'not '}authenticated`);
  return authenticated;
};

export const signUp = async () => {
  log('User has been signed up');
  return {} as ISignUpResult;
};

export const confirmSignUp = async () => {
  log('Verification code has been confirmed');
};

export const resendCode = async () => {
  log('Verification code has been resent');
};

export const sendCode = async () => {
  log('Verification code has been sent');
};

export const resetPassword = async () => {
  log('Password has been reset');
  return '';
};

export const changePassword = async () => {
  log('Password has been changed');
  return '';
};

export const signIn = async (email: string) =>
  Promise.all([DemoStorage.setUserAuthenticated(true), DemoStorage.setUserEmail(email)])
    .then(() => {
      log('Signed in');
      return {} as CognitoUser;
    })
    .catch(error => {
      log('Could not sign in', error);
      throw error;
    });

export const signOut = async () =>
  DemoStorage.clearStorage()
    .then(() => {
      log('Signed out');
    })
    .catch(error => {
      console.error('Could not sign out', error);
      throw error;
    });

export const deleteAccount = async () => {
  DemoStorage.clearStorage()
    .then(() => {
      log('User has been succssfully deleted');
    })
    .catch(error => {
      console.error('Could not delete account', error);
      throw error;
    });
};

export const getIdJwt = async () => {
  const idJwt = 'id-jwt';
  log('Retrieved ID-JWT', idJwt);
  return idJwt;
};

export const getUserId = () => getUserAttributes().then(attributes => attributes.userId);

export const getEmail = () => getUserAttributes().then(attributes => attributes.email);

export const getLanguage = () => getUserAttributes().then(attributes => attributes.language);

export const updateLanguage = async () => {
  log('Language has been successfully updated');
};

export const getTermsAndConditions = () => getUserAttributes().then(attributes => attributes.termsAndConditions);

export const updateTermsAndConditions = async () => {
  log('Terms and conditions have been successfully updated');
};

export const getPrivacyPolicy = () => getUserAttributes().then(attributes => attributes.privacyPolicy);

export const updatePrivacyPolicy = async () => {
  log('Privacy policy has been successfully updated');
};

const getUserAttributes = async () => {
  const userAttrtibutes = {
    userId: 'user-id',
    email: (await DemoStorage.getUserEmail()) || '',
    language: getSystemLanguage(),
    termsAndConditions: false,
    privacyPolicy: false,
  };
  log('Retrieved user attributes', userAttrtibutes);
  return userAttrtibutes;
};
