import { PARTNER } from '@env';

import { Partner } from 'src/types/partner';

export const usePartner = () => ({
  partner: PARTNER,
  isHiven: PARTNER === Partner.HIVEN,
  isWallbox: PARTNER === Partner.WALLBOX,
  isUniperSe: PARTNER === Partner.UNIPER_SE,
});
