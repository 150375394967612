import React, { FC } from 'react';
import root from 'react-shadow';

import { LONG_LOGO_HEIGHT, LONG_LOGO_WIDTH, manufacturerToLogoMap } from './constants';
import { Props } from './types';

const DeviceLogo: FC<Props> = ({ manufacturer, width = 44, height = 44, long, color, customProps }) => {
  const logo = manufacturerToLogoMap[manufacturer];
  const component = typeof logo === 'object' ? (long ? logo.long : logo.default) : logo;
  const style = {
    width: long ? LONG_LOGO_WIDTH : width,
    height: long ? LONG_LOGO_HEIGHT : height,
  };

  return (
    <root.div style={{ ...style, textAlign: 'center' }}>
      {React.createElement(component, {
        fill: color,
        ...style,
        ...customProps,
      })}
    </root.div>
  );
};

export default React.memo(DeviceLogo);
