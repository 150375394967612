import * as sdk from '@hiven-energy/hiven-client';
import { VehicleChargingBehavior } from '@hiven-energy/hiven-client';

import { ReadyTimeByType } from 'src/containers/ready-time/types';

export enum PreferenceType {
  ADD_CHARGING_LOCATION = 'AddChargingLocation',
  YOUR_CHARGING_LOCATIONS = 'YourChargingLocations',
  READY_TIME = 'ReadyTime',
  CHARGE_LEVELS = 'ChargeLevels',
  CHARGING_BEHAVIOR = 'ChargingBehavior',
}

export interface Preferences {
  chargingLocations: ChargingLocation[];
  readyTime: ReadyTimeByType;
  chargeLevels: ChargeLevels;
  chargingBehavior: VehicleChargingBehavior;
}

export interface ChargingLocation {
  payload: sdk.SetUserLocationPayload;
  id?: string;
}

export interface ChargeLevels {
  minimum: number;
  maximum: number;
}
