import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const Container = styled(_Container)``;

export const ButtonsWrapper = styled(_Container)`
  gap: ${spacings.xxs}px;
`;

export const Text = Typography;
