import { Container as _Container, spacings } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';

export const Container = styled(BaseView)`
  padding: ${spacings.xs}px;
`;

export const Content = styled(_Container)`
  flex: auto;
`;

export const ToggleWrapper = styled(_Container)`
  margin-top: ${spacings.s}px;
`;
