import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const Container = styled(_Container)`
  padding: 0 ${spacings.xs}px;
`;

export const Header = styled(_Container)`
  align-items: center;
`;

export const Text = styled(Typography)`
  text-align: center;
`;
