import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';

export const Container = styled(BaseView)`
  padding: ${spacings.s}px;
  flex: 1;
`;

export const Title = styled(Typography)`
  align-self: flex-start;
  margin-bottom: ${spacings.xxs}px;
`;

export const Footer = styled(_Container)`
  flex: 1;
  justify-content: flex-end;
`;
