import { Container as _Container, TouchableOpacityContainer, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  flex-direction: row;
  margin-top: 50px;
`;

export const Option = styled(TouchableOpacityContainer).attrs({ activeOpacity: 0.5 })<{ $active: boolean }>`
  flex: 1;
  align-items: center;
  padding-bottom: 10px;
  border-bottom-width: ${p => (p.$active ? 2 : 1)}px;
  border-bottom-color: ${p => (p.$active ? colors.deepNavy : colors.disabledGrey)};
`;

export const Label = styled(Typography)<{ $active: boolean }>`
  font-size: 16px;
  line-height: 21px;
  color: ${p => (p.$active ? colors.deepNavy : colors.stoneGrey)};
`;
