import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { FlatList, ListRenderItem } from 'react-native';

import { useAppTheme } from 'src/app-theme';
import { useBrandGroups } from 'src/hooks/useBrandGroups';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useAnalytics } from 'src/services/analytics';

import { groupTypeToScreenRoute } from './constants';
import * as styled from './styles';
import { Group, GroupType } from './types';

export type RoutedProps =
  | {
      disableGoBack?: boolean;
      hiddenGroupTypes?: GroupType[];
    }
  | undefined;

type Props = ScreenProps<RouteId.BrandGroups>;

const BrandGroups: FC<Props> = ({ route, navigation }) => {
  const { hiddenGroupTypes = [] } = route.params || {};

  const appTheme = useAppTheme();
  const { trackButtonClick } = useAnalytics();

  const brandGroups = useBrandGroups(hiddenGroupTypes);

  const handlePress = (groupType: GroupType, groupName: string) => {
    const routeId = groupTypeToScreenRoute[groupType];
    trackButtonClick(groupName);
    navigation.navigate(routeId);
  };

  const renderItem: ListRenderItem<Group> = ({ item, index }) => (
    <styled.Group
      $isLast={index === brandGroups.length - 1}
      activeOpacity={0.75}
      onPress={() => handlePress(item.type, item.nameMessageId)}
      backgroundColor={appTheme.main.color}
      testID={item.nameMessageId}
      accessibilityLabel={item.nameMessageId}
    >
      <styled.ImageBackground source={item.image} resizeMode="contain" />
      <styled.Name variant="h2">
        <FormattedMessage id={item.nameMessageId} />
      </styled.Name>
    </styled.Group>
  );

  const keyExtractor = (item: Group) => item.type;

  return (
    <styled.Container>
      <FlatList data={brandGroups} renderItem={renderItem} keyExtractor={keyExtractor} />
    </styled.Container>
  );
};

export default BrandGroups;
