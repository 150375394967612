import * as WebBrowser from 'expo-web-browser';
import { Platform } from 'react-native';

export const openBrowser = async (url: string, blank = false) =>
  Platform.OS === 'web' ? openWebBrowser(url, blank) : openMobileBrowser(url);

const openWebBrowser = async (url: string, blank: boolean) => {
  if (blank) {
    window.open(url, '_blank');
  } else {
    window.location.href = url;
  }
};

const openMobileBrowser = async (url: string) =>
  WebBrowser.openBrowserAsync(decodeURIComponent(url), { showInRecents: true });

export const dismissBrowser = () => (Platform.OS === 'ios' ? dismissMobileBrowser() : undefined);

const dismissMobileBrowser = () => WebBrowser.dismissBrowser();
