import { CarIcon, ChevronRightIcon, PinIcon, ProfileIcon, Typography } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import BaseView from 'src/components/BaseView/BaseView';
import { ScreenScrollView } from 'src/components/ScreenScrollView/ScreenScrollView';
import TabBar from 'src/containers/TabBar/TabBar';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useAnalytics } from 'src/services/analytics';
import { colors } from 'src/theme';

import * as styled from './styles';

export interface RoutedProps {
  deviceId: string;
}

type Props = ScreenProps<RouteId.Settings>;

const Settings: FC<Props> = ({
  navigation,
  route: {
    params: { deviceId },
  },
}) => {
  const { trackButtonClick } = useAnalytics();

  return (
    <BaseView>
      <ScreenScrollView>
        <styled.Setting
          onPress={() => {
            trackButtonClick('Settings.myVehiclesAndChargers');
            navigation.navigate(RouteId.MyVehiclesAndChargers);
          }}
        >
          <styled.Details>
            <CarIcon />
            <Typography fontWeight="medium">
              <FormattedMessage id="Settings.myVehiclesAndChargers" />
            </Typography>
          </styled.Details>
          <ChevronRightIcon color={colors.hintGrey} />
        </styled.Setting>
        <styled.Setting
          onPress={() => {
            trackButtonClick('Settings.myChargingLocations');
            navigation.navigate(RouteId.MyChargingLocations);
          }}
        >
          <styled.Details>
            <PinIcon />
            <Typography fontWeight="medium">
              <FormattedMessage id="Settings.myChargingLocations" />
            </Typography>
          </styled.Details>
          <ChevronRightIcon color={colors.hintGrey} />
        </styled.Setting>
        <styled.Setting
          onPress={() => {
            trackButtonClick('Settings.account');
            navigation.navigate(RouteId.Account);
          }}
          $skipBorderBottom
        >
          <styled.Details>
            <ProfileIcon />
            <Typography fontWeight="medium">
              <FormattedMessage id="Settings.account" />
            </Typography>
          </styled.Details>
          <ChevronRightIcon color={colors.hintGrey} />
        </styled.Setting>
      </ScreenScrollView>
      <TabBar deviceId={deviceId} />
    </BaseView>
  );
};

export default Settings;
