import { Container as _Container, Button as HivenButton, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';

export const Container = styled(BaseView)`
  padding: ${spacings.xs}px;
`;

export const Button = styled(HivenButton)`
  width: 100%;
  align-self: flex-end;
  justify-self: flex-end;
`;

export const MiddleContent = styled(_Container)`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const BottomContent = styled(_Container)`
  flex: 1;
  align-self: stretch;
  justify-content: flex-end;
  gap: ${spacings.xs}px;
`;

export const Description = styled(Typography)`
  margin-bottom: ${spacings.xs}px;
`;
