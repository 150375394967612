import { ErrorIcon, ErrorMessage } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { colors } from 'src/theme';

import * as styled from './styles';

const FetchError: FC = () => (
  <styled.Container>
    <ErrorIcon size={80} color={colors.raspberryPink} />
    <ErrorMessage>
      <FormattedMessage id="common.deviceOverview.fetchError.message" />
    </ErrorMessage>
  </styled.Container>
);

export default React.memo(FetchError);
