import { Button, EmailIcon, Link, LockedIcon } from '@hiven-energy/hiven-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';
import { z } from 'zod';

import { useA11y } from 'src/a11y';
import { TextField } from 'src/components/form-fields/TextField';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useResetPassword, useSendCode } from 'src/queries/session';
import { useAnalytics } from 'src/services/analytics';
import { CognitoAction } from 'src/services/session';
import * as validators from 'src/utils/validators';

import { getCognitoErrorMessageId } from '../utils';

import * as styled from './styles';
import { NewPasswordFormData, SendCodeFormData, Step } from './types';

type Props = ScreenProps<RouteId.ResetPassword>;

const ResetPassword: FC<Props> = ({ route, navigation }) => {
  const a11y = useA11y();
  const intl = useIntl();
  const toast = useToast();
  const { trackButtonClick } = useAnalytics();

  const [email, setEmail] = useState('');
  const [step, setStep] = useState(Step.SEND_CODE);

  const sendCodeSchema = useMemo(
    () =>
      z.object({
        email: validators.email(intl),
      }),
    [intl],
  );

  const newPasswordSchema = useMemo(
    () =>
      z.object({
        password: validators.password(intl, false),
        code: validators.code(intl),
      }),
    [intl],
  );

  const sendCodeForm = useForm<SendCodeFormData>({
    mode: 'onBlur',
    defaultValues: { email: route.params?.email || '' },
    resolver: zodResolver(sendCodeSchema),
  });

  const newPasswordForm = useForm<NewPasswordFormData>({
    mode: 'onBlur',
    defaultValues: {
      code: '',
      password: '',
    },
    resolver: zodResolver(newPasswordSchema),
  });

  const sendCodeMutation = useSendCode({
    onSuccess: (...args) => {
      const [_, email] = args;
      setEmail(email);
      setStep(Step.NEW_PASSWORD);
      toast.show(intl.formatMessage({ id: 'ResetPassword.sendCode.success' }), { type: 'success' });
    },
    onError: error => {
      setStep(Step.NEW_PASSWORD);
      const errorMessageId = getCognitoErrorMessageId(CognitoAction.SEND_CODE, error);
      toast.show(<FormattedMessage id={errorMessageId} />, { type: 'danger' });
    },
  });

  const resetPasswordMutation = useResetPassword({
    onSuccess: (...args) => {
      const [_, payload] = args;
      const { email } = payload;
      navigation.navigate(RouteId.SignIn, { email });
      toast.show(intl.formatMessage({ id: 'ResetPassword.success' }), { type: 'success' });
    },
    onError: error => {
      const errorMessageId = getCognitoErrorMessageId(CognitoAction.RESET_PASSWORD, error);
      toast.show(<FormattedMessage id={errorMessageId} />, { type: 'danger' });
    },
  });

  const handleSendConfirmation = (data: SendCodeFormData) => {
    trackButtonClick('ResetPassword.sendCode.confirm');
    sendCodeMutation.mutate(data.email);
  };

  const handleResetPasswordConfirm = (data: NewPasswordFormData) => {
    trackButtonClick('ResetPassword.newPassword.confirm');
    resetPasswordMutation.mutate({ email, code: data.code, newPassword: data.password });
  };

  const handleBackPress = () => {
    trackButtonClick('ResetPassword.back');
    navigation.navigate(RouteId.SignIn);
  };

  return (
    <styled.Container>
      {step === Step.SEND_CODE && (
        <>
          <styled.Title variant="h3">
            <FormattedMessage id="ResetPassword.sendCode.header" />
          </styled.Title>
          <TextField
            placeholder={intl.formatMessage({ id: 'common.email' })}
            control={sendCodeForm.control}
            name="email"
            leftIcon={EmailIcon}
          />
          <styled.Footer>
            <Button
              title={intl.formatMessage({ id: 'ResetPassword.sendCode.confirm' })}
              disabled={sendCodeMutation.isLoading || !sendCodeForm.formState.isValid}
              loading={sendCodeMutation.isLoading}
              onPress={sendCodeForm.handleSubmit(handleSendConfirmation)}
            />
            <styled.Back>
              <Link accessibilityLabel={a11y.formatLabel('ResetPassword.back')} onPress={handleBackPress}>
                {intl.formatMessage({ id: 'ResetPassword.back' })}
              </Link>
            </styled.Back>
          </styled.Footer>
        </>
      )}
      {step === Step.NEW_PASSWORD && (
        <>
          <styled.Title variant="h3">
            <FormattedMessage id="ResetPassword.newPassword.header" />
          </styled.Title>
          <TextField
            placeholder={intl.formatMessage({ id: 'common.code' })}
            control={newPasswordForm.control}
            name="code"
          />
          <TextField
            placeholder={intl.formatMessage({ id: 'common.password' })}
            control={newPasswordForm.control}
            name="password"
            leftIcon={LockedIcon}
            password
          />
          <styled.Footer>
            <Button
              title={intl.formatMessage({ id: 'ResetPassword.newPassword.confirm' })}
              disabled={resetPasswordMutation.isLoading || !newPasswordForm.formState.isValid}
              loading={resetPasswordMutation.isLoading}
              onPress={newPasswordForm.handleSubmit(handleResetPasswordConfirm)}
            />
          </styled.Footer>
        </>
      )}
    </styled.Container>
  );
};

export default ResetPassword;
