import { spacings } from '@hiven-energy/hiven-ui';
import React, { FC, PropsWithChildren } from 'react';
import { ScrollViewProps } from 'react-native';

import * as styled from './styles';

interface Props extends PropsWithChildren<ScrollViewProps> {
  verticalPadding?: boolean;
}

export const ScreenScrollView: FC<Props> = ({ verticalPadding = true, contentContainerStyle, children, ...rest }) => (
  <styled.ScrollView
    {...rest}
    contentContainerStyle={[
      { paddingVertical: verticalPadding ? spacings.xs : 0, flexGrow: 1, width: '100%' },
      contentContainerStyle,
    ]}
  >
    {children}
  </styled.ScrollView>
);
