import {
  ChargeBoxRegistrationStatus,
  ChargerIntegration,
  ChargerManufacturer,
  Device,
  DeviceType,
  VehicleChargingBehavior,
  VehicleIntegration,
  VehicleManufacturer,
} from '@hiven-energy/hiven-client';

export let devices: Device[] = [
  {
    id: 'vehicle-vw',
    attributes: {
      userId: 'userId',
      externalId: 'externalId',
      name: 'VW Car',
      partnerName: 'hiven',
      manufacturer: VehicleManufacturer.VOLKSWAGEN,
      deviceType: 'vehicle',
      engineType: 'ELECTRIC',
      model: 'model',
      partnerId: 'partnerId',
      vin: 'vin',
      year: 0,
    },
    integration: VehicleIntegration.SMARTCAR,
    settings: {
      locationSupported: true,
      chargingPreferences: {
        chargingBehavior: VehicleChargingBehavior.SMART_CHARGE_OFF,
      },
    },
    associatedToCharger: false,
    associatedChargerIds: [],
    createdAt: 'createdAt',
    type: DeviceType.VEHICLE,
  },
  {
    id: 'vehicle-tesla',
    attributes: {
      userId: 'userId',
      externalId: 'externalId',
      name: 'Tesla Car',
      partnerName: 'hiven',
      manufacturer: VehicleManufacturer.TESLA,
      deviceType: 'vehicle',
      engineType: 'ELECTRIC',
      model: 'model',
      partnerId: 'partnerId',
      vin: 'vin',
      year: 0,
    },
    integration: VehicleIntegration.SMARTCAR,
    settings: {
      locationSupported: true,
      chargingPreferences: {
        chargingBehavior: VehicleChargingBehavior.SMART_CHARGE_OFF,
      },
    },
    associatedToCharger: false,
    associatedChargerIds: [],
    createdAt: 'createdAt',
    type: DeviceType.VEHICLE,
  },
  {
    id: 'wallbox-charger',
    attributes: {
      userId: 'userId',
      externalId: 'externalId',
      name: 'Wallbox Charger',
      partnerName: 'hiven',
      connectorId: 'id',
      chargeBox: {
        id: 'id',
        ocppProtocol: 'ocppProtocol',
        registrationStatus: ChargeBoxRegistrationStatus.ACCEPTED,
      },
      chargePoint: {
        serialNumber: 'serialNumber',
        vendor: ChargerManufacturer.WALLBOX,
        model: 'model',
      },
    },
    integration: ChargerIntegration.OCPP,
    settings: {},
    createdAt: 'createdAt',
    type: DeviceType.CHARGER,
  },
  {
    id: 'vehicle-device-associated-to-wallbox-charger',
    attributes: {
      userId: 'userId',
      externalId: 'externalId',
      name: 'VW Associated To Wallbox Charger',
      partnerName: 'hiven',
      manufacturer: VehicleManufacturer.VOLKSWAGEN,
      deviceType: 'vehicle',
      engineType: 'ELECTRIC',
      model: 'model',
      partnerId: 'partnerId',
      vin: 'vin',
      year: 0,
    },
    integration: VehicleIntegration.SMARTCAR,
    settings: {
      locationSupported: true,
      chargingPreferences: {
        chargingBehavior: VehicleChargingBehavior.SMART_CHARGE_OFF,
      },
    },
    associatedToCharger: true,
    associatedChargerIds: ['associatedChargerId'],
    createdAt: 'createdAt',
    type: DeviceType.VEHICLE,
  },
  {
    id: 'wallbox-charger-associated-to-vehicle-device',
    attributes: {
      userId: 'userId',
      externalId: 'externalId',
      name: 'Wallbox Charger Associated To VW',
      partnerName: 'hiven',
      connectorId: 'id',
      chargeBox: {
        id: 'id',
        ocppProtocol: 'ocppProtocol',
        registrationStatus: ChargeBoxRegistrationStatus.ACCEPTED,
      },
      chargePoint: {
        serialNumber: 'serialNumber',
        vendor: ChargerManufacturer.WALLBOX,
        model: 'model',
      },
    },
    integration: ChargerIntegration.OCPP,
    settings: {
      chargingPreferences: {
        associatedDeviceId: 'vehicle-device-associated-to-wallbox-charger',
      },
    },
    createdAt: 'createdAt',
    type: DeviceType.CHARGER,
  },
];

export const addDevice = (device: Device) => {
  devices = [...devices, device];
};
