import { colors } from 'src/theme/colors';

import { AppTheme } from './types';

export const hivenAppTheme: AppTheme = {
  assets: {
    onboarding: {
      step1: require('src/assets/onboarding/step1.jpg'),
      step2: require('src/assets/onboarding/step2.jpg'),
      step3: require('src/assets/onboarding/step3.jpg'),
    },
  },
  main: {
    color: colors.deepNavy,
  },
};

export const uniperSeAppTheme = {
  ...hivenAppTheme,
} as AppTheme;

export const wallboxAppTheme: AppTheme = {
  assets: {
    onboarding: {
      step1: require('src/assets/wallbox/onboarding/step1.jpg'),
      step2: require('src/assets/wallbox/onboarding/step2.jpg'),
      step3: require('src/assets/wallbox/onboarding/step3.jpg'),
    },
  },
  main: {
    color: colors.shark,
  },
};
