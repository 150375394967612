import { Button as HivenButton, spacings, Typography } from '@hiven-energy/hiven-ui';
import { ImageBackground as NativeImageBackground } from 'react-native';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

export const Container = styled(BaseView)`
  padding: ${spacings.xs}px;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Title = styled(Typography)`
  color: ${colors.white};
  padding: 0 ${spacings.xs}px;
  margin-top: ${spacings.m}px;
  margin-bottom: ${spacings.l}px;
  text-align: center;
`;

export const ContinueButton = styled(HivenButton).attrs({ type: 'tertiary' })`
  width: 100%;
  margin-top: ${spacings.l}px;
  position: relative;
  z-index: 1;
`;

export const VirtualKeyAdditionButton = styled(HivenButton).attrs({
  type: 'secondary',
  colors: {
    enabled: { titleColor: colors.white },
    pressed: { titleColor: colors.hintGrey },
  },
})`
  width: 100%;
  margin-top: ${spacings.xxs}px;
  position: relative;
  z-index: 1;
`;

export const VirtualKeyDisclaimmer = styled(Typography)`
  color: ${colors.white};
  padding: 0 ${spacings.xs}px;
  margin-top: ${spacings.xxs}px;
  text-align: center;
`;

export const ImageBackground = styled(NativeImageBackground)`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 325px;
`;
