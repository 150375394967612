import { Button as _Button, Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const Container = styled(_Container)`
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Icon = styled(_Container)`
  margin-bottom: ${spacings.xs}px;
`;

export const Title = styled(Typography)`
  margin-bottom: ${spacings.xxxxs}px;
  text-align: center;
`;

export const Content = styled(Typography)`
  margin-bottom: ${spacings.xxl}px;
  text-align: center;
`;

export const Button = styled(_Button)`
  width: 100%;
`;
