import { CheckIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { colors } from 'src/theme';

import * as styled from './styles';

const Complete: FC = () => (
  <styled.Container>
    <styled.CheckWrapper>
      <CheckIcon size={20} color={colors.sunshineYellow} />
    </styled.CheckWrapper>
    <styled.Label>
      <FormattedMessage id="timeSchedule.complete.label" />
    </styled.Label>
  </styled.Container>
);

export default React.memo(Complete);
