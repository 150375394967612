import { Container as _Container, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  flex-direction: row;
  align-items: center;
`;

export const AddressWrapper = styled(_Container)`
  flex-grow: 1;
`;

export const Name = styled(Typography).attrs({ fontWeight: 'medium' })`
  font-size: 14px;
  line-height: 20px;
`;

export const AddressLine = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.stoneGrey};
`;
