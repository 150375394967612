import { ChargerChargingBehavior } from '@hiven-energy/hiven-client';
import { Button, Radio, Typography } from '@hiven-energy/hiven-ui';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import { useAnalytics } from 'src/services/analytics';

import * as styled from './styles';

interface Props {
  value: ChargerChargingBehavior;
  saving?: boolean;
  onConfirm: (value: ChargerChargingBehavior) => void;
}

const ChargingBehavior: FC<Props> = ({ value, saving, onConfirm }) => {
  const intl = useIntl();
  const a11y = useA11y();

  const [chargingBehavior, setChargingBehavior] = useState(value);
  const { trackButtonClick, trackInputValue } = useAnalytics();

  useEffect(() => {
    setChargingBehavior(value);
  }, [value]);

  const handleChange = (value: ChargerChargingBehavior) => {
    trackInputValue('ChargerPreferences.chargingBehavior', value);
    setChargingBehavior(value);
  };

  const handleSubmit = () => {
    trackButtonClick('common.continue', 'ChargerPreferences.chargingBehavior');
    onConfirm(chargingBehavior);
  };

  return (
    <styled.Container>
      <styled.Content>
        <Typography variant="h3">
          <FormattedMessage id="ChargerPreferences.chargingBehavior.preference.title" />
        </Typography>
        <styled.ToggleWrapper>
          <Radio
            checked={chargingBehavior === ChargerChargingBehavior.SMART_CHARGE_ON}
            label={intl.formatMessage({ id: 'charger.chargingBehavior.SMART_CHARGE_ON' })}
            onChange={() => handleChange(ChargerChargingBehavior.SMART_CHARGE_ON)}
          />
          <Radio
            checked={chargingBehavior === ChargerChargingBehavior.SMART_CHARGE_OFF}
            label={intl.formatMessage({ id: 'charger.chargingBehavior.SMART_CHARGE_OFF' })}
            onChange={() => handleChange(ChargerChargingBehavior.SMART_CHARGE_OFF)}
          />
        </styled.ToggleWrapper>
      </styled.Content>
      <Button
        title={intl.formatMessage({ id: 'common.continue' })}
        testID={a11y.formatLabel('common.continue')}
        loading={saving}
        disabled={saving}
        onPress={handleSubmit}
      />
    </styled.Container>
  );
};

export default React.memo(ChargingBehavior);
