import { Typography } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';

import * as styled from './styles';

interface Props {
  image: number;
  title: string;
  content: string;
}

const Step: FC<Props> = ({ image, title, content }) => (
  <styled.Container>
    <styled.Box>
      <styled.Image resizeMode="cover" source={image} />
    </styled.Box>
    <styled.Box $last>
      <styled.Title variant="h1">{title}</styled.Title>
      <Typography>{content}</Typography>
    </styled.Box>
  </styled.Container>
);

export default React.memo(Step);
