import dayjs from 'dayjs';
import range from 'lodash/range';

import { DateFormat, formatDate } from 'src/utils/date';

import * as constants from './constants';
import { Datum, TickWithOffset } from './types';

const MINUTES_IN_HOUR = 60;

export const parseData = (
  schedule: Record<string, number>,
  prices: Record<string, number>,
  readyDate: Date | undefined,
  timeZone: string | undefined,
): Datum[] => {
  const currentDate = dayjs().tz(timeZone).startOf('hour');
  const targetDate = dayjs(readyDate);

  const scheduleMap = new Map<string, boolean>(Object.entries(schedule).map(([date, value]) => [date, value > 0]));
  const pricesMap = new Map<string, number>(Object.entries(prices).map(([date, price]) => [date, price]));

  return range(constants.UPCOMING_HOURS).map(index => {
    const date = currentDate.add(index, 'hour');
    const dateString = formatDate(date, DateFormat.UTC);
    const price = pricesMap.get(dateString) ?? 0;
    const charge = !!scheduleMap.get(dateString);
    const overtime = date >= targetDate;
    const category = charge ? (overtime ? 'overtime' : 'charge') : 'wait';
    return { index, date, price, category };
  });
};

export const getReadyTimeTickWithOffset = (readyDate: Date): TickWithOffset | undefined => {
  const currentDate = dayjs().startOf('hour');
  const targetDate = dayjs(readyDate);

  let tick: number | undefined;
  const offset = 0.5 + targetDate.minute() / MINUTES_IN_HOUR;

  range(constants.UPCOMING_HOURS).forEach(index => {
    const date = currentDate.add(index, 'hour');
    if (!date.isSame(targetDate, 'hour')) return;
    tick = index;
  });

  return tick === undefined ? undefined : { tick, offset };
};
