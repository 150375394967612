import { useMemo } from 'react';

import { useChargerPreferences, useUserLocations } from 'src/queries/sdk';

export const useChargerLocation = (deviceId: string) => {
  const chargerPreferencesQuery = useChargerPreferences(deviceId);
  const userLocationsQuery = useUserLocations();
  return useMemo(() => {
    const { data: chargerPreferences } = chargerPreferencesQuery;
    const { data: userLocations } = userLocationsQuery;
    const chargerLocationId = chargerPreferences?.locationId;
    return {
      isInitialLoading: chargerPreferencesQuery.isInitialLoading || userLocationsQuery.isInitialLoading,
      chargerLocation: userLocations?.find(({ id }) => id === chargerLocationId),
      isRefetching: chargerPreferencesQuery.isRefetching || userLocationsQuery.isRefetching,
      chargerLocationId,
      refetch: async () => {
        await Promise.all([chargerPreferencesQuery.refetch(), userLocationsQuery.refetch()]);
      },
    };
  }, [chargerPreferencesQuery, userLocationsQuery]);
};
