import { Container as _Container, Button as HivenButton, spacings, Typography } from '@hiven-energy/hiven-ui';
import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

const MARGIN_LEFT_BASED_ON_ICON = 28;

export const Container = styled(BaseView)`
  background-color: ${colors.white};
`;

export const Button = styled(HivenButton)`
  margin: ${spacings.s}px;
`;

export const Content = styled(Typography)`
  margin: ${spacings.s}px;
  color: ${colors.deepNavy};
`;

export const LocationTitle = styled(Typography)`
  flex: 1;
  flex-wrap: wrap;
`;

export const ListContainer = styled(_Container)`
  flex: 1;
`;

export const LocationItem = styled(View)<{ $firstItem: boolean }>`
  padding: ${spacings.xs}px 0px;
  border-bottom-color: ${colors.disabledGrey};
  border-bottom-width: 1px;
  border-bottom-style: solid;

  ${({ $firstItem }) =>
    $firstItem &&
    css`
      border-top-color: ${colors.disabledGrey};
      border-top-width: 1px;
      border-top-style: solid;
    `}
`;

export const LocationTitleContent = styled(_Container)`
  flex-direction: row;
  align-items: center;
  gap: ${spacings.xxxs}px;
  padding-bottom: ${spacings.xxxs}px;
`;

export const AddressLine = styled(Typography)`
  color: ${colors.stoneGrey};
  margin-left: ${MARGIN_LEFT_BASED_ON_ICON}px;
  margin-right: ${spacings.xs}px;
  flex-wrap: wrap;
`;

export const CityContainer = styled(_Container)`
  flex-direction: row;
  margin-left: ${MARGIN_LEFT_BASED_ON_ICON}px;
  flex: 1;
  flex-basis: 100%;
  gap: ${spacings.xxxs}px;
`;

export const CityText = styled(Typography)`
  color: ${colors.stoneGrey};
`;

export const AttachedDevicesText = styled(Typography)`
  color: ${colors.stoneGrey};
  font-size: 12px;
  flex: 1;
  flex-wrap: wrap;
  margin-top: ${spacings.xxxxs}px;
  margin-left: ${MARGIN_LEFT_BASED_ON_ICON}px;
  margin-right: ${spacings.xs}px;
`;
