import { Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Content = styled(Container)`
  flex-direction: column;
  gap: ${spacings.xxs}px;
  flex-basis: auto;
`;

export const ChargeLevel = styled(Container)`
  width: 50%;
`;

export const Value = styled(Typography)`
  color: ${colors.deepNavy};
`;

export const Label = styled(Typography)`
  color: ${colors.stoneGrey};
`;
