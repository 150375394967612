import { ChargerChargingBehavior, Device, DeviceType } from '@hiven-energy/hiven-client';
import { EllipsisIcon, IconButton } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import { useChargerPreferences } from 'src/queries/sdk';
import { AttachedDeviceSelection } from 'src/screens/ChargingLocation/types';

interface Props {
  device: Device;
  onDeviceMenuOpen: (arg: AttachedDeviceSelection) => void;
}

export const ChargerMenu: FC<Props> = ({ device, onDeviceMenuOpen }) => {
  const intl = useIntl();
  const a11y = useA11y();

  const chargerPreferencesQuery = useChargerPreferences(device.id);

  const getPreferences = () => {
    const preferences = chargerPreferencesQuery.data!;
    return {
      ...preferences,
      locationId: undefined,
      chargingBehavior: ChargerChargingBehavior.SMART_CHARGE_OFF,
    };
  };

  return (
    <IconButton
      icon={EllipsisIcon}
      type="plain"
      accessibilityLabel={intl.formatMessage({ id: 'common.menu' })}
      onPress={() => onDeviceMenuOpen({ preferences: getPreferences(), type: DeviceType.CHARGER, device })}
      testID={a11y.formatLabel('common.menu')}
      disabled={!chargerPreferencesQuery.isSuccess}
    />
  );
};
