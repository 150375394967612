import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import { hasTariffInfoEmpty } from 'src/utils/tariff-info';

import { initialPreferences } from '../../constants';
import { Preferences } from '../../types';

import Preference from './Preference/Preference';
import * as styled from './styles';

interface Props {
  deviceName?: string;
  preferences: Preferences;
  onSetPreferencesPress: VoidFunction;
}

const MissingPreferences: FC<Props> = ({
  deviceName = '',
  preferences: { chargingLocation, associatedVehicleId, batteryCapacity, readyTime, chargeLevels },
  onSetPreferencesPress,
}) => {
  const a11y = useA11y();
  const intl = useIntl();

  const isChargingLocationSet = !!chargingLocation.address && !hasTariffInfoEmpty(chargingLocation.tariffInfo);

  return (
    <styled.Container>
      <styled.Title variant="h4">
        <FormattedMessage id="charger.Dashboard.missingPreferences.title" />
      </styled.Title>
      <styled.Content>
        <FormattedMessage id="charger.Dashboard.missingPreferences.content" />
      </styled.Content>
      <styled.Preferences>
        <Preference
          label={intl.formatMessage({ id: 'charger.Dashboard.missingPreferences.preference.chargingLocation' })}
          isSet={isChargingLocationSet}
        />
        {associatedVehicleId && (
          <Preference
            label={intl.formatMessage({ id: 'charger.Dashboard.missingPreferences.preference.batteryCapacity' })}
            isSet={!!batteryCapacity}
          />
        )}
        <Preference
          label={intl.formatMessage({ id: 'charger.Dashboard.missingPreferences.preference.readyTime' })}
          isSet={readyTime !== initialPreferences.readyTime}
        />
        <Preference
          label={intl.formatMessage({ id: 'charger.Dashboard.missingPreferences.preference.chargeLevels' })}
          isSet={chargeLevels !== initialPreferences.chargeLevels}
        />
      </styled.Preferences>
      <styled.Button
        title={intl.formatMessage({ id: 'charger.Dashboard.missingPreferences.setPreferences' })}
        onPress={onSetPreferencesPress}
        accessibilityLabel={a11y.formatLabel('missingPreferences.setPreferences', { name: deviceName })}
        testID={a11y.formatLabel('missingPreferences.setPreferences', { name: deviceName })}
      />
    </styled.Container>
  );
};

export default React.memo(MissingPreferences);
