import { UserLocation, Vehicle, VehiclePreferences } from '@hiven-energy/hiven-client';
import { useNavigation } from '@react-navigation/native';
import React, { FC, useMemo } from 'react';

import Divider from 'src/components/Divider/Divider';
import ChargeLevels from 'src/containers/device-overview/ChargeLevels/ChargeLevels';
import ChargingLocations from 'src/containers/device-overview/ChargingLocations/ChargingLocations';
import ReadyTime from 'src/containers/device-overview/ReadyTime/ReadyTime';
import { RouteId } from 'src/nav/types';

import { PreferenceType } from '../../types';

import ChargingBehavior from './ChargingBehavior/ChargingBehavior';
import * as styled from './styles';

interface Props {
  vehicle: Vehicle;
  preferences?: VehiclePreferences;
  userLocations?: UserLocation[];
}

const ChargingSetup: FC<Props> = ({ vehicle, preferences, userLocations = [] }) => {
  const navigation = useNavigation();

  const locations = useMemo(() => {
    if (!userLocations || !preferences) return [];
    return userLocations.filter(location => preferences.chargingLocationIds?.includes(location.id));
  }, [userLocations, preferences]);

  const navigateToPreference = (preferenceType: PreferenceType) => {
    navigation.navigate(RouteId.VehiclePreferences, {
      deviceId: vehicle.id,
      currentPreferenceType: preferenceType,
      updateOnPreferenceConfirm: true,
    });
  };

  const handleChargingLocationsEditPress = () => {
    navigateToPreference(PreferenceType.YOUR_CHARGING_LOCATIONS);
  };

  const handleUserLocationEditPress = (locationId: string) => {
    navigation.navigate(RouteId.ChargingLocation, { locationId });
  };

  const handleTariffInfoEditPress = (locationId: string) => {
    navigation.navigate(RouteId.SetTariffAndMeter, { locationId });
  };

  const handleReadyTimeEditPress = () => {
    navigateToPreference(PreferenceType.READY_TIME);
  };

  const handleChargeLevelsEditPress = () => {
    navigateToPreference(PreferenceType.CHARGE_LEVELS);
  };

  const handleChargingBehaviorEditPress = () => {
    navigateToPreference(PreferenceType.CHARGING_BEHAVIOR);
  };

  return (
    <styled.Container>
      <ChargingLocations
        locations={locations}
        onEditPress={handleChargingLocationsEditPress}
        onLocationEditPress={handleUserLocationEditPress}
        onTariffInfoEditPress={handleTariffInfoEditPress}
      />
      <Divider />
      <ReadyTime
        readyTime={preferences?.readyTime}
        daysOfWeekSpecificReadyTime={preferences?.weekDaySpecificReadyTimes}
        onEditPress={handleReadyTimeEditPress}
      />
      <Divider />
      <ChargeLevels
        minimum={preferences?.minimalSoC?.value}
        maximum={preferences?.targetSoC?.value}
        unit="%"
        onEditPress={handleChargeLevelsEditPress}
      />
      <Divider />
      <ChargingBehavior
        chargingBehavior={preferences?.chargingBehavior}
        onEditPress={handleChargingBehaviorEditPress}
      />
    </styled.Container>
  );
};

export default React.memo(ChargingSetup);
