import { TariffInfo } from '@hiven-energy/hiven-client';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';

import TariffInformation from 'src/containers/TariffInformation/TariffInformation';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useSaveTariffInformation, useUserLocation } from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';

type Props = ScreenProps<RouteId.SetTariffAndMeter>;

export interface RoutedProps {
  locationId: string;
}

const SetTariffAndMeter: FC<Props> = ({
  route: {
    params: { locationId },
  },
  navigation,
}) => {
  const toast = useToast();
  const intl = useIntl();

  useAnalyticsTimeEvent(MixpanelEvents.USER_DATA_SAVED);
  const { trackUserDataChange, trackButtonClick } = useAnalytics();
  const userLocationQuery = useUserLocation(locationId);

  const saveTariffInformationMutation = useSaveTariffInformation(locationId, {
    onSuccess: data => {
      trackUserDataChange('common.save', {
        tariff: data.tariffInfo?.tariff,
        meterId: data.tariffInfo?.meterId,
        electricalUtilityProvider: data.tariffInfo?.electricalUtilityProvider,
      });
      toast.show(intl.formatMessage({ id: 'TariffInfo.save.success' }), { type: 'success' });
      navigation.goBack();
    },
    onError: () => {
      toast.show(intl.formatMessage({ id: 'TariffInfo.save.error' }), { type: 'danger' });
    },
  });

  const handleSave = (data: TariffInfo) => {
    trackButtonClick('common.save');
    saveTariffInformationMutation.mutate(
      Object.entries(data).reduce<TariffInfo>((acc, [prop, value]) => {
        if (value) {
          acc[prop as keyof TariffInfo] = value;
        }
        return acc;
      }, {}),
    );
  };

  return (
    <TariffInformation
      onSave={handleSave}
      loading={userLocationQuery.isLoading}
      saving={saveTariffInformationMutation.isLoading}
      tariffInformation={userLocationQuery.data?.tariffInfo}
      saveTitle={intl.formatMessage({ id: 'common.save' })}
    />
  );
};

export default SetTariffAndMeter;
