import { useMemo } from 'react';

import { Mode, useMode } from 'src/store/mode';

import { createHivenClient } from './client';
import { createDemoHivenClient } from './client.demo';

export const useHivenClient = () => {
  const [mode] = useMode();

  return useMemo(() => (mode === Mode.DEMO ? createDemoHivenClient() : createHivenClient()), [mode]);
};
