import { Container as _Container } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

const WIDTH = 48;
const HEIGHT = 30;

export const Container = styled(_Container)`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
`;

export const Inner = styled(_Container)<{ $color: string }>`
  width: 100%;
  height: 100%;
  background-color: ${props => props.$color};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Before = styled(_Container)<{ $color: string }>`
  position: absolute;
  top: -${WIDTH / 4}px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-left-width: ${WIDTH / 2}px;
  border-left-color: transparent;
  border-right-width: ${WIDTH / 2}px;
  border-right-color: transparent;
  border-bottom-width: ${WIDTH / 4}px;
  border-bottom-color: ${props => props.$color};
`;

export const After = styled(_Container)<{ $color: string }>`
  position: absolute;
  bottom: -${WIDTH / 4}px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-left-width: ${WIDTH / 2}px;
  border-left-color: transparent;
  border-right-width: ${WIDTH / 2}px;
  border-right-color: transparent;
  border-top-width: ${WIDTH / 4}px;
  border-top-color: ${props => props.$color};
`;
