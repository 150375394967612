import { Select, SelectProps } from '@hiven-energy/hiven-ui';
import React from 'react';
import { Control, Controller, FieldError, FieldPath, FieldValues } from 'react-hook-form';

import { useAnalytics } from 'src/services/analytics';

import FormField from '../FormField';

interface SelectFieldProps<K extends FieldValues> {
  control: Control<K>;
  name: FieldPath<K>;
  options: SelectProps<string | null>['options'];
  accessibilityLabel: string;
  showErrors?: boolean;
  placeholder?: string;
}

export const SelectField = <K extends FieldValues>({
  control,
  options,
  name,
  accessibilityLabel,
  showErrors = true,
  placeholder,
}: SelectFieldProps<K>): React.JSX.Element => {
  const { trackInputValue } = useAnalytics();

  return (
    <Controller
      control={control}
      render={({ field: { value, onChange, onBlur }, formState }) => {
        const error = showErrors && (formState.errors[name] as FieldError);

        const customOnChange: typeof onChange = _value => {
          onBlur();
          onChange(_value);
          trackInputValue(name, _value);
        };
        return (
          <FormField error={error}>
            <Select
              value={value}
              onChange={customOnChange}
              options={options}
              error={!!error}
              testID={name}
              dismissKeyboardOnActive
              placeholder={placeholder}
              accessibilityLabel={accessibilityLabel}
            />
          </FormField>
        );
      }}
      name={name}
    />
  );
};
