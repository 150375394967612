import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import { Platform, ScrollView } from 'react-native';
import styled, { css } from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

export const Container = styled(BaseView)`
  padding: 0 ${spacings.xs}px;
`;

export const Content = styled(ScrollView)`
  padding-bottom: ${spacings.xxs}px;
  padding-top: ${spacings.xs}px;
`;

export const Section = styled(_Container)<{ $first?: boolean }>`
  margin-top: ${props => (props.$first ? spacings.xxl : spacings.m)}px;
`;

export const SectionLabel = styled(Typography)`
  margin-bottom: ${spacings.xxs}px;
`;

export const Status = styled(Typography)<{ $error?: boolean }>`
  margin-top: ${spacings.xxxxs}px;
  margin-left: ${spacings.xxxs}px;
  color: ${props => (props.$error ? colors.raspberryPink : colors.stoneGrey)};
`;

export const ButtonsWrapper = styled(_Container)`
  gap: ${spacings.xxs}px;
  padding-top: ${spacings.xs}px;
  ${() =>
    Platform.OS === 'web' &&
    css`
      padding-bottom: ${spacings.xs}px;
    `}
`;
