import { VehicleChargingBehavior, VehicleChargingStatus } from '@hiven-energy/hiven-client';
import {
  BatteryChargingIcon,
  BatteryFullIcon,
  BatteryUnavailableIcon,
  IconProps,
  PlugIcon,
  ProgressVariant,
} from '@hiven-energy/hiven-ui';
import { FC } from 'react';
import { defineMessages } from 'react-intl';

import { ChargeLevelUnit } from 'src/containers/charge-levels/types';
import { defaultReadyTime } from 'src/containers/ready-time/utils';
import { MessageDescriptor } from 'src/i18n';
import { colors } from 'src/theme';

import { ChargeLevels, Preferences, PreferenceType } from './types';

export const CHARGE_LEVEL_LIMIT = 100;

export const MINIMUM_CHARGE_LEVEL_HINT_THRESHOLD = 60;

export const CHARGE_LEVEL_UNIT: ChargeLevelUnit = '%';

export const initialChargeLevels: ChargeLevels = {
  minimum: 15,
  maximum: 80,
};

export const defaultRegion = {
  latitude: 59.3293,
  longitude: 18.0686,
};

export const initialPreferences: Preferences = {
  chargingLocations: [],
  readyTime: { type: 'all_day', time: defaultReadyTime },
  chargeLevels: initialChargeLevels,
  chargingBehavior: VehicleChargingBehavior.SMART_CHARGE_ANYWHERE,
};

export const chargingStatusToStateOfChargeColor: Record<VehicleChargingStatus, string> = {
  [VehicleChargingStatus.CHARGING]: colors.sunshineYellow,
  [VehicleChargingStatus.NOT_CHARGING]: colors.pidgeonGray,
  [VehicleChargingStatus.COMPLETE]: colors.sunshineYellow,
  [VehicleChargingStatus.DISCONNECTED]: colors.pidgeonGray,
  [VehicleChargingStatus.PLUGGED]: colors.pidgeonGray,
  [VehicleChargingStatus.CHARGING_COMPLETED]: colors.sunshineYellow,
};

export const chargingStatusToProgressVariant: Record<VehicleChargingStatus, ProgressVariant> = {
  [VehicleChargingStatus.CHARGING]: ProgressVariant.GROWING_VALUE,
  [VehicleChargingStatus.NOT_CHARGING]: ProgressVariant.STANDARD,
  [VehicleChargingStatus.COMPLETE]: ProgressVariant.STANDARD,
  [VehicleChargingStatus.DISCONNECTED]: ProgressVariant.STANDARD,
  [VehicleChargingStatus.PLUGGED]: ProgressVariant.STANDARD,
  [VehicleChargingStatus.CHARGING_COMPLETED]: ProgressVariant.STANDARD,
};

export const chargingStatusToMessage = defineMessages<VehicleChargingStatus, MessageDescriptor>({
  [VehicleChargingStatus.CHARGING]: { id: 'vehicle.status.charging' },
  [VehicleChargingStatus.NOT_CHARGING]: { id: 'vehicle.status.notCharging' },
  [VehicleChargingStatus.COMPLETE]: { id: 'vehicle.status.complete' },
  [VehicleChargingStatus.DISCONNECTED]: { id: 'vehicle.status.disconnected' },
  [VehicleChargingStatus.PLUGGED]: { id: 'vehicle.status.notCharging' },
  [VehicleChargingStatus.CHARGING_COMPLETED]: { id: 'vehicle.status.complete' },
});

export const chargingStatusToIcon: Record<VehicleChargingStatus, FC<IconProps>> = {
  [VehicleChargingStatus.CHARGING]: BatteryChargingIcon,
  [VehicleChargingStatus.NOT_CHARGING]: PlugIcon,
  [VehicleChargingStatus.COMPLETE]: BatteryFullIcon,
  [VehicleChargingStatus.DISCONNECTED]: BatteryUnavailableIcon,
  [VehicleChargingStatus.PLUGGED]: PlugIcon,
  [VehicleChargingStatus.CHARGING_COMPLETED]: BatteryFullIcon,
};
export const vehiclePreferenceTitles = defineMessages<PreferenceType, MessageDescriptor>({
  [PreferenceType.ADD_CHARGING_LOCATION]: {
    id: 'navigation.screen.VehiclePreferences.AddChargingLocation',
  },
  [PreferenceType.READY_TIME]: {
    id: 'navigation.screen.VehiclePreferences.ReadyTime',
  },
  [PreferenceType.CHARGE_LEVELS]: {
    id: 'navigation.screen.VehiclePreferences.ChargeLevels',
  },
  [PreferenceType.CHARGING_BEHAVIOR]: {
    id: 'navigation.screen.VehiclePreferences.ChargingBehavior',
  },
  [PreferenceType.YOUR_CHARGING_LOCATIONS]: {
    id: 'navigation.screen.VehiclePreferences.YourChargingLocations',
  },
});
