import { useMemo } from 'react';

import { Language } from 'src/i18n';
import EnWeekdays from 'src/i18n/displayNames/weekday.en.json';
import FiWeekdays from 'src/i18n/displayNames/weekday.fi.json';
import { useSession } from 'src/store/session';

export type WeekDay = keyof (typeof FiWeekdays & typeof EnWeekdays);

export const useWeekDays = (): [WeekDay, string][] => {
  const session = useSession();

  return useMemo(() => {
    const weekdays = session.language === Language.EN ? EnWeekdays : FiWeekdays;
    return Object.entries(weekdays) as [WeekDay, string][];
  }, [session.language]);
};
