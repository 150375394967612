import { Container as _Container, ErrorMessage as _ErrorMessage, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';

export const Container = styled(BaseView)`
  padding: ${spacings.m}px ${spacings.xs}px;
`;

export const Section = styled(_Container)`
  margin-bottom: ${spacings.s}px;
`;

export const Title = styled(Typography)`
  margin-bottom: ${spacings.xs}px;
`;

export const InputWrapper = styled(_Container)`
  margin-bottom: ${spacings.xs}px;
`;

export const ErrorMessage = styled(_ErrorMessage)`
  margin-top: ${spacings.xs}px;
`;
