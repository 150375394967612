import { Container as _Container } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const FullScreenContainer = styled(_Container)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Container = styled(_Container)`
  flex-direction: row;
  justify-content: center;
`;
