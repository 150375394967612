import { CheckCircleIcon, Typography } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';

import { colors } from 'src/theme';

import * as styled from './styles';

interface Props {
  label: string;
  isSet: boolean;
}

const Preference: FC<Props> = ({ label, isSet }) => (
  <styled.Container>
    <CheckCircleIcon size={32} color={isSet ? colors.jungleGreen : colors.fogGrey} />
    <Typography>{label}</Typography>
  </styled.Container>
);

export default React.memo(Preference);
