import { ChargingSchedule } from '@hiven-energy/hiven-client';
import flow from 'lodash/flow';

import { UPCOMING_HOURS } from './constants';
import { Datum } from './types';

const MILLISECONDS_IN_HOUR = 60 * 60 * 1000;

export const getScheduleDomain = (): [Date, Date] => {
  const start = new Date();
  start.setMinutes(0);
  start.setSeconds(0);
  start.setMilliseconds(0);
  const end = new Date(start.getTime() + UPCOMING_HOURS * MILLISECONDS_IN_HOUR);
  return [start, end];
};

export const parseSchedule = (schedule: ChargingSchedule, domain: [Date, Date]): Datum[] => {
  const readyDate = new Date(schedule.readyTime);

  const parse = flow(
    parseHourlyCharges,
    (data: Datum[]) => filterScheduleItemsRange(data, domain),
    (data: Datum[]) => markScheduleOvertimeCharges(data, readyDate),
    joinScheduleItems,
  );

  return parse(schedule);
};

const parseHourlyCharges = (schedule: ChargingSchedule): Datum[] => {
  const creationDate = new Date(schedule.createdAt);
  return Object.entries(schedule.hourlyCharges)
    .sort(([first], [second]) => new Date(first).getTime() - new Date(second).getTime())
    .reduce<Datum[]>((result, [dateString, factor]) => {
      if (factor === 0) return result;

      const date = new Date(dateString);
      const nextHour = new Date(date.getTime() + MILLISECONDS_IN_HOUR);

      const start = date < creationDate ? creationDate : date;
      const endMilliseconds = start.getTime() + MILLISECONDS_IN_HOUR * factor;
      const end = endMilliseconds > nextHour.getTime() ? nextHour : new Date(endMilliseconds);

      const item: Datum = {
        start,
        end,
        category: 'charge',
      };

      return [...result, item];
    }, []);
};

const filterScheduleItemsRange = (data: Datum[], domain: [Date, Date]): Datum[] => {
  const [start, end] = domain;

  return data.reduce<Datum[]>((result, datum) => {
    let item: Datum | undefined = datum;
    if (datum.end <= start || datum.start >= end) {
      item = undefined;
    } else if (datum.start < start && datum.end > start) {
      item = { ...datum, start };
    } else if (datum.start < end && datum.end > end) {
      item = { ...datum, end };
    }

    return item ? [...result, item] : result;
  }, []);
};

const markScheduleOvertimeCharges = (data: Datum[], readyDate: Date): Datum[] =>
  data.reduce<Datum[]>((result, datum) => {
    if (datum.category === 'charge' && datum.start < readyDate && datum.end > readyDate) {
      const chargeItem: Datum = {
        start: datum.start,
        end: readyDate,
        category: 'charge',
      };
      const chargeOvertimeItem: Datum = {
        start: readyDate,
        end: datum.end,
        category: 'overtime',
      };
      return [...result, chargeItem, chargeOvertimeItem];
    }

    if (datum.category === 'charge' && datum.start >= readyDate) {
      const item: Datum = {
        ...datum,
        category: 'overtime',
      };
      return [...result, item];
    }

    return [...result, datum];
  }, []);

const joinScheduleItems = (data: Datum[], index = 0): Datum[] => {
  if (data.length === 0 || index >= data.length) return data;

  const current = data[index],
    next = data[index + 1];
  const shouldJoin = next && current.end.getTime() === next.start.getTime() && current.category === next.category;

  if (!shouldJoin) return joinScheduleItems(data, index + 1);

  const jointItem: Datum = {
    start: current.start,
    end: next.end,
    category: current.category,
  };
  const nextData = [...data.slice(0, index), jointItem, ...data.slice(index + 2)];

  return joinScheduleItems(nextData, index);
};
