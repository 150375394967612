import { CopyIcon, LockedIcon } from '@hiven-energy/hiven-ui';
import * as Clipboard from 'expo-clipboard';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useA11y } from 'src/a11y';
import { useAnalytics } from 'src/services/analytics';

import * as styled from './styles';

interface Props {
  steveUrl: string;
  chargerExternalId: string;
}

const ConnectDetails: FC<Props> = ({ steveUrl, chargerExternalId }) => {
  const a11y = useA11y();
  const { trackButtonClick } = useAnalytics();

  const handleCopyToClipboard = (value: string, clickText: string) => {
    trackButtonClick(clickText);
    void Clipboard.setStringAsync(value);
  };

  return (
    <>
      <styled.Setting>
        <styled.NameWrapper>
          <styled.Icon
            onPress={() => {
              handleCopyToClipboard(`${steveUrl}/${chargerExternalId}`, 'connectDetails.url.icon');
            }}
            testID={a11y.formatLabel('connectDetails.url.icon')}
            accessibilityLabel={a11y.formatLabel('connectDetails.url.icon')}
          >
            <CopyIcon />
          </styled.Icon>
          <styled.Name
            variant="h4"
            testID={a11y.formatLabel('connectDetails.url.label')}
            accessibilityLabel={a11y.formatLabel('connectDetails.url.label')}
          >
            <FormattedMessage id="connectDetails.url.label" />
          </styled.Name>
        </styled.NameWrapper>
        <styled.Value>{`${steveUrl}/${chargerExternalId}`}</styled.Value>
      </styled.Setting>
      <styled.Setting>
        <styled.NameWrapper>
          <styled.Icon
            onPress={() => {
              handleCopyToClipboard(chargerExternalId, 'connectDetails.id.icon');
            }}
            testID={a11y.formatLabel('connectDetails.id.icon')}
            accessibilityLabel={a11y.formatLabel('connectDetails.id.icon')}
          >
            <CopyIcon />
          </styled.Icon>
          <styled.Name
            variant="h4"
            testID={a11y.formatLabel('connectDetails.id.label')}
            accessibilityLabel={a11y.formatLabel('connectDetails.id.label')}
          >
            <FormattedMessage id="connectDetails.id.label" />
          </styled.Name>
        </styled.NameWrapper>
        <styled.Value>{chargerExternalId}</styled.Value>
      </styled.Setting>
      <styled.Setting $noMargin>
        <styled.NameWrapper>
          <styled.Icon
            testID={a11y.formatLabel('connectDetails.password.icon')}
            accessibilityLabel={a11y.formatLabel('connectDetails.password.icon')}
          >
            <LockedIcon />
          </styled.Icon>
          <styled.Name
            variant="h4"
            testID={a11y.formatLabel('connectDetails.password.label')}
            accessibilityLabel={a11y.formatLabel('connectDetails.password.label')}
          >
            <FormattedMessage id="connectDetails.password.label" />
          </styled.Name>
        </styled.NameWrapper>
        <styled.Value>
          <FormattedMessage id="connectDetails.password.hint" />
        </styled.Value>
      </styled.Setting>
    </>
  );
};

export default React.memo(ConnectDetails);
