import { Container as _Container, spacings } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const Container = styled(_Container)<{ $withError: boolean; $withErrorHeight: boolean }>`
  margin-bottom: ${({ $withError, $withErrorHeight }) =>
    $withErrorHeight ? `${$withError ? 0 : spacings.xs + 1}px` : `${spacings.xxs}px`};
`;

export const Error = styled(_Container)`
  margin-top: ${spacings.xxxxs}px;
`;
