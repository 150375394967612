import { AccordionIcon, ChevronRightIcon, EditIcon, PinIcon, spacings } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import { LabelValueRow } from 'src/components/LabelValue/LabelValue';
import PreferenceOverview from 'src/components/PreferenceOverview/PreferenceOverview';
import LocationNotSetView from 'src/containers/charging-location/Overview/LocationNotSetView';
import { ChargingLocation } from 'src/screens/charger/types';
import { useAnalytics } from 'src/services/analytics';
import { AnalyticPlace } from 'src/services/analytics/types';
import { hasTariffInfoEmpty } from 'src/utils/tariff-info';

import * as styled from './styles';

interface Props {
  chargingLocation: ChargingLocation;
  analyticsPlace: AnalyticPlace;
  onEditPress: VoidFunction;
  onTariffInfoEditPress: VoidFunction;
}

const ChargingLocationComponent: FC<Props> = ({
  chargingLocation,
  analyticsPlace,
  onEditPress,
  onTariffInfoEditPress,
}) => {
  const intl = useIntl();
  const a11y = useA11y();

  const { trackButtonClick } = useAnalytics();

  const handleContentPress = () => {
    trackButtonClick('overview.map', analyticsPlace);
    onEditPress();
  };

  const tariffInfoEmpty = hasTariffInfoEmpty(chargingLocation.tariffInfo);

  return (
    <PreferenceOverview
      title={intl.formatMessage({ id: 'ChargerPreferences.chargingLocation.overview.title' })}
      icon={PinIcon}
      contentPadding={spacings.none}
      analyticsPlace={analyticsPlace}
    >
      <styled.Container>
        {!chargingLocation.address && <LocationNotSetView onAddLocationPress={handleContentPress} />}
        {chargingLocation.address && (
          <styled.LocationContainer accessible={false} onPress={handleContentPress}>
            <styled.LocationHeader>
              <styled.LocationHeaderText variant="h3">{chargingLocation.name}</styled.LocationHeaderText>
              <styled.LocationAddressText>{chargingLocation.address.streetAddress}</styled.LocationAddressText>
              <styled.LocationAddressText>{`${chargingLocation.address.postalCode} ${
                chargingLocation.address!.city
              }`}</styled.LocationAddressText>
            </styled.LocationHeader>
            <styled.ChevronIconContainer>
              <ChevronRightIcon />
            </styled.ChevronIconContainer>
          </styled.LocationContainer>
        )}
        {tariffInfoEmpty && chargingLocation.address && (
          <styled.TariffInfoHintContainer>
            <styled.TariffInfoHeader>
              {intl.formatMessage({ id: 'ChargerPreferences.chargingLocation.tariffInfo.hint' })}
            </styled.TariffInfoHeader>
            <styled.AddTariffInfoButton accessible={false} onPress={onTariffInfoEditPress}>
              <AccordionIcon />
              <styled.AddTariffInfoText fontWeight="semiBold">
                {intl.formatMessage({ id: 'ChargerPreferences.chargingLocation.tariffInfo.addTariffInfo' })}
              </styled.AddTariffInfoText>
            </styled.AddTariffInfoButton>
          </styled.TariffInfoHintContainer>
        )}
        {chargingLocation.tariffInfo && !tariffInfoEmpty && chargingLocation.address && (
          <styled.TariffInfoContainer>
            <styled.TariffInfoTouchableContainer
              accessible={false}
              onPress={onTariffInfoEditPress}
              accessibilityLabel={intl.formatMessage({
                id: 'ChargerPreferences.chargingLocation.tariffInfo.editTariffInfo',
              })}
              testID={a11y.formatLabel('common.edit', { name: 'tariff' })}
            >
              <styled.TariffInfoParametersContainer>
                <LabelValueRow
                  label={intl.formatMessage({ id: 'ChargerPreferences.chargingLocation.tariffInfo.metterId' })}
                  value={chargingLocation.tariffInfo.meterId}
                />
                <LabelValueRow
                  label={intl.formatMessage({ id: 'ChargerPreferences.chargingLocation.tariffInfo.tariff' })}
                  value={chargingLocation.tariffInfo.tariff}
                />
                <LabelValueRow
                  label={intl.formatMessage({
                    id: 'ChargerPreferences.chargingLocation.tariffInfo.electricalUtilityProvider',
                  })}
                  value={chargingLocation.tariffInfo.electricalUtilityProvider}
                />
              </styled.TariffInfoParametersContainer>
              <EditIcon />
            </styled.TariffInfoTouchableContainer>
          </styled.TariffInfoContainer>
        )}
      </styled.Container>
    </PreferenceOverview>
  );
};

export default React.memo(ChargingLocationComponent);
