import { Container as _Container, Button, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

export const Container = styled(BaseView)`
  padding: ${spacings.s}px;
  justify-content: flex-end;
  flex: 1;
`;

export const Title = styled(Typography)`
  text-align: center;
  padding-bottom: ${spacings.m}px;
  color: ${colors.white};
`;

export const Content = styled(Typography)`
  text-align: center;
  padding-bottom: ${spacings.xxxl}px;
  color: ${colors.white};
`;

export const Subscript = styled(Typography)`
  font-size: 10px;
  color: ${colors.white};
`;

export const GetStartedButton = styled(Button)``;

export const SignInQuestion = styled(_Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${spacings.xs}px;
`;

export const SignInText = styled(Typography)`
  color: ${colors.white};
  margin-right: ${spacings.xxxxs}px;
`;
