import { Auth } from '@aws-amplify/auth';
import { REGION, USER_POOL_ID, USER_POOL_WEBCLIENT_ID } from '@env';

export const configureAuthentication = () => {
  console.log('Configuring authentication...');

  Auth.configure({
    region: REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_WEBCLIENT_ID,
  });
};
