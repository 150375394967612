import { InputField, spacings } from '@hiven-energy/hiven-ui';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';

export const AddressInput = styled(InputField)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: ${spacings.xxxs}px;
`;

export const EmptyContent = styled(Pressable)`
  height: 120px;
`;
