import React, { FC, PropsWithChildren } from 'react';
import { IntlConfig, IntlProvider } from 'react-intl';
import { Text } from 'react-native';

import { EnMessages } from 'src/i18n';

type Props = Partial<IntlConfig>;

const messages = Object.keys(EnMessages).reduce(
  (result, messageId) => ({
    ...result,
    [messageId]: messageId,
  }),
  {},
);

const I18NProvider: FC<PropsWithChildren<Props>> = ({ children, ...config }) => (
  <IntlProvider locale="en" messages={messages} textComponent={Text} {...config}>
    {children}
  </IntlProvider>
);

export default I18NProvider;
