import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatRelativeTime } from 'src/utils/time';

import * as styled from './styles';

interface Props {
  heartbeat: string;
}

const LastHeartbeat: FC<Props> = ({ heartbeat }) => {
  const intl = useIntl();

  const time = formatRelativeTime(heartbeat, intl);

  return (
    <styled.Heartbeat>
      <FormattedMessage id="lastHeartbeat.label" values={{ time }} />
    </styled.Heartbeat>
  );
};

export default React.memo(LastHeartbeat);
