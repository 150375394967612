import { Container as _Container, Button, spacings } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const Container = styled(_Container)`
  flex: 1;
`;

export const ChildrenWrapper = styled(_Container)`
  flex: auto;
`;

export const NavigationWrapper = styled(_Container)`
  margin: 0 ${spacings.xs}px ${spacings.xs}px ${spacings.xs}px;
`;

export const DotsWrapper = styled(_Container)`
  display: flex;
  align-items: center;
`;

export const ActionsWrapper = styled(_Container)`
  flex: auto;
  justify-content: flex-end;
  margin-top: ${spacings.xs}px;
`;

export const NextAction = styled(Button)`
  margin-bottom: ${spacings.xxs}px;
`;

export const GetStarted = styled(Button)``;

export const SkipAction = styled(Button)``;
