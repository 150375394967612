import { Container as _Container, spacings, TouchableOpacityContainer, Typography } from '@hiven-energy/hiven-ui';
import { ImageBackground as NativeImageBackground } from 'react-native';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

export const Container = styled(BaseView)`
  padding: ${spacings.xs}px;
`;

export const Brand = styled(TouchableOpacityContainer)<{ $isLast: boolean }>`
  border: 1px solid ${colors.pidgeonGray};
  border-radius: 12px;
  margin-bottom: ${props => (props.$isLast ? spacings.none : spacings.xxs)}px;
  height: 140px;
`;

export const Icon = styled(_Container)`
  align-items: center;
  justify-content: center;
  flex: auto;
`;

export const Action = styled(_Container)`
  height: 50px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  flex-direction: row;
`;

export const ImageBackground = styled(NativeImageBackground)`
  flex: 1;
`;

export const Button = styled(_Container)`
  flex: 2;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: ${spacings.xs}px;
`;

export const Hint = styled(Typography)`
  margin-right: ${spacings.xxs}px;
  color: ${colors.white};
`;
