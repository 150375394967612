import * as sdk from '@hiven-energy/hiven-client';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Loader from 'src/components/Loader/Loader';
import { ChargerHeartBeat } from 'src/screens/ChargingLocation/ChargerHeartBeat/ChargerHeartBeat';
import { ChargerMenu } from 'src/screens/ChargingLocation/ChargerMenu/ChargerMenu';
import { AttachedDeviceSelection } from 'src/screens/ChargingLocation/types';
import { VehicleMenu } from 'src/screens/ChargingLocation/VehicleMenu/VehicleMenu';
import { colors } from 'src/theme';
import { isCharger, isVehicle } from 'src/utils/device';

import * as styled from '../styles';

interface Props {
  devices?: sdk.Device[];
  location: sdk.UserLocation;
  onDeviceMenuOpen: (arg: AttachedDeviceSelection) => void;
  isLoading: boolean;
}

export const AttachedDevices: FC<Props> = ({ devices, onDeviceMenuOpen, location, isLoading }) => {
  const intl = useIntl();

  return (
    <styled.Section>
      {isLoading ? (
        <Loader color={colors.deepNavy} />
      ) : (
        <>
          <styled.SectionTitle fontWeight="semiBold">
            {intl.formatMessage({ id: 'ChargingLocation.attachedDevices.title' })}
          </styled.SectionTitle>
          {devices && !!devices.length ? (
            <styled.DevicesContainer>
              {devices.map(device => (
                <styled.SectionItem key={device.id}>
                  <styled.SectionItemHeader>
                    <styled.SectionItemTitle variant="h4">{device.attributes.name}</styled.SectionItemTitle>
                    {isCharger(device) && <ChargerMenu device={device} onDeviceMenuOpen={onDeviceMenuOpen} />}
                    {isVehicle(device) && (
                      <VehicleMenu device={device} onDeviceMenuOpen={onDeviceMenuOpen} location={location} />
                    )}
                  </styled.SectionItemHeader>
                  {isCharger(device) && <ChargerHeartBeat chargerId={device.id} />}
                </styled.SectionItem>
              ))}
            </styled.DevicesContainer>
          ) : (
            <styled.SectionItemText>
              <FormattedMessage id="ChargingLocation.noDevices" />
            </styled.SectionItemText>
          )}
        </>
      )}
    </styled.Section>
  );
};
