import React, { FC } from 'react';

import DevicePairingContainer from 'src/containers/DevicePairing/DevicePairing';
import { RouteId, ScreenProps } from 'src/nav/types';

type Props = ScreenProps<RouteId.DevicePairing>;

const DevicePairing: FC<Props> = ({ navigation }) => {
  const handleConfirm = (chargerId: string, vehicleId: string | undefined) => {
    navigation.navigate(RouteId.ChargerPreferences, {
      deviceId: chargerId,
      associatedDeviceId: vehicleId,
      obtainPreferencesFromVehicle: true,
      navigateToDashboard: true,
    });
  };

  return <DevicePairingContainer onConfirm={handleConfirm} />;
};

export default DevicePairing;
