import { Charger, ChargerPreferences, ChargerStatus, ChargingSchedule } from '@hiven-energy/hiven-client';
import { ChainLinkIcon } from '@hiven-energy/hiven-ui';
import { useNavigation } from '@react-navigation/native';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';

import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import Divider from 'src/components/Divider/Divider';
import Properties from 'src/containers/device-overview/Properties/Properties';
import { RouteId } from 'src/nav/types';
import { useDeleteDevice, useSteveUrl, useUpdateChargerPreferences } from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';

import * as styled from './styles';
import { prepareConnectionSettings, prepareDetails, prepareDiagnostics } from './utils';

type ModalType = 'detach-device' | 'delete-device';

interface Props {
  charger: Charger;
  status?: ChargerStatus;
  preferences?: ChargerPreferences;
  schedule?: ChargingSchedule;
}

const DeviceDetails: FC<Props> = ({ charger, status, preferences, schedule }) => {
  const intl = useIntl();
  const toast = useToast();
  const navigation = useNavigation();
  const { trackButtonClick, trackDeleteDevice, trackChargerPreferencesChange } = useAnalytics();

  const [visibleModal, setVisibleModal] = useState<ModalType>();

  const { data: steveUrl } = useSteveUrl();

  const preferencesMutation = useUpdateChargerPreferences({
    onSuccess: () => {
      const message = intl.formatMessage({ id: 'charger.deviceOverview.deviceDetails.detach.success' });
      toast.show(message, { type: 'success' });
    },
    onError: () => {
      const message = intl.formatMessage({ id: 'charger.deviceOverview.deviceDetails.detach.error' });
      toast.show(message, { type: 'danger' });
    },
  });

  const deleteDeviceMutation = useDeleteDevice({
    onSuccess: () => {
      const message = intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.delete.success' });
      toast.show(message, { type: 'success' });
      navigation.navigate(RouteId.Initial);
    },
    onError: () => {
      const message = intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.delete.error' });
      toast.show(message, { type: 'danger' });
    },
  });

  const details = prepareDetails(charger, status, intl);
  const diagnostics = prepareDiagnostics(status, preferences, schedule, intl);
  const connectionSettings = prepareConnectionSettings(charger, steveUrl?.url, intl);
  const standalone = !preferences?.associatedDeviceId;

  const handleDetachDevicePress = () => {
    trackButtonClick('charger.deviceOverview.deviceDetails.detach.button');
    setVisibleModal('detach-device');
  };

  const handleDetachDeviceCancel = () => {
    trackButtonClick('common.cancel');
    setVisibleModal(undefined);
  };

  const handleDetachDeviceConfirm = () => {
    if (!preferences) return;
    const newPreferences: ChargerPreferences = {
      ...preferences,
      associatedDeviceId: 'DISSOCIATE',
    };
    preferencesMutation.mutate({ deviceId: charger.id, preferences: newPreferences });
    setVisibleModal(undefined);
    trackChargerPreferencesChange(newPreferences, charger.id);
  };

  const handleDeleteDevicePress = () => {
    trackButtonClick('common.deviceOverview.deviceDetails.delete.button');
    if (standalone) {
      setVisibleModal('delete-device');
    } else {
      toast.show(intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.remove.error.detachRequired' }), {
        type: 'danger',
      });
    }
  };

  const handleDeleteDeviceCancel = () => {
    trackButtonClick('common.cancel');
    setVisibleModal(undefined);
  };

  const handleDeleteDeviceConfirm = () => {
    trackDeleteDevice('common.confirm', charger.id);
    deleteDeviceMutation.mutate(charger.id);
    setVisibleModal(undefined);
  };

  return (
    <>
      <styled.Container>
        <Properties
          title={intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.title' })}
          properties={details}
        />
        <Divider />
        <Properties
          title={intl.formatMessage({ id: 'common.deviceOverview.diagnostics.title' })}
          properties={diagnostics}
        />
        <Divider />
        <Properties
          title={intl.formatMessage({ id: 'charger.deviceOverview.ocppSettings.title' })}
          properties={connectionSettings}
          icon={ChainLinkIcon}
        />
        <Divider />
        {!standalone && (
          <styled.DetachButton
            title={intl.formatMessage({ id: 'charger.deviceOverview.deviceDetails.detach.button' })}
            onPress={handleDetachDevicePress}
          />
        )}
        <styled.DeleteButton
          title={intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.delete.button' })}
          onPress={handleDeleteDevicePress}
        />
      </styled.Container>
      {visibleModal === 'detach-device' && (
        <ConfirmationModal
          title={intl.formatMessage({ id: 'charger.deviceOverview.deviceDetails.detach.modal.title' })}
          content={intl.formatMessage({ id: 'charger.deviceOverview.deviceDetails.detach.modal.content' })}
          onConfirm={handleDetachDeviceConfirm}
          onCancel={handleDetachDeviceCancel}
        />
      )}
      {visibleModal === 'delete-device' && (
        <ConfirmationModal
          title={intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.delete.modal.title' })}
          content={intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.delete.modal.content' })}
          onConfirm={handleDeleteDeviceConfirm}
          onCancel={handleDeleteDeviceCancel}
        />
      )}
    </>
  );
};

export default React.memo(DeviceDetails);
