import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';

interface Props {
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
}

export const DetachConfirmation: FC<Props> = ({ onConfirm, onCancel }) => {
  const intl = useIntl();

  return (
    <ConfirmationModal
      title={intl.formatMessage({ id: 'common.preferences.chargingLocation.confirmation.detach.dialog.title' })}
      content={intl.formatMessage({ id: 'common.preferences.chargingLocation.confirmation.detach.dialog.content' })}
      confirmLabel={intl.formatMessage({
        id: 'common.preferences.chargingLocation.confirmation.detach.dialog.confirm',
      })}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};
