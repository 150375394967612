import { colors, IconProps, InfoIcon, Typography } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';

import { Property } from '../Property/Property';

import * as styled from './styles';

interface Props {
  title: string;
  properties: Property[];
  icon?: FC<IconProps>;
}

export type Value = string | number | boolean | undefined;

const Properties: FC<Props> = ({ title, properties, icon: Icon = InfoIcon }) => (
  <styled.Container>
    <styled.Header>
      <Icon size={20} color={colors.hintGrey} />
      <Typography>{title}</Typography>
    </styled.Header>
    <styled.Content>
      {properties.map(property => (
        <styled.Property key={property.name} {...property} />
      ))}
    </styled.Content>
  </styled.Container>
);

export default React.memo(Properties);
