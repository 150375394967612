import { ThemeProvider } from '@hiven-energy/hiven-ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { render as _render } from '@testing-library/react-native';
import React, { PropsWithChildren } from 'react';

import { AppThemeProvider, hivenAppTheme } from 'src/app-theme';
import { SessionContext, SessionState } from 'src/store/session';

import * as fixtures from './fixtures';
import I18NProvider from './I18NProvider';

interface RenderOptions {
  sessionState?: SessionState;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      retry: false,
    },
    mutations: {
      cacheTime: 0,
      retry: false,
    },
  },
});

export const render = (children: React.ReactNode, options?: RenderOptions) => {
  return _render(<TestAppProvider options={options}>{children}</TestAppProvider>);
};

export const TestAppProvider = ({ children, options }: PropsWithChildren<{ options?: RenderOptions }>) => (
  <I18NProvider>
    <ThemeProvider>
      <AppThemeProvider theme={hivenAppTheme}>
        <SessionContext.Provider value={options?.sessionState || fixtures.sessionState}>
          <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </SessionContext.Provider>
      </AppThemeProvider>
    </ThemeProvider>
  </I18NProvider>
);
