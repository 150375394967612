import { VehicleIntegration, VehicleManufacturer } from '@hiven-energy/hiven-client';

import { Brand, VehicleManufacturerWithoutStartStop } from './types';

export const vehicleManufacturerToNameMap: Record<VehicleManufacturer | VehicleManufacturerWithoutStartStop, string> = {
  [VehicleManufacturer.BMW]: 'BMW',
  [VehicleManufacturer.CITROEN]: 'Citroën',
  [VehicleManufacturer.CUPRA]: 'Cupra',
  [VehicleManufacturer.DACIA]: 'Dacia',
  [VehicleManufacturer.DS]: 'DS',
  [VehicleManufacturer.FORD]: 'Ford',
  [VehicleManufacturer.HYUNDAI]: 'Hyundai',
  [VehicleManufacturer.JAGUAR]: 'Jaguar',
  [VehicleManufacturer.JEEP]: 'Jeep',
  [VehicleManufacturer.KIA]: 'Kia',
  [VehicleManufacturer.LAND_ROVER]: 'Land Rover',
  [VehicleManufacturer.MG]: 'MG',
  [VehicleManufacturer.MINI]: 'Mini',
  [VehicleManufacturer.OPEL]: 'Opel',
  [VehicleManufacturer.PEUGEOT]: 'Peugeot',
  [VehicleManufacturer.SKODA]: 'Škoda',
  [VehicleManufacturer.TESLA]: 'Tesla',
  [VehicleManufacturer.TOYOTA]: 'Toyota',
  [VehicleManufacturer.VAUXHALL]: 'Vauxhall',
  [VehicleManufacturer.VOLKSWAGEN]: 'Volkswagen',
  [VehicleManufacturer.VOLVO]: 'Volvo',
  [VehicleManufacturerWithoutStartStop.ALFA_ROMEO]: 'Alfa Romeo',
  [VehicleManufacturerWithoutStartStop.AUDI]: 'Audi',
  [VehicleManufacturerWithoutStartStop.FIAT]: 'Fiat',
  [VehicleManufacturerWithoutStartStop.MAZDA]: 'Mazda',
  [VehicleManufacturerWithoutStartStop.MERCEDES]: 'Mercedes-Benz',
  [VehicleManufacturerWithoutStartStop.NISSAN]: 'Nissan',
  [VehicleManufacturerWithoutStartStop.PORSCHE]: 'Porsche',
  [VehicleManufacturerWithoutStartStop.RENAULT]: 'Renault',
};

const brandsWithStartStop: Brand[] = [
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.BMW],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.BMW,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.CITROEN],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.CITROEN,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.CUPRA],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.CUPRA,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.DACIA],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.DACIA,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.DS],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.DS,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.FORD],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.FORD,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.HYUNDAI],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.HYUNDAI,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.JAGUAR],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.JAGUAR,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.JEEP],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.JEEP,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.KIA],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.KIA,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.LAND_ROVER],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.LAND_ROVER,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.MG],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.MG,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.MINI],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.MINI,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.OPEL],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.OPEL,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.PEUGEOT],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.PEUGEOT,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.SKODA],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.SKODA,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.TESLA],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.TESLA,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.VAUXHALL],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.VAUXHALL,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturer.VOLKSWAGEN],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.VOLKSWAGEN,
  },
];

const brandsWithoutStartTop: Brand[] = [
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturerWithoutStartStop.AUDI],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.AUDI,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturerWithoutStartStop.ALFA_ROMEO],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.ALFA_ROMEO,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturerWithoutStartStop.FIAT],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.FIAT,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturerWithoutStartStop.MAZDA],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.MAZDA,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturerWithoutStartStop.MERCEDES],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.MERCEDES,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturerWithoutStartStop.PORSCHE],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.PORSCHE,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturerWithoutStartStop.NISSAN],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.NISSAN,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturerWithoutStartStop.VOLVO],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.VOLVO,
  },
  {
    name: vehicleManufacturerToNameMap[VehicleManufacturerWithoutStartStop.RENAULT],
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.RENAULT,
  },
];

export const brands: Brand[] = [...brandsWithStartStop, ...brandsWithoutStartTop].sort((a, b) =>
  a.name.localeCompare(b.name),
);
