import React, { FC } from 'react';

import LoaderComponent from 'src/components/Loader/Loader';
import { colors } from 'src/theme';

import * as styled from './styles';

const Loader: FC = () => (
  <styled.Container>
    <LoaderComponent size={80} color={colors.deepNavy} />
  </styled.Container>
);

export default React.memo(Loader);
