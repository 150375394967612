import { APP_BACKEND_URL } from '@env';
import axios from 'axios';
import { Platform } from 'react-native';

const IS_MOBILE = Platform.OS === 'android' || Platform.OS === 'ios';

const client = axios.create({ baseURL: APP_BACKEND_URL });

export const registerDevicePushToken = async (deviceToken: string, jwt: string) => {
  if (!IS_MOBILE) {
    throw new Error('Cannot register device push token for non mobile device');
  }
  return client.put(
    'notifications/push/token',
    { deviceToken, platform: Platform.OS },
    {
      headers: {
        Authorization: jwt,
      },
    },
  );
};
