import { Button as HivenButton, spacings, Typography } from '@hiven-energy/hiven-ui';
import { ImageBackground as NativeImageBackground } from 'react-native';
import styled, { css } from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

export const Container = styled(BaseView)`
  padding: ${spacings.xs}px;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Title = styled(Typography)`
  color: ${colors.white};
  padding: 0 ${spacings.xs}px;
  margin-top: ${spacings.m}px;
  margin-bottom: ${spacings.l}px;
  text-align: center;
`;

export const Button = styled(HivenButton)`
  width: 100%;
  margin-top: ${spacings.l}px;
  position: relative;
  z-index: 1;
`;

const ImageBackground = css`
  position: absolute;
  right: 0;
  left: 0;
`;

export const ImageBackgroundTop = styled(NativeImageBackground)`
  ${ImageBackground};
  top: 0;
  height: 250px;
`;

export const ImageBackgroundBottom = styled(NativeImageBackground)`
  ${ImageBackground};
  bottom: 0;
  height: 325px;
`;
