import { ClockIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { colors } from 'src/theme';

import * as styled from './styles';

const Placeholder: FC = () => (
  <styled.Container>
    <ClockIcon size={32} color={colors.pidgeonGray} />
    <styled.Hint variant="hint">
      <FormattedMessage id="timeSchedule.placeholder.hint" />
    </styled.Hint>
  </styled.Container>
);

export default React.memo(Placeholder);
