import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import _Property from '../Property/Property';

export const Container = styled(_Container)`
  margin-top: ${spacings.xxs}px;
  margin-bottom: ${spacings.xxs}px;
`;

export const Header = styled(_Container)`
  margin-bottom: 10px;
  flex-direction: row;
  align-items: center;
  gap: ${spacings.xxxs}px;
`;

export const Title = styled(Typography).attrs({ fontWeight: 'medium' })`
  font-size: 16px;
  line-height: 24px;
`;

export const Content = styled(_Container)`
  margin-left: 30px;
`;

export const Property = styled(_Property)`
  margin-top: ${spacings.xxxs}px;
`;
