import { Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Capacity = styled(Typography)`
  color: ${colors.deepNavy};
`;

export const Label = styled(Typography)`
  color: ${colors.stoneGrey};
`;
