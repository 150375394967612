import { ToastType } from '@hiven-energy/hiven-ui';
import React, { FC, ReactElement } from 'react';
import { Platform } from 'react-native';

import * as styled from './styles';

interface Props {
  type?: string;
  message: string | ReactElement;
}

const hivenToastTypeMap: Record<string, ToastType> = {
  success: 'success',
  warning: 'warning',
  danger: 'error',
};

const supportedToastTypes = Object.keys(hivenToastTypeMap);

const Toast: FC<Props> = ({ type: _type, message }) => {
  const type = _type && supportedToastTypes.includes(_type) ? _type : 'success';

  return (
    <styled.Toast
      type={hivenToastTypeMap[type]}
      message={message}
      styles={{
        textWrapper: Platform.OS === 'web' ? { flex: 1 } : undefined,
      }}
    />
  );
};

export default Toast;
