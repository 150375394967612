import { ChargerManufacturer } from '@hiven-energy/hiven-client';
import { ArrowRightIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { FlatList, ListRenderItem } from 'react-native';

import { useAppTheme } from 'src/app-theme';
import chargerMask from 'src/assets/charger/mask.png';
import OcppLogo from 'src/assets/logos/ocpp.svg';
import DeviceLogo from 'src/components/DeviceLogo/DeviceLogo';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useAnalytics } from 'src/services/analytics';
import { colors } from 'src/theme';

import { brands } from './constants';
import * as styled from './styles';
import { Brand } from './types';

export type RoutedProps =
  | {
      disableGoBack?: boolean;
    }
  | undefined;

type Props = ScreenProps<RouteId.ChargerBrands>;

const ChargerBrands: FC<Props> = ({ navigation }) => {
  const appTheme = useAppTheme();

  const { trackButtonClick } = useAnalytics();

  const handlePress = (item: Brand) => {
    trackButtonClick(item.name, 'ChargerBrand.manufacturer');
    if (item.manufacturer === ChargerManufacturer.WALLBOX) {
      navigation.navigate(RouteId.WallboxLogin);
      return;
    }
    navigation.navigate(RouteId.ChargerConnect);
  };

  const renderItem: ListRenderItem<Brand> = ({ item, index }) => (
    <styled.Brand
      $isLast={index === brands.length - 1}
      accessibilityLabel={item.name}
      testID={item.name}
      activeOpacity={0.5}
      onPress={() => handlePress(item)}
    >
      <styled.Icon>
        {item.manufacturer ? <DeviceLogo manufacturer={item.manufacturer} long /> : <OcppLogo width={80} height={80} />}
      </styled.Icon>
      <styled.Action backgroundColor={appTheme.main.color}>
        <styled.ImageBackground source={chargerMask} resizeMode="cover" />
        <styled.Button>
          <styled.Hint variant="h3">
            <FormattedMessage id="common.select" />
          </styled.Hint>
          <ArrowRightIcon color={colors.white} />
        </styled.Button>
      </styled.Action>
    </styled.Brand>
  );

  const keyExtractor = (item: Brand) => item.name;

  return (
    <styled.Container>
      <FlatList data={brands} renderItem={renderItem} keyExtractor={keyExtractor} />
    </styled.Container>
  );
};

export default ChargerBrands;
