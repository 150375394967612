import { Container as _Container, Overlay, spacings } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const ButtonsContainer = styled(_Container)`
  gap: ${spacings.xxs}px;
  padding-top: ${spacings.xxxs}px;
`;

export const ConfirmationOverlay = styled(Overlay)`
  position: relative;
  margin-top: -58px;
`;
