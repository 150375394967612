import { Dayjs } from 'dayjs';

import { getDate } from './date';

const DELAY_SECONDS = 10;

export const getNextUpdateDate = (lastUpdate: Dayjs, refreshSeconds: number): Dayjs => {
  const currentDate = getDate();
  const nextDate = lastUpdate.add(refreshSeconds + DELAY_SECONDS, 'seconds');
  return currentDate > nextDate ? currentDate.add(refreshSeconds, 'seconds') : nextDate;
};
