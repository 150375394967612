import { useEffect, useState } from 'react';
import { Dimensions, Platform, StatusBar } from 'react-native';

export const useScreenDimensions = (): [number, number] => {
  const isWeb = Platform.OS === 'web';

  const [dimensions, setDimensions] = useState<[number, number]>(() => {
    const { width, height } = Dimensions.get(isWeb ? 'window' : 'screen');
    return [width, height - (StatusBar?.currentHeight ?? 0)];
  });

  useEffect(() => {
    const updateDimensions = () => {
      const { width, height } = Dimensions.get(isWeb ? 'window' : 'screen');
      setDimensions([width, height - (StatusBar?.currentHeight ?? 0)]);
    };
    const listener = Dimensions.addEventListener('change', updateDimensions);
    return () => listener.remove();
  }, []);

  return dimensions;
};
