import { Container as _Container, spacings, TouchableOpacityContainer, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  flex: 1;
  flex-direction: column;
  padding: ${spacings.xxs}px;
  gap: ${spacings.xxs}px;
  flex-basis: auto;
`;

export const TariffInfoHintContainer = styled(_Container)`
  flex: 1;
  flex-basis: auto;
  border-style: dashed;
  border-color: ${colors.pidgeonGray};
  border-radius: 12px;
  border-width: 1px;
  padding: ${spacings.xxs}px;
  gap: ${spacings.xxs}px;
`;

export const TariffInfoContainer = styled(_Container)`
  border-color: ${colors.pidgeonGray};
  border-top-width: 1px;
  padding-top: ${spacings.xxs}px;
`;

export const TariffInfoTouchableContainer = styled(TouchableOpacityContainer)`
  flex: 1;
  flex-basis: auto;
  flex-direction: row;
`;

export const TariffInfoParametersContainer = styled(_Container)`
  flex: 1;
  flex-direction: column;
  gap: ${spacings.xxs}px;
`;

export const TariffInfoHeader = styled(Typography)`
  color: ${colors.stoneGrey};
  font-size: 12px;
`;

export const LocationHeader = styled(_Container)`
  flex: 1;
  flex-basis: auto;
`;

export const LocationContainer = styled(TouchableOpacityContainer)`
  flex: 1;
  flex-direction: row;
  flex-basis: auto;
  gap: ${spacings.xxs}px;
`;

export const LocationHeaderText = styled(Typography)`
  color: ${colors.deepNavy};
  font-size: 16px;
`;

export const LocationAddressText = styled(Typography)`
  color: ${colors.stoneGrey};
`;

export const ChevronIconContainer = styled(_Container)`
  justify-content: center;
`;

export const AddTariffInfoButton = styled(TouchableOpacityContainer)`
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
`;

export const AddTariffInfoText = styled(Typography)`
  color: ${colors.deepNavy};
`;
