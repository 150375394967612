import { Container as _Container, Button, spacings } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  margin-top: ${spacings.xxs}px;
`;

export const DetachButton = styled(Button).attrs({ type: 'secondary' })`
  margin-top: ${spacings.xxs}px;
`;

export const DeleteButton = styled(Button).attrs({
  type: 'secondary',
  colors: {
    enabled: { borderColor: colors.raspberryPink, titleColor: colors.raspberryPink },
    pressed: { borderColor: colors.punchRed, titleColor: colors.punchRed },
  },
})`
  margin-top: ${spacings.xxs}px;
`;
