import { useChargers } from 'src/queries/sdk';
import { Group, GroupType } from 'src/screens/brands/BrandGroups/types';

import { usePartner } from './usePartner';

const chargerIcon = require('src/assets/brandGroups/charger-icon.png');
const vehicleIcon = require('src/assets/brandGroups/vehicle-icon.png');

export const useBrandGroups = (hiddenGroupTypes: GroupType[]): Group[] => {
  const partner = usePartner();

  const { data: chargers } = useChargers({ enabled: partner.isWallbox });

  const defaultBrandGroups: Group[] = [
    {
      type: GroupType.VEHICLE,
      nameMessageId: 'common.vehicle',
      image: vehicleIcon,
    },
    {
      type: GroupType.CHARGER,
      nameMessageId: 'common.charger',
      image: chargerIcon,
    },
  ];

  const hideVehicleBrandGroup = (partner.isWallbox && !chargers?.length) || partner.isUniperSe;

  return defaultBrandGroups
    .filter(({ type }) => !hiddenGroupTypes.includes(type))
    .filter(item => item.type !== GroupType.VEHICLE || !hideVehicleBrandGroup);
};
