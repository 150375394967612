import { APP_ENV } from '@env';
import { DeviceType } from '@hiven-energy/hiven-client';
import { Button, EditIcon } from '@hiven-energy/hiven-ui';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { InteractionManager, RefreshControl } from 'react-native';
import { BottomSheetRef } from 'react-native-sheet';
import { useToast } from 'react-native-toast-notifications';

import { useA11y } from 'src/a11y';
import { BottomSheet } from 'src/components/BottomSheet/BottomSheet';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import { DetachConfirmation } from 'src/containers/charging-location/Preference/DetachConfirmation/DetachConfirmation';
import { RouteId, ScreenProps } from 'src/nav/types';
import {
  useDeleteUserLocation,
  useDevices,
  useUpdateChargerPreferences,
  useUpdateVehiclePreferences,
  useUserLocation,
  useUserLocations,
} from 'src/queries/sdk';
import { AttachedDevices } from 'src/screens/ChargingLocation/AttachedDevices/AttachedDevices';
import { ChargerHeartBeat } from 'src/screens/ChargingLocation/ChargerHeartBeat/ChargerHeartBeat';
import { TariffAndMeterDetails } from 'src/screens/ChargingLocation/TariffAndMeterDetails/TarifAndMeterDetails';
import { AttachedDeviceSelection } from 'src/screens/ChargingLocation/types';
import { useAnalytics } from 'src/services/analytics';
import { colors } from 'src/theme';
import { groupDevicesByLocation, isCharger } from 'src/utils/device';

import * as styled from './styles';

type Props = ScreenProps<RouteId.ChargingLocation>;

export interface RoutedProps {
  locationId: string;
}

const ChargingLocation: FC<Props> = ({
  route: {
    params: { locationId },
  },
  navigation,
}) => {
  const intl = useIntl();
  const a11y = useA11y();
  const toast = useToast();
  const sheetRef = useRef<BottomSheetRef>(null);

  const { trackChargerPreferencesChange, trackVehiclePreferencesChange, trackButtonClick } = useAnalytics();

  const userLocationQuery = useUserLocation(locationId);
  const userLocationsQuery = useUserLocations();
  const devicesByLocationQuery = useDevices({
    select: devices => groupDevicesByLocation(userLocationsQuery.data ?? [], devices),
  });
  const [selectedDevice, setSelectedDevice] = useState<AttachedDeviceSelection | null>(null);
  const [confirmDetachDevice, setConfirmDetachDevice] = useState(false);
  const [confirmDeleteLocation, setConfirmDeleteLocation] = useState(false);
  const [isSheetVisible, setIsSheetVisible] = useState(false);

  const updateChargerPreferences = useUpdateChargerPreferences({
    onSuccess: (_, { deviceId, preferences }) => {
      trackChargerPreferencesChange(preferences, deviceId);
      toast.show(intl.formatMessage({ id: 'ChargingLocation.detachDevice.toast.success' }), { type: 'success' });
    },
    onError: () => {
      const message = intl.formatMessage({ id: 'ChargingLocation.detachDevice.toast.error' });
      toast.show(message, { type: 'danger' });
    },
  });
  const updateVehiclePreferences = useUpdateVehiclePreferences({
    onSuccess: (_, { preferences, deviceId }) => {
      trackVehiclePreferencesChange(preferences, deviceId);
      toast.show(intl.formatMessage({ id: 'ChargingLocation.detachDevice.toast.success' }), { type: 'success' });
    },
    onError: () => {
      const message = intl.formatMessage({ id: 'ChargingLocation.detachDevice.toast.error' });
      toast.show(message, { type: 'danger' });
    },
  });

  const hasAttachedDevices =
    devicesByLocationQuery.data &&
    devicesByLocationQuery.data[locationId] &&
    devicesByLocationQuery.data[locationId].length > 0;

  const deleteLocationMutation = useDeleteUserLocation({
    onSuccess: () => {
      toast.show(intl.formatMessage({ id: 'ChargingLocation.deleteLocation.toast.success' }), { type: 'success' });
      navigation.navigate(RouteId.MyChargingLocations);
    },
    onError: () => {
      const message = intl.formatMessage({ id: 'ChargingLocation.deleteLocation.toast.error' });
      toast.show(message, { type: 'danger' });
    },
  });

  const updateDevice = useCallback(() => {
    if (!selectedDevice) return;

    if (selectedDevice.type === DeviceType.VEHICLE) {
      updateVehiclePreferences.mutate({
        deviceId: selectedDevice.device.id,
        preferences: selectedDevice.preferences,
      });
    } else if (selectedDevice.type === DeviceType.CHARGER) {
      updateChargerPreferences.mutate({
        deviceId: selectedDevice.device.id,
        preferences: selectedDevice.preferences,
      });
    }
  }, [selectedDevice, updateChargerPreferences, updateVehiclePreferences]);

  const handleEditAddressDetails = useCallback(() => {
    navigation.navigate(RouteId.SetChargingLocation, { locationId });
  }, [locationId]);

  const closeSheet = () => {
    setIsSheetVisible(false);
  };

  useEffect(() => {
    if (!sheetRef.current) return;

    if (isSheetVisible) {
      sheetRef.current.show();
    } else {
      if (!confirmDetachDevice) {
        setSelectedDevice(null);
      }
    }
  }, [isSheetVisible]);

  useEffect(() => {
    if (selectedDevice && sheetRef.current) {
      setIsSheetVisible(true);
    }
  }, [selectedDevice]);

  useEffect(() => {
    if (!userLocationQuery.data) {
      navigation.navigate(RouteId.MyChargingLocations);
    }
  }, [userLocationQuery.data]);

  const handleDisconnectDevicePress = () => {
    trackButtonClick('ChargingLocation.deviceSheet.detachDevice.button');
    setConfirmDetachDevice(true);
    sheetRef.current?.hide();
  };

  const handleDetachDeviceConfirm = () => {
    trackButtonClick('common.confirm', 'ChargingLocation.detachDevice');
    if (!selectedDevice) return;

    updateDevice();
    setConfirmDetachDevice(false);
    setSelectedDevice(null);
  };

  const handleDeleteLocationConfirm = () => {
    trackButtonClick('common.confirm', 'ChargingLocation.deleteLocation');
    deleteLocationMutation.mutate(locationId);
    setConfirmDeleteLocation(false);
  };

  const handleDeleteLocationCancel = () => {
    trackButtonClick('common.cancel', 'ChargingLocation.deleteLocation');
    setConfirmDeleteLocation(false);
  };

  const handleDetachDeviceCancel = () => {
    trackButtonClick('common.cancel', 'ChargingLocation.detachDevice');
    setConfirmDetachDevice(false);
    setSelectedDevice(null);
  };

  const handleDeleteLocationPress = () => {
    trackButtonClick('ChargingLocation.deleteLocation.button');
    setConfirmDeleteLocation(true);
    sheetRef.current?.hide();
  };

  const handlePressDeviceDetails = () => {
    trackButtonClick('ChargingLocation.deviceSheet.checkDeviceDetails.button');
    if (selectedDevice) {
      if (selectedDevice.device.type === DeviceType.VEHICLE) {
        navigation.navigate(RouteId.VehicleOverview, { deviceId: selectedDevice.device.id });
      } else if (selectedDevice.device.type === DeviceType.CHARGER) {
        navigation.navigate(RouteId.ChargerOverview, { deviceId: selectedDevice.device.id });
      }
      InteractionManager.runAfterInteractions(() => sheetRef.current?.hide());
    }
  };

  const devices = devicesByLocationQuery.data && devicesByLocationQuery.data[locationId];

  if (!userLocationQuery.data) return null;

  const location = userLocationQuery.data;

  return (
    <styled.Container safeAreaEdges={['left', 'right']}>
      <styled.FormContent
        refreshControl={
          <RefreshControl
            tintColor={colors.deepNavy}
            refreshing={devicesByLocationQuery.isLoading}
            onRefresh={devicesByLocationQuery.refetch}
          />
        }
      >
        <styled.Section>
          <styled.SectionTitle fontWeight="semiBold">
            {intl.formatMessage({ id: 'ChargingLocation.addressDetails.title' })}
          </styled.SectionTitle>
          <styled.SectionItem>
            <styled.SectionItemHeader>
              <styled.SectionItemTitle variant="h4">{location.name}</styled.SectionItemTitle>
              <styled.EditButton
                icon={EditIcon}
                onPress={handleEditAddressDetails}
                type="plain"
                accessibilityLabel={intl.formatMessage({ id: 'common.edit' })}
                testID={a11y.formatLabel('common.edit')}
              />
            </styled.SectionItemHeader>
            <styled.SectionItemText>{location.streetAddress}</styled.SectionItemText>
            <styled.SectionItemText>{`${location.postalCode} ${location.city}`}</styled.SectionItemText>
          </styled.SectionItem>
          {hasAttachedDevices && (
            <styled.LocationDeleteDisabledText>
              {intl.formatMessage({ id: 'ChargingLocation.deleteLocation.disabled.text' })}
            </styled.LocationDeleteDisabledText>
          )}
          <styled.DeleteButton
            colors={{
              enabled: { borderColor: colors.raspberryPink, titleColor: colors.raspberryPink },
              pressed: {
                titleColor: colors.white,
                borderColor: colors.raspberryPink,
                backgroundColor: colors.raspberryPink,
              },
            }}
            type="secondary"
            title={intl.formatMessage({ id: 'ChargingLocation.deleteLocation.button' })}
            onPress={handleDeleteLocationPress}
            disabled={hasAttachedDevices || deleteLocationMutation.isLoading || deleteLocationMutation.isSuccess}
          />
        </styled.Section>
        <AttachedDevices
          isLoading={devicesByLocationQuery.isLoading}
          devices={devices}
          onDeviceMenuOpen={setSelectedDevice}
          location={location}
        />
        <TariffAndMeterDetails tariffInfo={location.tariffInfo} locationId={location.id} />
      </styled.FormContent>
      <BottomSheet
        height={320}
        ref={sheetRef}
        showDragIcon={false}
        draggable={false}
        onCloseFinish={closeSheet}
        borderRadius={24}
      >
        <styled.DeviceSheetTitle fontWeight="semiBold">
          {selectedDevice?.device.attributes.name}
        </styled.DeviceSheetTitle>
        {selectedDevice && isCharger(selectedDevice.device) && (
          <ChargerHeartBeat chargerId={selectedDevice.device.id} />
        )}
        {selectedDevice && (
          <styled.DeviceSheetButtonsGroup>
            {APP_ENV === 'test' && (
              <Button
                type="secondary"
                title={intl.formatMessage({ id: 'ChargingLocation.deviceSheet.checkDeviceDetails.button' })}
                onPress={handlePressDeviceDetails}
              />
            )}
            <Button
              type="secondary"
              title={intl.formatMessage({ id: 'ChargingLocation.deviceSheet.detachDevice.button' })}
              onPress={handleDisconnectDevicePress}
            />
            <Button
              type="tertiary"
              title={intl.formatMessage({ id: 'common.cancel' })}
              onPress={sheetRef.current?.hide}
            />
          </styled.DeviceSheetButtonsGroup>
        )}
      </BottomSheet>
      {confirmDetachDevice && !isSheetVisible && (
        <DetachConfirmation onConfirm={handleDetachDeviceConfirm} onCancel={handleDetachDeviceCancel} />
      )}
      {confirmDeleteLocation && !isSheetVisible && (
        <ConfirmationModal
          title={intl.formatMessage({ id: 'ChargingLocation.deleteLocation.dialog.title' })}
          content={intl.formatMessage({ id: 'ChargingLocation.deleteLocation.dialog.content' })}
          onConfirm={handleDeleteLocationConfirm}
          onCancel={handleDeleteLocationCancel}
        />
      )}
    </styled.Container>
  );
};

export default ChargingLocation;
