import { useIsFocused, useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';

import Loader from 'src/components/Loader/Loader';
import { usePartner } from 'src/hooks/usePartner';
import { RouteId } from 'src/nav/types';
import { useDevices } from 'src/queries/sdk';
import { isCharger, isVehicle } from 'src/utils/device';

const Initial = () => {
  const navigation = useNavigation();
  const isFocused = useIsFocused();

  const partner = usePartner();

  const devicesQuery = useDevices();

  useEffect(() => {
    if (!isFocused || devicesQuery.isFetching || !devicesQuery.data) return;

    if (devicesQuery.data.length === 0) {
      if (partner.isHiven) {
        navigation.navigate(RouteId.BrandGroups, { disableGoBack: true });
        return;
      }
      if (partner.isWallbox || partner.isUniperSe) {
        navigation.navigate(RouteId.ChargerBrands, { disableGoBack: true });
        return;
      }
    }

    const chargers = devicesQuery.data.filter(isCharger);
    const vehicles = devicesQuery.data.filter(isVehicle);

    const shouldRedirectToAssociatedDashboard =
      chargers.length === 1 && vehicles.length === 1 && !!chargers[0].settings.chargingPreferences?.associatedDeviceId;

    if (shouldRedirectToAssociatedDashboard) {
      navigation.navigate(RouteId.ChargerDashboard, { deviceId: chargers[0].id });
      return;
    }

    const [device] = devicesQuery.data;
    if (isVehicle(device)) {
      navigation.navigate(RouteId.VehicleDashboard, { deviceId: device.id });
    }
    if (isCharger(device)) {
      navigation.navigate(RouteId.ChargerDashboard, { deviceId: device.id });
    }
  }, [isFocused, devicesQuery.isFetching, devicesQuery.data, partner]);

  return <Loader fullScreen />;
};

export default Initial;
