import { Device, DeviceType } from '@hiven-energy/hiven-client';
import { CarIcon, ChainLinkIcon, ChevronRightIcon, IconProps, PlugIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';

import { colors } from 'src/theme';

import * as styled from './styles';

const deviceTypeToIconMap: Record<DeviceType, FC<IconProps>> = {
  [DeviceType.VEHICLE]: CarIcon,
  [DeviceType.CHARGER]: PlugIcon,
};

interface Props {
  device: Device;
  chips: string[];
  onPress: VoidFunction;
}

const DeviceItem: FC<Props> = ({ device, chips, onPress }) => {
  const Icon = deviceTypeToIconMap[device.type];

  return (
    <styled.Container onPress={onPress}>
      <styled.Heading>
        <styled.IconWrapper>
          <Icon color={colors.white} size={20} />
        </styled.IconWrapper>
        <styled.DeviceName>{device.attributes.name}</styled.DeviceName>
        <ChevronRightIcon color={colors.hintGrey} />
      </styled.Heading>
      {!!chips.length && (
        <styled.Chips>
          {chips.map((name, index) => (
            <styled.Chip key={index}>
              <ChainLinkIcon size={20} color={colors.hintGrey} />
              <styled.ChipLabel>{name}</styled.ChipLabel>
            </styled.Chip>
          ))}
        </styled.Chips>
      )}
    </styled.Container>
  );
};

export default React.memo(DeviceItem);
