import { QueryStatus } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import RefreshButton from 'src/components/RefreshButton/RefreshButton';
import { usePeriodicalUpdate } from 'src/hooks/usePeriodicalUpdate';
import { getDate } from 'src/utils/date';
import { ErrorReason } from 'src/utils/queries';

import * as styled from './styles';

const DATE_REFRESH_INTERVAL = 1000; // 1 second

interface Props {
  status: QueryStatus;
  errorReason?: ErrorReason;
  onRetry: VoidFunction;
  reloadIconTestID: string;
  reloadIconAccessibilityLabel: string;
}

const RecentUpdate: FC<Props> = ({
  status,
  errorReason = ErrorReason.UNKNOWN,
  onRetry,
  reloadIconTestID,
  reloadIconAccessibilityLabel,
}) => {
  const intl = useIntl();

  usePeriodicalUpdate(DATE_REFRESH_INTERVAL);

  const [lastSuccessfulRefreshDate, setLastSuccessfulRefreshDate] = useState<Dayjs>();

  useEffect(() => {
    setLastSuccessfulRefreshDate(getDate());
  }, [status]);

  const isLoading = status === 'loading';

  const message = {
    loading: intl.formatMessage({ id: 'recentUpdate.loading' }),
    error:
      errorReason === ErrorReason.NOT_FOUND
        ? intl.formatMessage({ id: 'recentUpdate.notFound' })
        : intl.formatMessage({ id: 'recentUpdate.failure' }),
    success: intl.formatMessage({ id: 'recentUpdate.success' }, { relativeDate: lastSuccessfulRefreshDate?.fromNow() }),
  }[status];

  return (
    <styled.Wrapper>
      <styled.Title>{message}</styled.Title>
      <RefreshButton
        loading={isLoading}
        disabled={isLoading}
        testID={reloadIconTestID}
        accessibilityLabel={reloadIconAccessibilityLabel}
        onPress={onRetry}
      />
    </styled.Wrapper>
  );
};

export default React.memo(RecentUpdate);
