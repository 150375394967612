import { VehicleIntegration, VehicleManufacturer } from '@hiven-energy/hiven-client';

export interface Brand {
  name: string;
  integration: VehicleIntegration.SMARTCAR;
  manufacturer: VehicleManufacturer | VehicleManufacturerWithoutStartStop;
}

export enum VehicleManufacturerWithoutStartStop {
  ALFA_ROMEO = 'ALFA_ROMEO',
  AUDI = 'AUDI',
  FIAT = 'FIAT',
  MAZDA = 'MAZDA',
  MERCEDES = 'MERCEDES',
  NISSAN = 'NISSAN',
  PORSCHE = 'PORSCHE',
  RENAULT = 'RENAULT',
  VOLVO = 'VOLVO',
}
