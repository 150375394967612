import { spacings, TouchableOpacityContainer, Typography } from '@hiven-energy/hiven-ui';
import { View as NativeView } from 'react-native';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Wrapper = styled(NativeView)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${spacings.xxxs}px;
  padding-left: ${spacings.xxs}px;
`;

export const Title = styled(Typography)`
  color: ${colors.stoneGrey};
`;

export const IconWrapper = styled(TouchableOpacityContainer)`
  margin-left: ${spacings.xxxs}px;
`;
