import { VehicleChargingIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import PreferenceOverview from 'src/components/PreferenceOverview/PreferenceOverview';
import { useVehicle } from 'src/queries/sdk';

import * as styled from './styles';

interface Props {
  vehicleId: string | undefined;
  onEditPress: VoidFunction;
}

const DevicePairing: FC<Props> = ({ vehicleId, onEditPress }) => {
  const intl = useIntl();

  const vehicle = useVehicle(vehicleId!, { enabled: !!vehicleId });

  return (
    <PreferenceOverview
      title={intl.formatMessage({ id: 'ChargerPreferences.devicePairing.overview.title' })}
      icon={VehicleChargingIcon}
      onEditPress={onEditPress}
      analyticsPlace="ChargerPreferences.devicePairing"
    >
      <styled.VehicleName variant="h4">{vehicle.data?.attributes.name ?? '-'}</styled.VehicleName>
    </PreferenceOverview>
  );
};

export default React.memo(DevicePairing);
