import { Button } from '@hiven-energy/hiven-ui';
import React, { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAnalytics } from 'src/services/analytics';
import { colors } from 'src/theme';

import EndChargingModal from './modals/EndChargingModal';
import StartChargingModal from './modals/StartChargingModal';
import * as styled from './styles';

type ModalType = 'start-charging' | 'end-charging';

interface Props {
  isCharging: boolean;
  handleStartConfirm: VoidFunction;
  handleEndConfirm: VoidFunction;
}

const ChargingActions: FC<Props> = ({ isCharging, handleStartConfirm, handleEndConfirm }) => {
  const intl = useIntl();
  const { trackButtonClick } = useAnalytics();

  const [modalVisible, setModalVisible] = useState<ModalType>();

  const hideModal = () => {
    setModalVisible(undefined);
  };

  const handleStopCharging = useCallback(() => {
    trackButtonClick('chargingActions.stop.title');
    setModalVisible('end-charging');
  }, [trackButtonClick]);

  const handleStartCharging = useCallback(() => {
    trackButtonClick('chargingActions.start.title');
    setModalVisible('start-charging');
  }, [trackButtonClick]);

  return (
    <styled.Container>
      <styled.ButtonsWrapper>
        <Button
          disabled={isCharging}
          title={intl.formatMessage({ id: 'chargingActions.start.title' })}
          onPress={handleStartCharging}
          colors={{
            enabled: { backgroundColor: colors.punchRed },
          }}
        />
        <Button
          disabled={!isCharging}
          title={intl.formatMessage({ id: 'chargingActions.stop.title' })}
          onPress={handleStopCharging}
          colors={{
            enabled: { backgroundColor: colors.pidgeonGray, titleColor: colors.black },
          }}
        />
      </styled.ButtonsWrapper>
      {modalVisible === 'start-charging' && (
        <StartChargingModal
          onConfirm={() => {
            handleStartConfirm();
            hideModal();
          }}
          onCancel={hideModal}
        />
      )}
      {modalVisible === 'end-charging' && (
        <EndChargingModal
          onConfirm={() => {
            handleEndConfirm();
            hideModal();
          }}
          onCancel={hideModal}
        />
      )}
    </styled.Container>
  );
};

export default React.memo(ChargingActions);
