import { Dots } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import { useAnalytics } from 'src/services/analytics';

import * as styled from './styles';

interface Props {
  count: number;
  current: number;
  onChange: (index: number) => void;
  onFinish: VoidFunction;
  children: React.ReactNode;
}

const Stepper: FC<Props> = ({ count, current, onChange, onFinish, children }) => {
  const intl = useIntl();
  const a11y = useA11y();

  const { trackButtonClick } = useAnalytics();

  const handleNextStep = () => {
    trackButtonClick('Onboarding.next');
    onChange(current + 1);
  };

  const handleGetStartedPress = (placeClick: string) => () => {
    trackButtonClick(placeClick);
    onFinish();
  };

  const isLastStep = current === count - 1;

  return (
    <styled.Container>
      <styled.ChildrenWrapper>{children}</styled.ChildrenWrapper>
      <styled.NavigationWrapper>
        <styled.DotsWrapper>
          <Dots active={current} count={count} onChange={onChange} />
        </styled.DotsWrapper>
        <styled.ActionsWrapper>
          {isLastStep ? (
            <styled.GetStarted
              title={intl.formatMessage({ id: 'Onboarding.getStarted' })}
              onPress={handleGetStartedPress('Onboarding.getStarted')}
            />
          ) : (
            <>
              <styled.NextAction
                title={intl.formatMessage({ id: 'Onboarding.next' })}
                onPress={handleNextStep}
                testID={a11y.formatLabel('Onboarding.nextPageButton')}
              />
              <styled.SkipAction
                type="secondary"
                title={intl.formatMessage({ id: 'Onboarding.skip' })}
                onPress={handleGetStartedPress('Onboarding.skip')}
                testID={a11y.formatLabel('Onboarding.skipIntroButton')}
              />
            </>
          )}
        </styled.ActionsWrapper>
      </styled.NavigationWrapper>
    </styled.Container>
  );
};

export default React.memo(Stepper);
