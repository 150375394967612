import findLastIndex from 'lodash/findLastIndex';

import { RouteId } from 'src/nav/types';

import { INITIAL_TARGET } from './constants';
import { NavigationState, RouteWithParams } from './types';

export const getNavigationTarget = (state: NavigationState): RouteWithParams | undefined => {
  const brandGroupsRouteIndex = findLastIndex(state.routes, route => route.name === RouteId.BrandGroups);

  const targetRoute = brandGroupsRouteIndex !== -1 ? state.routes[brandGroupsRouteIndex - 1] : undefined;

  if (!targetRoute) return undefined;

  return targetRoute.name === RouteId.Initial
    ? INITIAL_TARGET
    : ([targetRoute.name, targetRoute.params] as RouteWithParams);
};
