import { EnergyUnit } from '@hiven-energy/hiven-client';
import { HelpIcon, InputField, Typography } from '@hiven-energy/hiven-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { z } from 'zod';

import { useA11y } from 'src/a11y';
import BaseView from 'src/components/BaseView/BaseView';
import FormField from 'src/components/form-fields/FormField';
import { ScreenScrollView } from 'src/components/ScreenScrollView/ScreenScrollView';
import * as validators from 'src/utils/validators';

import * as styled from './styles';
import {
  checkIfContainsMultipleCommasOrDots,
  formatBatteryCapacityToDisplayValue,
  formatBatteryCapacityToInputValue,
} from './utils';

const MIN_VALUE = 5;
const MAX_VALUE = 120;
const NON_NUMERIC_CHARACTERS_REGEX = '[^0-9,.]';

interface BatteryCapacityForm {
  capacity: string;
}

interface Props {
  capacity: number | undefined;
  saving?: boolean;
  onConfirm: (capacity: number) => void;
}

const BatteryCapacity: FC<Props> = ({ capacity: initialCapacity, saving, onConfirm }) => {
  const intl = useIntl();
  const a11y = useA11y();

  const schema = useMemo(
    () =>
      z.object({
        capacity: validators.batteryCapacity(intl, MIN_VALUE, MAX_VALUE),
      }),
    [intl],
  );

  const form = useForm<BatteryCapacityForm>({
    values: {
      capacity: initialCapacity ? formatBatteryCapacityToInputValue(initialCapacity) : '',
    },
    resolver: zodResolver(schema),
  });

  const handleSubmitConfirm = (form: BatteryCapacityForm) => {
    onConfirm(formatBatteryCapacityToDisplayValue(form.capacity));
  };

  return (
    <BaseView>
      <ScreenScrollView>
        <Typography variant="h3">
          <FormattedMessage id="ChargerPreferences.vehicleBatteryCapacity.preference.title" />
        </Typography>
        <styled.Form>
          <Controller
            name="capacity"
            control={form.control}
            render={({ field: { value, onChange, onBlur }, formState: { errors } }) => {
              const error = errors['capacity'];
              const handleChange = (value: string) => {
                const newValue = value.replace(new RegExp(NON_NUMERIC_CHARACTERS_REGEX, 'g'), '');
                const hasMultipleCommasOrDots = checkIfContainsMultipleCommasOrDots(value);
                if (!hasMultipleCommasOrDots) {
                  onChange(newValue);
                }
              };
              return (
                <FormField error={error}>
                  <InputField
                    keyboardType="numeric"
                    value={value}
                    onChange={handleChange}
                    onBlur={onBlur}
                    error={!!error}
                    autoCapitalize="none"
                    testID="capacity"
                    styles={{
                      input: styled.getInputStyles(),
                    }}
                  />
                </FormField>
              );
            }}
          />
          <styled.Unit variant="h1">{EnergyUnit.KWH}</styled.Unit>
        </styled.Form>
        <styled.Hint>
          <styled.IconWrapper>
            <HelpIcon />
          </styled.IconWrapper>
          <styled.Title variant="h2">
            <FormattedMessage id="ChargerPreferences.vehicleBatteryCapacity.preference.hint.title" />
          </styled.Title>
          <styled.Text>
            <FormattedMessage id="ChargerPreferences.vehicleBatteryCapacity.preference.hint.text" />
          </styled.Text>
          <styled.Text>
            <FormattedMessage id="ChargerPreferences.vehicleBatteryCapacity.preference.hint.text.option1" />
          </styled.Text>
          <styled.Text>
            <FormattedMessage id="ChargerPreferences.vehicleBatteryCapacity.preference.hint.text.option2" />
          </styled.Text>
        </styled.Hint>
      </ScreenScrollView>
      <styled.SaveButton
        title={intl.formatMessage({ id: 'common.continue' })}
        loading={saving}
        disabled={saving || !form.formState.isValid}
        testID={a11y.formatLabel('common.continue')}
        onPress={form.handleSubmit(handleSubmitConfirm)}
      />
    </BaseView>
  );
};

export default React.memo(BatteryCapacity);
