import { CognitoUser } from '@aws-amplify/auth';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ISignUpResult } from 'amazon-cognito-identity-js';

import { Language } from 'src/i18n';
import { CognitoError } from 'src/screens/authentication/utils';
import { SignOutOptions, useSessionService } from 'src/services/session';

import { MutationOptions, QueryOptions } from './types';

export const useSignIn = (
  options?: MutationOptions<{ email: string; password: string }, CognitoUser, CognitoError>,
) => {
  const service = useSessionService();
  return useMutation({
    ...options,
    mutationFn: ({ email, password }) => service.signIn(email, password),
  });
};

export const useSignUp = (
  options?: MutationOptions<{ email: string; password: string }, ISignUpResult, CognitoError>,
) => {
  const service = useSessionService();
  return useMutation({
    ...options,
    mutationFn: ({ email, password }) => service.signUp(email, password),
  });
};

export const useConfirmSignUp = (options?: MutationOptions<{ email: string; code: string }, void, CognitoError>) => {
  const service = useSessionService();
  return useMutation({
    ...options,
    mutationFn: ({ email, code }) => service.confirmSignUp(email, code),
  });
};

export const useResendCode = (options?: MutationOptions<string, void, CognitoError>) => {
  const service = useSessionService();
  return useMutation({
    ...options,
    mutationFn: email => service.resendCode(email),
  });
};

export const useSendCode = (options?: MutationOptions<string, void, CognitoError>) => {
  const service = useSessionService();
  return useMutation({
    ...options,
    mutationFn: email => service.sendCode(email),
  });
};

export const useResetPassword = (
  options?: MutationOptions<{ email: string; code: string; newPassword: string }, string, CognitoError>,
) => {
  const service = useSessionService();
  return useMutation({
    ...options,
    mutationFn: ({ email, code, newPassword }) => service.resetPassword(email, code, newPassword),
  });
};

export const useChangePassword = (
  options?: MutationOptions<{ oldPassword: string; newPassword: string }, string, CognitoError>,
) => {
  const service = useSessionService();
  return useMutation({
    ...options,
    mutationFn: ({ oldPassword, newPassword }) => service.changePassword(oldPassword, newPassword),
  });
};

export const useSignOut = (options?: MutationOptions<SignOutOptions | undefined, void, CognitoError>) => {
  const queryClient = useQueryClient();
  const service = useSessionService();
  return useMutation({
    ...options,
    mutationFn: options => service.signOut(options),
    onSuccess: (...args) => {
      queryClient.clear();
      options?.onSuccess?.(...args);
    },
  });
};

export const useDeleteAccount = (options?: MutationOptions<void, string | void, CognitoError>) => {
  const service = useSessionService();
  return useMutation({
    ...options,
    mutationFn: () => service.deleteAccount(),
  });
};

export const useUpdateLanguage = (options?: MutationOptions<Language, void, CognitoError>) => {
  const service = useSessionService();
  return useMutation({
    ...options,
    mutationFn: language => service.updateLanguage(language),
  });
};

export const useTermsAndConditions = (options?: QueryOptions<boolean>) => {
  const service = useSessionService();
  return useQuery(['termsAndConditions'], () => service.getTermsAndConditions(), options);
};

export const useUpdateTermsAndConditions = (options?: MutationOptions<boolean, void, CognitoError>) => {
  const service = useSessionService();
  return useMutation({
    ...options,
    mutationFn: accepted => service.updateTermsAndConditions(accepted),
  });
};

export const useUpdatePrivacyPolicy = (options?: MutationOptions<boolean, void, CognitoError>) => {
  const service = useSessionService();
  return useMutation({
    ...options,
    mutationFn: accepted => service.updatePrivacyPolicy(accepted),
  });
};
