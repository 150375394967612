import {
  Button as _Button,
  Container as _Container,
  spacings,
  TouchableOpacityContainer,
} from '@hiven-energy/hiven-ui';
import styled, { css } from 'styled-components/native';

import { colors } from 'src/theme';

export const Button = styled(_Button)`
  margin-bottom: ${spacings.s}px;
`;

export const Setting = styled(TouchableOpacityContainer).attrs({ activeOpacity: 0.5 })<{ $skipBorderBottom?: boolean }>`
  padding: ${spacings.xs}px 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  ${p =>
    !p.$skipBorderBottom &&
    css`
      border-bottom-width: 1px;
      border-bottom-color: ${colors.disabledGrey};
    `};
`;

export const Details = styled(_Container)`
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
