import { Container, Typography } from '@hiven-energy/hiven-ui';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Animated } from 'react-native';

import { useAnimatedValue } from 'src/hooks/useAnimatedValue';
import { formatSecondsToMinutesAndSeconds } from 'src/utils/time';

const TIMER_REFRESH_INTERVAL = 1 * 1000; // 1 second
const TIMER_DEFAULT_VALUE = 5 * 60; // 5 minutes

interface Props {
  seconds?: number;
}

const Timer: FC<Props> = ({ seconds = TIMER_DEFAULT_VALUE }) => {
  const timer = useRef<NodeJS.Timer>();

  const animatedOpacityValue = useAnimatedValue(1);

  const [value, setValue] = useState(seconds);

  useEffect(() => {
    setValue(seconds);
  }, [seconds]);

  useEffect(() => {
    timer.current = setInterval(() => {
      setValue(prevValue => prevValue - 1);
    }, TIMER_REFRESH_INTERVAL);

    return () => {
      clearInterval(timer.current);
    };
  }, []);

  useEffect(() => {
    if (value <= 0) {
      clearInterval(timer.current);
      startAnimation();
    }
  }, [value]);

  const startAnimation = () => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(animatedOpacityValue, {
          toValue: 0,
          duration: 500,
          useNativeDriver: true,
        }),
        Animated.timing(animatedOpacityValue, {
          toValue: 1,
          duration: 500,
          useNativeDriver: true,
        }),
      ]),
    ).start();
  };

  const opacity = useMemo(
    () =>
      animatedOpacityValue.interpolate({
        inputRange: [0, 1],
        outputRange: [0.5, 1],
      }),
    [],
  );

  return (
    <Container>
      <Animated.View style={{ opacity }}>
        <Typography fontSize={40} fontWeight={600}>
          {formatSecondsToMinutesAndSeconds(value)}
        </Typography>
      </Animated.View>
    </Container>
  );
};

export default React.memo(Timer);
