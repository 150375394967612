import React, { ReactElement } from 'react';

import * as styled from './styles';

export type TabValue = string | number;

export interface TabOption<T> {
  label: string;
  value: T;
}

interface Props<T extends TabValue> {
  active: T;
  options: TabOption<T>[];
  onChange: (tab: T) => void;
}

const TabBar = <T extends TabValue>({ active, options, onChange }: Props<T>): ReactElement => (
  <styled.Container>
    {options.map(({ value, label }) => {
      const isActive = active === value;
      return (
        <styled.Option key={value} $active={isActive} onPress={() => onChange(value)}>
          <styled.Label fontWeight={isActive ? 'medium' : 'regular'} $active={isActive}>
            {label}
          </styled.Label>
        </styled.Option>
      );
    })}
  </styled.Container>
);

export default React.memo(TabBar);
