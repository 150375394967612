import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  flex: 1;
  flex-direction: column;
`;

export const SectionHeader = styled(Typography)<{ $color: string }>`
  padding-top: ${spacings.m}px;
  padding-bottom: ${spacings.xxxs}px;
  color: ${colors.pidgeonGray};
  background-color: ${props => props.$color};
`;

export const Separator = styled(_Container)`
  margin-bottom: ${spacings.xxs}px;
`;
