import { Button as _Button, spacings } from '@hiven-energy/hiven-ui';
import { Platform, SafeAreaView } from 'react-native';
import styled from 'styled-components/native';

import * as theme from 'src/theme';

export const Wrapper = styled(SafeAreaView)`
  padding-top: ${Platform.OS === 'android' ? spacings.s : spacings.none}px;
`;

export const Button = styled(_Button)`
  border-radius: 0;
  background-color: ${theme.colors.fineOrange};
`;
