import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Touchable = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  border-radius: ${spacings.xxxs}px;
  padding: ${spacings.xxs}px;
  background-color: rgba(255, 255, 255, 0.1);
`;

export const IconWrapper = styled(_Container)`
  margin-right: ${spacings.xxs}px;
`;

export const Details = styled(_Container)`
  flex: 1;
`;

export const Name = styled(Typography)`
  color: ${colors.white};
`;

export const Options = styled(_Container)`
  flex-direction: row;
  align-items: center;
`;

export const Status = styled(_Container)`
  flex-direction: row;
  align-items: center;
`;

export const StatusDot = styled(_Container)<{ $color: string }>`
  height: ${spacings.xxxs}px;
  width: ${spacings.xxxs}px;
  background-color: ${props => props.$color};
  border-radius: ${spacings.xxxs}px;
`;

export const StatusName = styled(Typography)`
  margin-left: ${spacings.xxxs}px;
  color: ${colors.hintGrey};
`;

export const CheckIconWrapper = styled(_Container)`
  background-color: ${colors.sunshineYellow};
  border-radius: 12px;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin-left: ${spacings.xxs}px;
`;
