import { spacings } from '@hiven-energy/hiven-ui';
import { Platform } from 'react-native';
import { BottomSheet as _BottomSheet } from 'react-native-sheet';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const BottomSheet = styled(_BottomSheet).attrs(props => ({
  ...props,
  draggable: false,
  borderRadius: 24,
  contentContainerStyle: {
    backgroundColor: colors.white,
    paddingLeft: spacings.s,
    paddingRight: spacings.s,
    paddingTop: spacings.s,
  },
  sheetStyle: {
    backgroundColor: colors.white,
    ...Platform.select({
      web: {
        maxWidth: 768,
        transform: 'translate(-50%, 0)',
        left: '50%',
        marginLeft: 0,
        marginRight: 0,
        width: '100%',
      },
    }),
  },
}))``;
