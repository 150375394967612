import { Button, EmailIcon, Link, LockedIcon } from '@hiven-energy/hiven-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { StatusBar } from 'expo-status-bar';
import React, { FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';
import { z } from 'zod';

import { useA11y } from 'src/a11y';
import { TextField } from 'src/components/form-fields/TextField';
import TermsModal from 'src/components/TermsModal/TermsModal';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useSignUp } from 'src/queries/session';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';
import { CognitoAction } from 'src/services/session';
import * as validators from 'src/utils/validators';

import { getCognitoErrorMessageId } from '../utils';

import * as styled from './styles';
import { SignUpFormData } from './types';

type Props = ScreenProps<RouteId.SignUp>;

const signUpTitle = { id: 'SignUp.button' };

const SignUp: FC<Props> = ({ route, navigation }) => {
  const intl = useIntl();
  const a11y = useA11y();
  const toast = useToast();

  const schema = useMemo(
    () =>
      z.object({
        email: validators.email(intl),
        password: validators.password(intl, false),
      }),
    [intl],
  );

  const form = useForm<SignUpFormData>({
    mode: 'onBlur',
    defaultValues: {
      email: route.params?.email || '',
      password: '',
    },
    resolver: zodResolver(schema),
  });

  const [modalVisible, setModalVisible] = useState(false);

  useAnalyticsTimeEvent(MixpanelEvents.SIGNED_UP);
  const { trackSignUp, trackButtonClick } = useAnalytics();

  const signUpMutation = useSignUp({
    onSuccess: (...args) => {
      const [_, payload] = args;
      const { email } = payload;
      trackSignUp(signUpTitle.id, email);
      navigation.navigate(RouteId.ConfirmSignUp, { email });
    },
    onError: error => {
      const errorMessageId = getCognitoErrorMessageId(CognitoAction.SIGN_UP, error);
      toast.show(<FormattedMessage id={errorMessageId} />, { type: 'danger' });
    },
  });

  const handleSignUp = ({ email, password }: SignUpFormData) => {
    signUpMutation.mutate({ email, password });
  };

  const handleSignInPress = () => {
    trackButtonClick('SignUp.loginLink');
    navigation.navigate(RouteId.SignIn, { email: form.getValues().email });
  };

  const toggleModal = () => {
    trackButtonClick('SignUp.termsLink');
    setModalVisible(state => !state);
  };

  return (
    <styled.Container>
      <styled.Title variant="h3" accessibilityLabel={a11y.formatLabel('SignUp.title')}>
        <FormattedMessage id="SignUp.title" />
      </styled.Title>
      <TextField
        placeholder={intl.formatMessage({ id: 'common.email' })}
        control={form.control}
        name="email"
        leftIcon={EmailIcon}
      />
      <TextField
        placeholder={intl.formatMessage({ id: 'common.password' })}
        control={form.control}
        name="password"
        leftIcon={LockedIcon}
        password
      />
      <styled.Terms>
        <styled.TermsText accessibilityLabel={a11y.formatLabel('SignUp.termsText')}>
          <FormattedMessage id="SignUp.termsText" />
        </styled.TermsText>
        <Link onPress={toggleModal} accessibilityLabel={a11y.formatLabel('common.termsAndConditions')}>
          <FormattedMessage id="common.termsAndConditions" />
        </Link>
      </styled.Terms>
      <styled.Footer>
        <Button
          title={intl.formatMessage(signUpTitle)}
          loading={signUpMutation.isLoading}
          disabled={signUpMutation.isLoading || !form.formState.isValid}
          testID={a11y.formatLabel('SignUp.button')}
          onPress={form.handleSubmit(handleSignUp)}
        />
        <styled.SignInQuestion>
          <styled.SignInText accessibilityLabel={a11y.formatLabel('SignUp.loginQn')}>
            <FormattedMessage id="SignUp.signInQuestion" />
          </styled.SignInText>
          <Link onPress={handleSignInPress} accessibilityLabel={a11y.formatLabel('SignUp.loginLink')}>
            <FormattedMessage id="SignUp.signInLink" />
          </Link>
        </styled.SignInQuestion>
      </styled.Footer>
      {modalVisible && <TermsModal onClose={toggleModal} />}
      <StatusBar style="light" />
    </styled.Container>
  );
};

export default SignUp;
