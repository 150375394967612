import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import { colors } from 'src/theme';

export const Content = styled(_Container)`
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Container = styled(_Container)<{ $withLeftMargin?: boolean }>`
  flex-direction: column;
  gap: ${spacings.xxxs}px;
  margin-left: ${props => (props.$withLeftMargin ? '34px' : 0)};
`;

export const Label = styled(Typography)<{ $withIcon: boolean }>`
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin-left: ${props => (props.$withIcon ? '10px' : 0)};
  margin-right: 8px;
  color: ${colors.stoneGrey};
`;

export const LabelInLine = styled(Typography)`
  flex: 1;
  flex-wrap: wrap;
  margin-right: ${spacings.xxs}px;
  color: ${colors.stoneGrey};
`;

export const Value = styled(Typography)`
  color: ${colors.deepNavy};

  ${() =>
    Platform.OS === 'web' &&
    css`
      overflow-wrap: anywhere;
    `}
`;

export const LabelInRow = styled(Typography)`
  color: ${colors.stoneGrey};
`;

export const ValueInRow = styled(Typography)`
  font-size: 16px;
  color: ${colors.deepNavy};
`;
