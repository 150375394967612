import { NavigationContainer, Route } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import Loader from 'src/components/Loader/Loader';
import DemoAlert from 'src/containers/DemoAlert/DemoAlert';
import Navigator from 'src/nav/Navigator';
import * as RootNavigation from 'src/nav/RootNavigation';
import { RouteId } from 'src/nav/types';
import { useNotifications } from 'src/notifications';
import { Analytics } from 'src/services/analytics';
import { useSessionService } from 'src/services/session';
import { useSession } from 'src/store/session';

const LandingView: FC = () => {
  const session = useSession();
  const notifications = useNotifications();
  const sessionService = useSessionService();
  const previousRouteRef = useRef<Route<string> | null>();
  const [appInitialized, setAppInitialized] = useState(false);

  const initializeUserSession = async () => {
    const isAuthenticated = await sessionService.isUserAuthenticated();
    if (isAuthenticated) {
      const initializedUser = await session.initialize();
      if (initializedUser) {
        void Analytics.loggedIn(initializedUser.email);
      }
    }
  };

  useEffect(() => {
    initializeUserSession().then(() => {
      setAppInitialized(true);
    });
  }, []);

  useEffect(() => {
    if (!session.loggedIn) return;
    void notifications.registerToken();
  }, [session.loggedIn]);

  const onNavigationStateChange = useCallback(async () => {
    const previousRoute = previousRouteRef.current;
    const currentRoute = RootNavigation.navigationRef.current?.getCurrentRoute();
    if (currentRoute && previousRoute?.name !== currentRoute.name) {
      if (previousRoute) {
        Analytics.pageView(
          previousRoute.name,
          previousRoute.params && {
            ...previousRoute.params,
          },
        );
      }
      Analytics.pageViewStarted();
      previousRouteRef.current = currentRoute;
    }
  }, []);

  const onNavigationReady = useCallback(() => {
    previousRouteRef.current = RootNavigation.navigationRef.current?.getCurrentRoute();
  }, []);

  if (!appInitialized) {
    return <Loader fullScreen />;
  }

  return (
    <>
      <DemoAlert />
      <NavigationContainer
        ref={RootNavigation.navigationRef}
        linking={{
          prefixes: [Linking.createURL('/'), Linking.createURL(RouteId.SmartCarConnect)],
          enabled: true,
        }}
        onReady={onNavigationReady}
        onStateChange={onNavigationStateChange}
      >
        <Navigator />
      </NavigationContainer>
    </>
  );
};

export default React.memo(LandingView);
