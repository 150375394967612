import { Typography } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useA11y } from 'src/a11y';
import RecentUpdate from 'src/components/RecentUpdate/RecentUpdate';
import { useChargingSchedule, useSpotPrices } from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';
import { AnalyticPlace } from 'src/services/analytics/types';
import { getErrorReason, getQueriesStatus } from 'src/utils/queries';

import ScheduleChart from './ScheduleChart/ScheduleChart';
import * as styled from './styles';

const SCHEDULE_REFETCH_INTERVAL = 60 * 1000; // 1 minute

interface Props {
  deviceId: string;
  analyticsPlace: AnalyticPlace;
}

const PriceSchedule: FC<Props> = ({ deviceId, analyticsPlace }) => {
  const a11y = useA11y();

  const scheduleQuery = useChargingSchedule(deviceId, { refetchInterval: SCHEDULE_REFETCH_INTERVAL });
  const spotPriceAreaId = scheduleQuery?.data?.spotPriceAreaId;

  const { trackButtonClick } = useAnalytics();
  const pricesQuery = useSpotPrices(spotPriceAreaId!, {
    enabled: !!spotPriceAreaId,
  });

  const prices = pricesQuery.data;
  const schedule = scheduleQuery.isSuccess ? scheduleQuery.data : undefined;

  const trigger = () => {
    if (pricesQuery.isError) {
      pricesQuery.refetch();
    }
    scheduleQuery.refetch();
  };

  const handleRetryPress = () => {
    trackButtonClick('retry', analyticsPlace);
    trigger();
  };

  return (
    <styled.Container>
      <styled.Header>
        <Typography variant="h2">
          <FormattedMessage id="priceSchedule.title" />
        </Typography>
      </styled.Header>
      <RecentUpdate
        status={getQueriesStatus(scheduleQuery, pricesQuery)}
        errorReason={scheduleQuery.error ? getErrorReason(scheduleQuery.error) : undefined}
        onRetry={handleRetryPress}
        reloadIconTestID={a11y.formatLabel('priceSchedule.reloadIcon')}
        reloadIconAccessibilityLabel={a11y.formatLabel('priceSchedule.reloadIcon')}
      />
      {schedule && prices && Object.keys(prices).length > 0 ? (
        <ScheduleChart schedule={schedule} prices={prices} />
      ) : (
        <styled.Text>
          <FormattedMessage id="priceSchedule.noData.hint" />
        </styled.Text>
      )}
    </styled.Container>
  );
};

export default React.memo(PriceSchedule);
