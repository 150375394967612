import { spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Title = styled(Typography)`
  color: ${colors.white};
  margin-bottom: ${spacings.xxxs}px;
  text-align: center;
`;

export const Text = styled(Typography)`
  color: ${colors.white};
  text-align: center;
`;
