import { Button } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import { colors } from 'src/theme';

import * as styled from './styles';

interface Props {
  isAssociationAllowed: boolean;
  handleAssociationLinkPress?: VoidFunction;
  handleAddDevicePress: VoidFunction;
}

export const Actions: FC<Props> = ({ isAssociationAllowed, handleAssociationLinkPress, handleAddDevicePress }) => {
  const a11y = useA11y();
  const intl = useIntl();

  return (
    <styled.Container>
      {isAssociationAllowed && (
        <styled.Link
          onPress={handleAssociationLinkPress}
          styles={{
            title: {
              color: colors.smokeGrey,
            },
          }}
        >
          <FormattedMessage id="deviceList.addDevice.contentTitle" />
        </styled.Link>
      )}
      <Button
        type="secondary"
        title={intl.formatMessage({ id: 'common.addDevice' })}
        onPress={handleAddDevicePress}
        accessibilityLabel={a11y.formatLabel('common.addDevice')}
        testID={a11y.formatLabel('common.addDevice')}
        colors={{
          enabled: {
            titleColor: colors.smokeGrey,
          },
          pressed: {
            titleColor: colors.smokeGrey,
          },
        }}
      />
    </styled.Container>
  );
};
