import { ErrorIcon, Link } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { MixpanelEventProps } from 'src/services/analytics/types';
import { colors } from 'src/theme';

import * as styled from './styles';

interface Props {
  onTryAgain: VoidFunction;
  onCancel: VoidFunction;
  analytics: Pick<MixpanelEventProps<MixpanelEvents.VEHICLE_ADDED>, 'DeviceType' | 'Code'>;
}

const Error: FC<Props> = ({ analytics, onTryAgain, onCancel }) => {
  const a11y = useA11y();
  const intl = useIntl();

  const { trackRetryVehicleConnect, trackRetryVehicleConnectCancel } = useAnalytics();

  const handleTryAgainPress = () => {
    trackRetryVehicleConnect('common.tryAgain', analytics.DeviceType, analytics.Code);
    onTryAgain();
  };

  const handleCancelPress = () => {
    trackRetryVehicleConnectCancel('common.cancel', analytics.DeviceType, analytics.Code);
    onCancel();
  };

  return (
    <styled.Container>
      <ErrorIcon color={colors.raspberryPink} size={45} />
      <styled.Title variant="h1">
        <FormattedMessage id="SmartCarConnect.error" />
      </styled.Title>
      <styled.Button
        type="tertiary"
        title={intl.formatMessage({ id: 'common.tryAgain' })}
        onPress={handleTryAgainPress}
      />
      <Link
        onPress={handleCancelPress}
        accessibilityLabel={a11y.formatLabel('common.cancel')}
        styles={{ title: { color: colors.white } }}
      >
        <FormattedMessage id="common.cancel" />
      </Link>
    </styled.Container>
  );
};

export default Error;
