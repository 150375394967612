import { useIsFocused } from '@react-navigation/native';
import { QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { Platform } from 'react-native';

export const useFocusAwareQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> => {
  const isFocused = useIsFocused();
  const isWeb = Platform.OS === 'web';

  const focusAwareOptions = {
    ...options,
    enabled: (isFocused || isWeb) && options?.enabled,
    refetchInterval: (isFocused || isWeb) && options?.refetchInterval,
  };

  return useQuery(queryKey, queryFn, focusAwareOptions);
};
