import { ChargerChargingStatus, ChargerStatus } from '@hiven-energy/hiven-client';
import { Progress, ProgressLinearBarType } from '@hiven-energy/hiven-ui';
import React, { FC, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useAppTheme } from 'src/app-theme';
import { TogglePanel } from 'src/components/TogglePanel/TogglePanel';
import { DeviceList } from 'src/containers/DeviceList/DeviceList';
import { useScreenDimensions } from 'src/hooks/useScreenDimensions';
import { colors } from 'src/theme';
import { convertEnergyUnits, EnergyUnit, formatEnergy } from 'src/utils/units';

import {
  chargingStatusToIcon,
  chargingStatusToMessage,
  chargingStatusToProgressVariant,
  chargingStatusToStateOfChargeColor,
} from '../../constants';
import { Preferences } from '../../types';

import * as styled from './styles';

const ENERGY_UNIT = EnergyUnit.KWH;

type ProgressBarColors = Partial<Record<ProgressLinearBarType, string>>;

interface Props {
  name: string | undefined;
  preferences: Preferences;
  deviceId: string | undefined;
  connectionPending: boolean;
  preferencesSet: boolean | undefined;
  status: ChargerStatus | undefined;
  showDeviceList: boolean;
  onToggleDeviceList: VoidFunction;
}

const Status: FC<Props> = ({
  name,
  preferences,
  preferencesSet,
  deviceId,
  connectionPending,
  status,
  showDeviceList,
  onToggleDeviceList,
}) => {
  const insets = useSafeAreaInsets();
  const [_, screenHeight] = useScreenDimensions();

  const appTheme = useAppTheme();

  const { chargeLevels } = preferences;
  const { measurement, status: chargingStatus = ChargerChargingStatus.UNAVAILABLE, heartbeatTimestamp } = status ?? {};
  const [showContent, setShowContent] = useState(false);

  const socColor = chargingStatusToStateOfChargeColor[chargingStatus];
  const progressVariant = chargingStatusToProgressVariant[chargingStatus];
  const statusMessage = chargingStatusToMessage[chargingStatus];
  const StatusIcon = chargingStatusToIcon[chargingStatus];

  const progressBarColors = useMemo<ProgressBarColors>(
    () => ({
      [ProgressLinearBarType.VALUE]:
        chargingStatus === ChargerChargingStatus.UNAVAILABLE ? colors.pidgeonGray : undefined,
    }),
    [chargingStatus],
  );

  const energyTransfer = measurement?.value && convertEnergyUnits(measurement.value, EnergyUnit.WH, ENERGY_UNIT);
  const title = name || '-';

  return (
    <styled.Container maxHeight={screenHeight}>
      <styled.StateContent insets={insets} backgroundColor={appTheme.main.color}>
        <styled.Label>
          <FormattedMessage id="ChargerDashboard.status.label" />
        </styled.Label>
        <TogglePanel
          timestamp={heartbeatTimestamp}
          isActive={!!deviceId}
          title={title}
          toggleContent={deviceId && <DeviceList deviceId={deviceId} onPress={onToggleDeviceList} />}
          accessibilityLabel={title}
          analyticsPlace="ChargerDashboard.status"
          open={showDeviceList}
          onToggle={onToggleDeviceList}
          onStateChange={setShowContent}
        />
        {showContent && (
          <styled.Content>
            {connectionPending ? (
              <styled.Status>
                <FormattedMessage id="ChargerDashboard.status.connectionPending" />
              </styled.Status>
            ) : status ? (
              <>
                {energyTransfer !== undefined && (
                  <styled.StateOfCharge fontSize={50} fontWeight="bold" color={socColor}>
                    {formatEnergy(energyTransfer, ENERGY_UNIT)}
                  </styled.StateOfCharge>
                )}
                <styled.StatusRow>
                  <styled.IconLabelWrapper>
                    <StatusIcon size={20} color={colors.white} />
                    <styled.StatusLabel lineHeight="tight">
                      <FormattedMessage {...statusMessage} />
                    </styled.StatusLabel>
                  </styled.IconLabelWrapper>
                  {preferencesSet && (
                    <styled.TargetLabel fontSize={14} lineHeight="tight">
                      <FormattedMessage
                        id="ChargerDashboard.status.stopAt"
                        values={{ value: chargeLevels.maximum, unit: ENERGY_UNIT }}
                      />
                    </styled.TargetLabel>
                  )}
                </styled.StatusRow>
                <Progress.Linear
                  variant={progressVariant}
                  value={energyTransfer}
                  maxValue={chargeLevels.maximum}
                  barColors={progressBarColors}
                />
              </>
            ) : (
              <styled.Status>
                <FormattedMessage id="ChargerDashboard.status.offline" />
              </styled.Status>
            )}
          </styled.Content>
        )}
      </styled.StateContent>
      <styled.RoundedBottom backgroundColor={appTheme.main.color} />
    </styled.Container>
  );
};

export default React.memo(Status);
