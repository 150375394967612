import { WEB_GOOGLE_MAPS_API_KEY } from '@env';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import pin from 'src/components/Icon/paths/pin';
import { colors } from 'src/theme';

import * as styled from './styles';
import { Props } from './types';
import { convertLatLngToRegion, convertRegionToLatLng } from './utils.web';

const Map: FC<Props> = ({ region, initialZoom, interactive = true, showMarker = true, onRegionChange }) => {
  const mapRef = useRef<google.maps.Map>();
  const [markerRegion, setMarkerRegion] = useState(region);
  const loadScript = useLoadScript({ googleMapsApiKey: WEB_GOOGLE_MAPS_API_KEY });

  useEffect(() => {
    setMarkerRegion(region);
  }, [region]);

  const handleLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  const getCenter = useCallback(() => {
    const center = mapRef.current?.getCenter();
    return center && convertLatLngToRegion(center);
  }, [mapRef?.current]);

  const handleDrag = useCallback(() => {
    const center = getCenter();
    if (center) setMarkerRegion(center);
  }, [getCenter]);

  const handleDragEnd = useCallback(() => {
    const center = getCenter();
    if (center) onRegionChange?.(center);
  }, [getCenter]);

  const center = useMemo(() => convertRegionToLatLng(region), [region]);

  const markerPosition = useMemo(() => convertRegionToLatLng(markerRegion), [markerRegion]);

  const mapContainerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
  };

  const markerIcon: google.maps.Symbol = { path: pin, fillColor: colors.deepNavy, fillOpacity: 1 };

  if (!loadScript.isLoaded) return null;

  return (
    <styled.Container interactive={interactive}>
      <GoogleMap
        center={center}
        zoom={initialZoom}
        onLoad={handleLoad}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        mapContainerStyle={mapContainerStyle}
      >
        {showMarker && <Marker position={markerPosition} icon={markerIcon} />}
      </GoogleMap>
    </styled.Container>
  );
};

export default React.memo(Map);
