import { TariffInfo } from '@hiven-energy/hiven-client';
import { SelectOption } from '@hiven-energy/hiven-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { z } from 'zod';

import { useA11y } from 'src/a11y';
import { SelectField } from 'src/components/form-fields/SelectField/SelectField';
import { TextField } from 'src/components/form-fields/TextField';
import Loader from 'src/components/Loader/Loader';
import { useElectricalUtilityProviders } from 'src/queries/sdk';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';

import * as styled from './styles';

interface Props {
  tariffInformation: TariffInfo | undefined;
  loading?: boolean;
  saving?: boolean;
  saveTitle: string;
  onSave: (tariffInfo: TariffInfo) => void;
}

const emptyTariffInfoForm: TariffInfo = {};

export const TariffInformation: FC<Props> = ({
  tariffInformation,
  loading = false,
  saving = false,
  saveTitle,
  onSave,
}) => {
  useAnalyticsTimeEvent(MixpanelEvents.USER_DATA_SAVED);

  const { data: providers, isLoading: isLoadingProviders } = useElectricalUtilityProviders({
    select: providers =>
      providers.map(name => ({
        value: name,
        label: name,
      })),
  });

  if (loading || isLoadingProviders) {
    return <Loader fullScreen />;
  }

  return (
    <styled.Container>
      <TariffInfoForm
        saving={saving}
        tariffInfo={tariffInformation}
        providerOptions={providers ?? []}
        saveTitle={saveTitle}
        onSave={onSave}
      />
    </styled.Container>
  );
};

interface TariffInfoFormProps {
  tariffInfo: TariffInfo | undefined;
  saving: boolean;
  providerOptions: SelectOption<string | null>[];
  saveTitle: string;
  onSave: (tariffInfo: TariffInfo) => void;
}

const TariffInfoForm: FC<TariffInfoFormProps> = ({ tariffInfo, onSave, saving, providerOptions, saveTitle }) => {
  const intl = useIntl();
  const a11y = useA11y();

  const defaultValues = useMemo(
    () => ({
      ...emptyTariffInfoForm,
      ...tariffInfo,
    }),
    [tariffInfo],
  );

  const schema = useMemo(
    () =>
      z.object({
        electricalUtilityProvider: z.string({
          required_error: intl.formatMessage({ id: 'validation.required' }),
          invalid_type_error: intl.formatMessage({ id: 'validation.required' }),
        }),
        meterId: z
          .string({
            required_error: intl.formatMessage({ id: 'validation.required' }),
          })
          .trim()
          .max(128)
          .min(1),
        tariff: z
          .string({
            required_error: intl.formatMessage({ id: 'validation.required' }),
          })
          .trim()
          .max(128)
          .min(1),
      }),
    [intl],
  );

  const form = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [defaultValues]);

  return (
    <>
      <styled.FormContent>
        <styled.Title fontWeight="medium">{intl.formatMessage({ id: 'SetTariffAndMeter.title' })}</styled.Title>
        <styled.FormGroup>
          <TextField<TariffInfo>
            control={form.control}
            placeholder={intl.formatMessage({ id: 'SetTariffAndMeter.meterId.label' })}
            name="meterId"
          />
          <TextField<TariffInfo>
            control={form.control}
            placeholder={intl.formatMessage({ id: 'SetTariffAndMeter.tariff.label' })}
            name="tariff"
          />
          <SelectField<TariffInfo>
            control={form.control}
            options={providerOptions}
            name="electricalUtilityProvider"
            placeholder={intl.formatMessage({ id: 'SetTariffAndMeter.electricalUtilityProvider.label' })}
            accessibilityLabel={intl.formatMessage({ id: 'SetTariffAndMeter.electricalUtilityProvider.label' })}
          />
          {/*<DemandResponse enabled={true} onToggle={() => {}}/>*/}
        </styled.FormGroup>
      </styled.FormContent>
      <styled.SaveButton
        title={saveTitle}
        loading={saving}
        disabled={saving || !form.formState.isValid}
        testID={a11y.formatLabel('common.save')}
        onPress={form.handleSubmit(onSave)}
      />
    </>
  );
};

export default TariffInformation;
