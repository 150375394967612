import { Theme as UiTheme } from '@hiven-energy/hiven-ui';

import { colors } from './colors';

const commonUiTheme = {} as UiTheme;

export const hivenUiTheme = {
  ...commonUiTheme,
} as UiTheme;

export const uniperSeTheme = {
  ...commonUiTheme,
} as UiTheme;

export const wallboxUiTheme = {
  ...commonUiTheme,
  button: {
    primary: {
      container: {
        backgroundColor: {
          enabled: colors.persianGreen,
          pressed: colors.persianGreen,
        },
      },
      title: {
        color: {
          pressed: colors.white,
        },
      },
    },
  },
  spinner: {
    color: colors.persianGreen,
  },
} as UiTheme;
