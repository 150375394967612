import { DeviceType } from '@hiven-energy/hiven-client';
import React, { FC } from 'react';
import { Animated, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import ChargerImage from 'src/assets/images/charger.svg';
import VehicleImage from 'src/assets/images/vehicle.svg';

const commonStyle: ViewStyle = {
  position: 'absolute',
  right: -8,
};

const deviceTypeToStyle: Record<DeviceType, ViewStyle> = {
  [DeviceType.CHARGER]: { top: -40 },
  [DeviceType.VEHICLE]: { top: -16 },
};

const deviceTypeToImage: Record<DeviceType, FC<SvgProps>> = {
  [DeviceType.CHARGER]: ChargerImage,
  [DeviceType.VEHICLE]: VehicleImage,
};

interface Props {
  deviceType: DeviceType;
  scroll: Animated.Value;
}

const DeviceImage: FC<Props> = ({ deviceType, scroll }) => {
  const Image = deviceTypeToImage[deviceType];
  const style = deviceTypeToStyle[deviceType];

  const translateY = Animated.multiply(scroll, new Animated.Value(-1));

  return (
    <Animated.View style={[style, commonStyle, { transform: [{ translateY }] }]}>
      <Image />
    </Animated.View>
  );
};

export default React.memo(DeviceImage);
