import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import * as theme from 'src/theme';

export const Container = styled(_Container)`
  align-items: center;
`;

export const Header = styled(Typography)`
  margin-bottom: 10px;
`;

export const Content = styled(_Container)`
  flex-direction: row;
`;

export const ContentBox = styled(_Container)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Heartbeat = styled(_Container)`
  align-items: center;
`;

export const Separator = styled(_Container)`
  width: 2px;
  height: 100%;
  margin: 0 ${spacings.xxs}px;
  background-color: ${theme.colors.lunarGrey};
`;
