import { APP_BACKEND_URL, USE_MOCK_API } from '@env';
import { Client, createClient } from '@hiven-energy/hiven-client';

import * as sessionService from 'src/services/session/service';

const HIVEN_API_URL = [APP_BACKEND_URL, 'hiven'].join('/');

export const createBaseClient: () => Client = () =>
  createClient({
    apiUrl: HIVEN_API_URL,
    headersGetter: async () => ({
      Authorization: await sessionService.getIdJwt(),
      'X-Use-Mock-API': USE_MOCK_API,
    }),
  });
