import { ChargerPreferences, Time, VehiclePreferences } from '@hiven-energy/hiven-client';

import { ReadyTimeByType } from 'src/containers/ready-time/types';
import { WeekDay } from 'src/hooks/useWeekDays';

export const defaultReadyTime: Time = '06:00';

type Preferences = VehiclePreferences | ChargerPreferences;

export type DaysOfWeekSpecificReadyTime = Preferences['weekDaySpecificReadyTimes'];

export const createReadyTimeByType = (preferences: Preferences): ReadyTimeByType | undefined => {
  if (isDaysOfWeekSpecificReadyTime(preferences.weekDaySpecificReadyTimes)) {
    return {
      type: 'days_of_week',
      time: preferences.weekDaySpecificReadyTimes,
    };
  }

  if (preferences.readyTime) {
    return { type: 'all_day', time: preferences.readyTime };
  }

  return undefined;
};

export const isDaysOfWeekSpecificReadyTime = (
  readyTime: DaysOfWeekSpecificReadyTime,
): readyTime is Record<WeekDay, Time> => {
  return !!readyTime?.MONDAY;
};
