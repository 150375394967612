import dayjs from 'dayjs';
import React, { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { getSystemLanguage, Language } from 'src/i18n';
import { Analytics } from 'src/services/analytics';
import { useSessionService } from 'src/services/session';
import batchedUpdates from 'src/utils/batchedUpdates';

export interface SessionState {
  language: Language;
  loggedIn: boolean;
  userId: string;
  email: string;
  resetState: VoidFunction;
  initialize: () => Promise<{ userId: string; email: string } | null>;
  getAuthorizationToken: () => Promise<string>;
  setLanguage: (language: Language) => void;
}

const initialSession: SessionState = {
  language: getSystemLanguage(),
  loggedIn: false,
  userId: '',
  email: '',
  initialize: async () => null,
  resetState: () => undefined,
  getAuthorizationToken: async () => '',
  setLanguage: () => undefined,
};

export const SessionContext = React.createContext<SessionState>(initialSession);

export const SessionProvider: FC<PropsWithChildren> = ({ children }) => (
  <SessionContext.Provider value={useSessionProvider()}>{children}</SessionContext.Provider>
);

const useSessionProvider = (): SessionState => {
  const sessionService = useSessionService();

  const [language, setLanguage] = useState(getSystemLanguage());
  const [loggedIn, setLoggedIn] = useState(false);
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    dayjs.locale(language);
  }, [language]);

  const resetState = () => {
    setLoggedIn(false);
    setUserId('');
    setEmail('');
  };

  const initialize = () =>
    Promise.all([sessionService.getUserId(), sessionService.getEmail(), sessionService.getLanguage()])
      .then(([userId, email, language]) => {
        batchedUpdates(() => {
          setLoggedIn(true);
          setUserId(userId);
          setEmail(email);
          setLanguage(language);
        });
        Analytics.setUser(userId, email);
        return { userId, email };
      })
      .catch(() => {
        setLoggedIn(false);
        return null;
      });

  const getAuthorizationToken = () =>
    sessionService.getIdJwt().catch(error => {
      setLoggedIn(false);
      throw error;
    });

  return {
    language,
    loggedIn,
    userId,
    email,
    initialize,
    resetState,
    getAuthorizationToken,
    setLanguage,
  };
};

export const useSession = () => useContext(SessionContext) as SessionState;
