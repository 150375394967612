import random from 'lodash/random';

export const delayedResponse = <T>(value: T): Promise<T> =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(value);
    }, random(500, 1500));
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const log = (msg: string, ...optionalParams: any[]) => console.log(`[DEMO]: ${msg}`, ...optionalParams);
