import { UserLocation } from '@hiven-energy/hiven-client';
import { EditIcon, IconButton, PlusIcon } from '@hiven-energy/hiven-ui';
import { useNavigation } from '@react-navigation/native';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import { RouteId } from 'src/nav/types';

import * as styled from '../styles';

interface Props {
  locationId: string;
  tariffInfo: UserLocation['tariffInfo'];
}

export const TariffAndMeterDetails: FC<Props> = ({ tariffInfo, locationId }) => {
  const intl = useIntl();
  const a11y = useA11y();
  const navigation = useNavigation();

  const handlePress = () => navigation.navigate(RouteId.SetTariffAndMeter, { locationId });

  return (
    <styled.Section $last>
      <styled.SectionTitle fontWeight="semiBold">
        {intl.formatMessage({ id: 'ChargingLocation.tariffAndMeter.title' })}
      </styled.SectionTitle>
      {!tariffInfo && (
        <>
          <styled.TariffInfoText>
            {intl.formatMessage({ id: 'ChargingLocation.tariffAndMeter.text' })}
          </styled.TariffInfoText>

          <IconButton
            icon={PlusIcon}
            accessibilityLabel={intl.formatMessage({ id: 'common.add' })}
            testID={a11y.formatLabel('common.add')}
            type="plain"
            onPress={handlePress}
          />
        </>
      )}
      {tariffInfo && (
        <styled.SectionItem>
          <styled.SectionItemGroup>
            <styled.SectionItemHeader>
              <styled.SectionItemTitle variant="h4">{tariffInfo.meterId}</styled.SectionItemTitle>
              <IconButton
                icon={EditIcon}
                type="plain"
                accessibilityLabel={intl.formatMessage({ id: 'common.edit' })}
                testID={a11y.formatLabel('common.edit')}
                onPress={handlePress}
              />
            </styled.SectionItemHeader>
            <styled.SectionItemText>
              {intl.formatMessage({ id: 'ChargingLocation.tariffAndMeter.meterId.text' })}
            </styled.SectionItemText>
          </styled.SectionItemGroup>
          <styled.SectionItemGroup>
            <styled.SectionItemHeader>
              <styled.SectionItemTitle variant="h4">{tariffInfo.tariff}</styled.SectionItemTitle>
            </styled.SectionItemHeader>
            <styled.SectionItemText>
              {intl.formatMessage({ id: 'ChargingLocation.tariffAndMeter.tariffType.text' })}
            </styled.SectionItemText>
          </styled.SectionItemGroup>
          <styled.SectionItemHeader>
            <styled.SectionItemTitle variant="h4">{tariffInfo.electricalUtilityProvider}</styled.SectionItemTitle>
          </styled.SectionItemHeader>
          <styled.SectionItemText>
            {intl.formatMessage({ id: 'ChargingLocation.tariffAndMeter.electricalUtilityProvider.text' })}
          </styled.SectionItemText>
        </styled.SectionItem>
      )}
    </styled.Section>
  );
};
