import { Time } from '@hiven-energy/hiven-client';
import { ClockIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { parseReadyTime } from 'src/containers/ready-time/Overview/utils';
import { DaysOfWeekSpecificReadyTime, isDaysOfWeekSpecificReadyTime } from 'src/containers/ready-time/utils';
import { useWeekDays } from 'src/hooks/useWeekDays';
import { formatTime } from 'src/utils/time';

import Preference from '../Preference/Preference';
import Property from '../Property/Property';

interface Props {
  readyTime?: Time;
  daysOfWeekSpecificReadyTime?: DaysOfWeekSpecificReadyTime;
  onEditPress: VoidFunction;
}

const ReadyTime: FC<Props> = ({ readyTime, daysOfWeekSpecificReadyTime, onEditPress }) => {
  const intl = useIntl();
  const weekDays = useWeekDays();

  return (
    <Preference
      title={intl.formatMessage({ id: 'common.deviceOverview.readyTime.title' })}
      icon={ClockIcon}
      onEditPress={onEditPress}
    >
      {isDaysOfWeekSpecificReadyTime(daysOfWeekSpecificReadyTime) ? (
        parseReadyTime(weekDays, daysOfWeekSpecificReadyTime).map(([time, range]) => (
          <Property key={range} name={range} value={formatTime(time)} />
        ))
      ) : (
        <Property
          name={intl.formatMessage({ id: 'common.deviceOverview.readyTime.allWeek' })}
          value={readyTime && formatTime(readyTime)}
        />
      )}
    </Preference>
  );
};

export default React.memo(ReadyTime);
