import {
  Container as _Container,
  spacings,
  Toggle,
  TouchableOpacityContainer,
  Typography,
} from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Description = styled(Typography)`
  font-size: 18px;
  color: ${colors.deepNavy};
  margin-bottom: ${spacings.s}px;
`;

export const AllDaySwitchContainer = styled(_Container)`
  margin-bottom: ${spacings.m}px;
  flex-direction: row;
  align-items: center;
  gap: ${spacings.xxxs}px;
`;

export const DaysContainer = styled(_Container)`
  gap: ${spacings.xxs}px;
`;

export const DayText = styled(Typography)`
  font-size: 18px;
`;

export const TimePickerContainer = styled(_Container)`
  align-items: center;
  justify-content: center;
  flex-grow: 0.7;
`;

export const DayItemContainer = styled(_Container)`
  border-width: 1px;
  border-radius: 12px;
  border-style: solid;
  border-color: ${colors.pidgeonGray};
`;

export const DayItem = styled(TouchableOpacityContainer)`
  padding: ${spacings.xxs}px;
`;

export const AllDayToggle = styled(Toggle).attrs(() => ({
  justifyContent: 'space-between',
  flexGrow: 1,
  styles: { label: { fontSize: 18, fontFamily: 'Outfit-Medium' } },
}))``;

export const DayTextLine = styled(_Container)`
  flex-direction: row;
  justify-content: space-between;
`;

export const ContinueButtonContainer = styled(_Container)`
  flex-grow: 1;
  justify-content: flex-end;
  padding: ${spacings.s}px;
`;

export const SetTimeButtonContainer = styled(_Container)`
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: ${spacings.s}px;
  padding-top: ${spacings.s}px;
`;

export const OverlayDescriptionText = styled(Typography)`
  font-size: 18px;
  margin-bottom: ${spacings.xs}px;
`;
