import { ChargerChargingBehavior } from '@hiven-energy/hiven-client';
import { FlashIcon, Typography } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PreferenceOverview from 'src/components/PreferenceOverview/PreferenceOverview';

interface Props {
  value: ChargerChargingBehavior;
  onEditPress: VoidFunction;
}

const ChargingBehavior: FC<Props> = ({ value, onEditPress }) => {
  const intl = useIntl();

  return (
    <PreferenceOverview
      title={intl.formatMessage({ id: 'ChargerPreferences.chargingBehavior.overview.title' })}
      icon={FlashIcon}
      onEditPress={onEditPress}
      analyticsPlace="ChargerPreferences.chargingBehavior"
    >
      <Typography>
        {value === ChargerChargingBehavior.SMART_CHARGE_ON ? (
          <FormattedMessage id="charger.chargingBehavior.SMART_CHARGE_ON" />
        ) : (
          <FormattedMessage id="charger.chargingBehavior.SMART_CHARGE_OFF" />
        )}
      </Typography>
    </PreferenceOverview>
  );
};

export default React.memo(ChargingBehavior);
