import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { usePrevious } from 'src/hooks/usePrevious';
import * as storage from 'src/storage/mode';

import { Mode } from './types';

type ModeState = [Mode, (mode: Mode) => void];

const ModeContext = createContext<ModeState>([Mode.LIVE, () => undefined]);

export const ModeProvider: FC<PropsWithChildren> = ({ children }) => (
  <ModeContext.Provider value={useModeProvider()}>{children}</ModeContext.Provider>
);

const useModeProvider = (): ModeState => {
  const [mode, change] = useState(Mode.LIVE);
  const previousMode = usePrevious(mode);

  useEffect(() => {
    const loadInitialMode = async () => {
      const initialMode = await storage.getMode();
      initialMode && change(initialMode);
    };
    loadInitialMode();
  }, []);

  useEffect(() => {
    if (mode === previousMode) return;
    const updateMode = () => storage.setMode(mode);
    updateMode();
  }, [mode, previousMode]);

  return [mode, change];
};

export const useMode = () => useContext(ModeContext);
