import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  align-items: center;
  padding: ${spacings.xxs}px;
  border-width: 1px;
  border-radius: 1px;
  border-style: dashed;
  border-color: ${colors.pidgeonGray};
`;

export const Hint = styled(Typography)`
  margin-top: ${spacings.xxxs}px;
`;
