import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect } from 'react';

import Header from 'src/nav/components/Header/Header';
import { RouteHeaderAction, RouteId, RoutesParamList } from 'src/nav/types';

export const useHeaderTitle = (title: string, onGoBackPress: VoidFunction) => {
  const route = useRoute() as RouteProp<RoutesParamList, RouteId.VehiclePreferences>;
  const navigation = useNavigation();

  useEffect(() => {
    navigation.setOptions({
      title,
      header: () =>
        React.createElement(Header, {
          title,
          action: RouteHeaderAction.GO_BACK,
          onGoBackPress,
          route,
        }),
    });
  }, [title, onGoBackPress]);
};
