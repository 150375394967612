import { Container as _Container, spacings, TouchableOpacityContainer, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

export const Container = styled(BaseView)`
  padding: ${spacings.xs}px;
`;

export const Manufacturer = styled(TouchableOpacityContainer)<{ $isLast: boolean }>`
  border: 1px solid ${colors.pidgeonGray};
  border-radius: 12px;
  margin-bottom: ${props => (props.$isLast ? spacings.none : spacings.xxs)}px;
  height: 80px;
  flex-direction: row;
`;

export const Content = styled(_Container)`
  align-items: center;
  flex-direction: row;
  padding: ${spacings.xs}px;
  flex: auto;
`;

export const Name = styled(Typography)`
  margin-left: ${spacings.xs}px;
  flex: auto;
`;

export const Action = styled(_Container)`
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
`;
