import { ClockIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { LabelValue, LabelValueLine } from 'src/components/LabelValue/LabelValue';
import { parseReadyTime } from 'src/containers/ready-time/Overview/utils';
import { ReadyTimeByType } from 'src/containers/ready-time/types';
import { useWeekDays } from 'src/hooks/useWeekDays';
import { formatTime } from 'src/utils/time';

interface Props {
  readyTime: ReadyTimeByType;
}

const ReadyTime: FC<Props> = ({ readyTime }) => {
  const intl = useIntl();
  const weekDays = useWeekDays();

  return (
    <LabelValue icon={ClockIcon} label={intl.formatMessage({ id: 'common.preferences.readyTime.label' })}>
      {readyTime.type === 'all_day' ? (
        <LabelValueLine
          label={intl.formatMessage({ id: 'common.preferences.readyTime.allWeek.label' })}
          value={formatTime(readyTime.time)}
        />
      ) : (
        parseReadyTime(weekDays, readyTime.time).map(([time, range]) => (
          <LabelValueLine label={range} value={formatTime(time)} key={time} />
        ))
      )}
    </LabelValue>
  );
};

export default ReadyTime;
