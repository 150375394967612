import { DeviceType } from '@hiven-energy/hiven-client';
import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

const deviceTypeToDeviceNameMargin: Record<DeviceType, number> = {
  [DeviceType.CHARGER]: 50,
  [DeviceType.VEHICLE]: 110,
};

export const Container = _Container;

export const QueryStatusContainer = styled(BaseView)`
  align-items: center;
  justify-content: center;
`;

export const StatusBadgeWrapper = styled(_Container)`
  flex-wrap: wrap;
`;

export const StatusBadge = styled(_Container)<{ $offline: boolean }>`
  padding: ${spacings.xxxs}px;
  flex-direction: row;
  align-items: center;
  gap: ${spacings.xxxs}px;
  border-radius: 18px;
  background-color: ${p => (p.$offline ? '#EFD6DC' : '#1AE9E31A')};
  ${() =>
    Platform.OS === 'web' &&
    css`
      max-width: fit-content;
    `}
`;

export const StatusIndicator = styled(_Container)<{ $offline: boolean }>`
  width: ${spacings.xxxs}px;
  height: ${spacings.xxxs}px;
  border-radius: ${spacings.xxxs / 2}px;
  background-color: ${p => (p.$offline ? colors.raspberryPink : colors.aqua)};
`;

export const StatusLabel = styled(Typography)`
  font-size: 10px;
  line-height: 10px;
  color: ${colors.deepNavy};
`;

export const DeviceName = styled(Typography).attrs({ variant: 'h1' })<{ $deviceType: DeviceType }>`
  margin-top: ${spacings.xxxs}px;
  margin-right: ${p => deviceTypeToDeviceNameMargin[p.$deviceType]}px;
  min-width: 0;
`;

export const Heartbeat = styled(Typography).attrs({ variant: 'hint' })``;
