import { Container as _Container, Button, IconButton, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled, { css } from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { ScreenScrollView } from 'src/components/ScreenScrollView/ScreenScrollView';
import { colors } from 'src/theme';

export const Container = styled(BaseView)``;

export const FormContent = styled(ScreenScrollView).attrs({ contentContainerStyle: { paddingHorizontal: spacings.s } })`
  padding: 0;
`;

export const Section = styled(_Container)<{ $last?: boolean }>`
  padding-bottom: ${spacings.xs}px;
  ${({ $last }) =>
    !$last &&
    css`
      border-bottom-color: ${colors.disabledGrey};
      border-bottom-width: 1px;
      margin-bottom: ${spacings.xs}px;
    `}
`;

export const SectionTitle = styled(Typography)`
  font-size: 20px;
  line-height: 30px;
  margin-bottom: ${spacings.xs}px;
  flex-grow: 1;
  flex-wrap: wrap;
`;

export const SectionItem = styled(_Container)`
  border-color: ${colors.disabledGrey};
  border-width: 1px;
  border-radius: 12px;
  padding: ${spacings.xxs}px;
`;

export const SectionItemTitle = styled(Typography)`
  flex: 1;
`;

export const SectionItemText = styled(Typography)`
  color: ${colors.stoneGrey};
`;

export const SectionItemHeader = styled(_Container)`
  flex-direction: row;
`;

export const SectionItemGroup = styled(_Container)`
  padding-bottom: ${spacings.xxs}px;
`;

export const EditButton = styled(IconButton)`
  height: 24px;
`;

export const SaveButton = styled(Button)`
  margin: ${spacings.s}px;
`;

export const DeviceSheetTitle = styled(Typography)`
  font-size: 18px;
`;

export const LocationDeleteDisabledText = styled(Typography)`
  color: ${colors.stoneGrey};
  margin-top: ${spacings.xs}px;
  flex-wrap: wrap;
`;

export const DeviceSheetButtonsGroup = styled(_Container)`
  gap: ${spacings.xxs}px;
  padding-top: ${spacings.xs}px;
`;

export const DeleteButton = styled(Button)`
  margin-top: ${spacings.xs}px;
`;

export const TariffInfoText = styled(Typography)`
  color: ${colors.stoneGrey};
  margin-bottom: ${spacings.xxs}px;
`;

export const DevicesContainer = styled(_Container)`
  gap: ${spacings.xxs}px;
`;
