import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const ButtonContainer = styled(_Container)`
  margin: 0 ${spacings.xs}px ${spacings.xs}px ${spacings.xs}px;
`;

export const ButtonGap = styled(_Container)`
  width: 100%;
  height: ${spacings.xxs}px;
`;

export const Title = styled(Typography)`
  margin-bottom: ${spacings.m}px;
`;
