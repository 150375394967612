import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  padding: ${spacings.xxs}px;
  border-width: 1px;
  border-radius: 12px;
  border-color: ${colors.pidgeonGray};
`;

export const Title = styled(Typography)`
  color: ${colors.deepNavy};
`;
