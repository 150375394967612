import { spacings, TouchableContainer, Typography } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Platform, ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useAppTheme } from 'src/app-theme';

import Address from './Address/Address';
import * as styled from './styles';

interface Props {
  onClose: VoidFunction;
}

const TermsModal: FC<Props> = ({ onClose }) => {
  const appTheme = useAppTheme();
  const intl = useIntl();
  const insets = useSafeAreaInsets();

  const isWeb = Platform.OS === 'web';

  return (
    <styled.Modal
      visible
      onClose={onClose}
      title={intl.formatMessage({ id: 'termsModal.title' })}
      isWeb={isWeb}
      insetTop={insets.top}
      overlayBackgroundColor={appTheme.main.color}
    >
      <ScrollView
        contentContainerStyle={{
          paddingRight: spacings.xxs,
          paddingBottom: isWeb ? 0 : insets.bottom + 30,
        }}
      >
        <TouchableContainer>
          <styled.Content>
            <Typography>
              <FormattedMessage
                id="termsModal.content.line1"
                values={{
                  legalNoticeLink: (
                    <styled.Link href="https://www.fortum.com/legal/privacy/privacy-notice-customers">
                      <FormattedMessage id="common.here" />
                    </styled.Link>
                  ),
                  allLegalNoticesLink: (
                    <styled.Link href="https://www.fortum.com/legal/privacy">
                      <FormattedMessage id="common.here" />
                    </styled.Link>
                  ),
                }}
              />
            </Typography>
            <Typography>
              <FormattedMessage id="termsModal.content.line2" />
            </Typography>
          </styled.Content>
          <styled.Addresses>
            <Address
              title="termsModal.addresses.fortum.title"
              lines={[
                'termsModal.addresses.fortum.line1',
                'termsModal.addresses.fortum.line2',
                'termsModal.addresses.fortum.line3',
                'termsModal.addresses.fortum.line4',
              ]}
            />
            <Address
              title="termsModal.addresses.hiven.title"
              lines={[
                'termsModal.addresses.hiven.line1',
                'termsModal.addresses.hiven.line2',
                'termsModal.addresses.hiven.line3',
                'termsModal.addresses.hiven.line4',
              ]}
            />
          </styled.Addresses>
        </TouchableContainer>
      </ScrollView>
    </styled.Modal>
  );
};

export default TermsModal;
