import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  padding: ${spacings.xxs}px;
  align-items: center;
  border-radius: ${spacings.xxxs}px;
  background-color: ${colors.sunshineYellow};
`;

export const CheckWrapper = styled(_Container)`
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: ${colors.deepNavy};
`;

export const Label = styled(Typography)`
  margin-top: ${spacings.xxxs}px;
`;
