import { ChargerIntegration, ChargerManufacturer } from '@hiven-energy/hiven-client';

import { Brand } from './types';

export const brands: Brand[] = [
  {
    name: 'Wallbox (OCPP)',
    integration: ChargerIntegration.OCPP,
    manufacturer: ChargerManufacturer.WALLBOX,
  },
  {
    name: 'OCPP 1.6',
    integration: ChargerIntegration.OCPP,
  },
];
