import { useEffect } from 'react';

export const useInterval = (callback: VoidFunction, interval: number) => {
  useEffect(callback, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      callback();
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [interval, callback]);
};
