import { UserLocation } from '@hiven-energy/hiven-client';
import { PinIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { colors } from 'src/theme';

import Preference from '../Preference/Preference';

import Location from './Location/Location';
import * as styled from './styles';
import TariffInfo from './TariffInfo/TariffInfo';

interface Props {
  locations: UserLocation[];
  showTariffInfo?: boolean;
  onEditPress: VoidFunction;
  onLocationEditPress: (id: string) => void;
  onTariffInfoEditPress: (locationId: string) => void;
}

const ChargingLocations: FC<Props> = ({
  locations,
  showTariffInfo = false,
  onEditPress,
  onLocationEditPress,
  onTariffInfoEditPress,
}) => {
  const intl = useIntl();

  return (
    <Preference
      title={intl.formatMessage({ id: 'common.deviceOverview.chargingLocations.title' })}
      icon={PinIcon}
      onEditPress={onEditPress}
    >
      <styled.Content>
        {locations.map(location => (
          <styled.ChargingLocation key={location.id}>
            <Location location={location} onEditPress={() => onLocationEditPress(location.id)} />
            {showTariffInfo && (
              <TariffInfo tariffInfo={location.tariffInfo} onEditPress={() => onTariffInfoEditPress(location.id)} />
            )}
          </styled.ChargingLocation>
        ))}
        {!locations.length && (
          <styled.WarningLabel color={colors.stoneGrey}>
            <FormattedMessage id="common.deviceOverview.chargingLocations.notAttachedWarning" />
          </styled.WarningLabel>
        )}
      </styled.Content>
    </Preference>
  );
};

export default React.memo(ChargingLocations);
