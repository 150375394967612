import {
  ChargingSchedule,
  UserLocation,
  Vehicle,
  VehicleChargingBehavior,
  VehiclePreferences,
  VehicleStatus,
} from '@hiven-energy/hiven-client';
import React, { FC, useCallback, useState } from 'react';
import { Animated } from 'react-native';

import BaseView from 'src/components/BaseView/BaseView';
import DeviceImage from 'src/containers/device-overview/DeviceImage/DeviceImage';
import FetchError from 'src/containers/device-overview/FetchError/FetchError';
import Header from 'src/containers/device-overview/Header/Header';
import Loader from 'src/containers/device-overview/Loader/Loader';
import Scroll from 'src/containers/device-overview/Scroll/Scroll';
import Tabs, { Tab } from 'src/containers/device-overview/Tabs/Tabs';
import { useAnimatedValue } from 'src/hooks/useAnimatedValue';
import { RouteId, ScreenProps } from 'src/nav/types';
import {
  useChargingSchedule,
  useUserLocations,
  useVehicle,
  useVehiclePreferences,
  useVehicleStatus,
} from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';

import ChargerSetup from './ChargerSetup/ChargerSetup';
import ChargingSetup from './ChargingSetup/ChargingSetup';
import DeviceDetails from './DeviceDetails/DeviceDetails';

type Props = ScreenProps<RouteId.VehicleOverview>;

export interface RoutedProps {
  deviceId: string;
}

interface ContentProps {
  vehicle: Vehicle;
  status?: VehicleStatus;
  preferences?: VehiclePreferences;
  schedule?: ChargingSchedule;
  userLocations?: UserLocation[];
}

const Overview: FC<Props> = ({ route }) => {
  const { deviceId } = route.params;

  const { trackRefresh } = useAnalytics();

  const vehicleQuery = useVehicle(deviceId);
  const statusQuery = useVehicleStatus(deviceId);
  const preferencesQuery = useVehiclePreferences(deviceId);
  const userLocationsQuery = useUserLocations();

  const standalone = !vehicleQuery.data?.associatedToCharger;
  const smartChargeEnabled =
    !!preferencesQuery.data && preferencesQuery.data.chargingBehavior !== VehicleChargingBehavior.SMART_CHARGE_OFF;

  const scheduleQuery = useChargingSchedule(deviceId, { enabled: smartChargeEnabled });

  const scroll = useAnimatedValue(0);

  const handleScroll = Animated.event(
    [
      {
        nativeEvent: {
          contentOffset: { y: scroll },
        },
      },
    ],
    { useNativeDriver: true },
  );

  const refresh = useCallback(async () => {
    trackRefresh();
    await Promise.all([
      vehicleQuery.refetch(),
      statusQuery.refetch(),
      preferencesQuery.refetch(),
      standalone && userLocationsQuery.refetch(),
      smartChargeEnabled && scheduleQuery.refetch(),
    ]);
  }, [
    standalone,
    smartChargeEnabled,
    vehicleQuery.refetch,
    statusQuery.refetch,
    preferencesQuery.refetch,
    userLocationsQuery.refetch,
    scheduleQuery.refetch,
    trackRefresh,
  ]);

  if (vehicleQuery.isLoading) {
    return <Loader />;
  }

  if (vehicleQuery.isError || !vehicleQuery.data) {
    return <FetchError />;
  }

  const Content = standalone ? StandaloneVehicleContent : PairedVehicleContent;

  return (
    <BaseView>
      <DeviceImage deviceType={vehicleQuery.data.type} scroll={scroll} />
      <Scroll refresh={refresh} onScroll={handleScroll}>
        <Header
          deviceType={vehicleQuery.data.type}
          name={vehicleQuery.data.attributes.name}
          heartbeat={statusQuery.data?.timestamp}
        />
        <Content
          vehicle={vehicleQuery.data}
          status={statusQuery.data}
          preferences={preferencesQuery.data}
          schedule={scheduleQuery.isSuccess ? scheduleQuery.data : undefined}
          userLocations={userLocationsQuery.data}
        />
      </Scroll>
    </BaseView>
  );
};

const StandaloneVehicleContent: FC<ContentProps> = ({ vehicle, status, preferences, schedule, userLocations }) => {
  const [activeTab, setActiveTab] = useState(Tab.CHARGING_SETUP);

  return (
    <>
      <Tabs active={activeTab} onChange={setActiveTab} />
      {activeTab === Tab.CHARGING_SETUP && (
        <ChargingSetup vehicle={vehicle} preferences={preferences} userLocations={userLocations} />
      )}
      {activeTab === Tab.DEVICE_DETAILS && (
        <DeviceDetails vehicle={vehicle} status={status} preferences={preferences} schedule={schedule} />
      )}
    </>
  );
};

const PairedVehicleContent: FC<ContentProps> = ({ vehicle, status, preferences, schedule, userLocations }) => (
  <>
    {vehicle.associatedChargerIds.map(chargerId => (
      <ChargerSetup key={chargerId} chargerId={chargerId} userLocations={userLocations} />
    ))}
    <DeviceDetails vehicle={vehicle} status={status} preferences={preferences} schedule={schedule} />
  </>
);

export default Overview;
