export type ResetPasswordRoutedProps = { email: string } | undefined;

export enum Step {
  SEND_CODE,
  NEW_PASSWORD,
}

export interface SendCodeFormData {
  email: string;
}

export interface NewPasswordFormData {
  code: string;
  password: string;
}
