import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { ConfirmSignUpRoutedProps } from 'src/screens/authentication/ConfirmSignUp/types';
import { ConfirmSuccessRoutedProps } from 'src/screens/authentication/ConfirmSuccess/types';
import { ResetPasswordRoutedProps } from 'src/screens/authentication/ResetPassword/types';
import { SignInRoutedProps } from 'src/screens/authentication/SignIn/types';
import { SignUpRoutedProps } from 'src/screens/authentication/SignUp/types';
import { RoutedProps as BrandGroupsRoutedProps } from 'src/screens/brands/BrandGroups/BrandGroups';
import { RoutedProps as ChargerBrandsRoutedProps } from 'src/screens/brands/ChargerBrands/ChargerBrands';
import { RoutedProps as ChargerAnalyticsRoutedProps } from 'src/screens/charger/Analytics/Analytics';
import { RoutedProps as ChargerDashboardRoutedProps } from 'src/screens/charger/Dashboard/Dashboard';
import { RoutedProps as ChargerOverviewRoutedProps } from 'src/screens/charger/Overview/Overview';
import { RoutedProps as ChargerPreferencesRoutedProps } from 'src/screens/charger/Preferences/Preferences';
import { RoutedProps as ChargingLocationRoutedProps } from 'src/screens/ChargingLocation/ChargingLocation';
import { RoutedProps as SmartCarConnectRoutedProps } from 'src/screens/connect/SmartCarConnect/SmartCarConnect';
import { RoutedProps as SetChargingLocationRoutedProps } from 'src/screens/SetChargingLocation/SetChargingLocation';
import { RoutedProps as TariffAndMeterRoutedProps } from 'src/screens/SetTariffAndMeter/SetTariffAndMeter';
import { RoutedProps as SettingsRoutedProps } from 'src/screens/Settings/Settings';
import { RoutedProps as SmartCarDocsRoutedProps } from 'src/screens/SmartCarDocs/SmartCarDocs';
import { RoutedProps as VehicleAnalyticsRoutedProps } from 'src/screens/vehicle/Analytics/Analytics';
import { RoutedProps as VehicleDashboardRoutedProps } from 'src/screens/vehicle/Dashboard/Dashboard';
import { RoutedProps as VehicleOverviewRoutedProps } from 'src/screens/vehicle/Overview/Overview';
import { RoutedProps as VehiclePreferencesRoutedProps } from 'src/screens/vehicle/Preferences/Preferences';

export enum RouteId {
  Initial = 'Initial',
  BrandGroups = 'BrandGroups',
  ChargerBrands = 'ChargerBrands',
  VehicleBrands = 'VehicleBrands',
  // Note: SmartCar Connect route ID has to be all-lowercase letters, without that redirection doesn't work
  SmartCarConnect = 'smartcarconnect',
  SmartCarDocs = 'SmartCarDocs',
  ChargerConnect = 'ChargerConnect',
  WallboxLogin = 'WallboxLogin',
  WallboxConnect = 'WallboxConnect',
  VehicleDashboard = 'VehicleDashboard',
  ChargerDashboard = 'ChargerDashboard',
  VehicleAnalytics = 'VehicleAnalytics',
  ChargerAnalytics = 'ChargerAnalytics',
  Settings = 'Settings',
  VehiclePreferences = 'VehiclePreferences',
  ChargerPreferences = 'ChargerPreferences',
  DevicePairing = 'DevicePairing',
  GetStarted = 'GetStarted',
  SignIn = 'SignIn',
  SignUp = 'SignUp',
  ConfirmSignUp = 'ConfirmSignUp',
  ConfirmSuccess = 'ConfirmSuccess',
  ResetPassword = 'ResetPassword',
  ChangePassword = 'ChangePassword',
  ChangeLanguage = 'ChangeLanguage',
  Onboarding = 'Onboarding',
  MyVehiclesAndChargers = 'MyVehiclesAndChargers',
  MyChargingLocations = 'MyChargingLocations',
  ChargingLocation = 'ChargingLocation',
  SetChargingLocation = 'SetChargingLocation',
  SetTariffAndMeter = 'SetTariffAndMeter',
  VehicleOverview = 'VehicleOverview',
  ChargerOverview = 'ChargerOverview',
  Account = 'Account',
  Consents = 'Consents',
}

export type RoutesParamList = {
  [RouteId.Initial]: undefined;
  [RouteId.BrandGroups]: BrandGroupsRoutedProps;
  [RouteId.ChargerBrands]: ChargerBrandsRoutedProps;
  [RouteId.VehicleBrands]: undefined;
  [RouteId.SmartCarConnect]: SmartCarConnectRoutedProps;
  [RouteId.SmartCarDocs]: SmartCarDocsRoutedProps;
  [RouteId.ChargerConnect]: undefined;
  [RouteId.WallboxLogin]: undefined;
  [RouteId.WallboxConnect]: undefined;
  [RouteId.VehicleDashboard]: VehicleDashboardRoutedProps;
  [RouteId.ChargerDashboard]: ChargerDashboardRoutedProps;
  [RouteId.VehicleAnalytics]: VehicleAnalyticsRoutedProps;
  [RouteId.ChargerAnalytics]: ChargerAnalyticsRoutedProps;
  [RouteId.Settings]: SettingsRoutedProps;
  [RouteId.VehiclePreferences]: VehiclePreferencesRoutedProps;
  [RouteId.ChargerPreferences]: ChargerPreferencesRoutedProps;
  [RouteId.DevicePairing]: undefined;
  [RouteId.GetStarted]: undefined;
  [RouteId.SignIn]: SignInRoutedProps;
  [RouteId.SignUp]: SignUpRoutedProps;
  [RouteId.ConfirmSignUp]: ConfirmSignUpRoutedProps;
  [RouteId.ConfirmSuccess]: ConfirmSuccessRoutedProps;
  [RouteId.ResetPassword]: ResetPasswordRoutedProps;
  [RouteId.ChangePassword]: undefined;
  [RouteId.ChangeLanguage]: undefined;
  [RouteId.Onboarding]: undefined;
  [RouteId.MyVehiclesAndChargers]: undefined;
  [RouteId.MyChargingLocations]: undefined;
  [RouteId.ChargingLocation]: ChargingLocationRoutedProps;
  [RouteId.SetChargingLocation]: SetChargingLocationRoutedProps;
  [RouteId.SetTariffAndMeter]: TariffAndMeterRoutedProps;
  [RouteId.VehicleOverview]: VehicleOverviewRoutedProps;
  [RouteId.ChargerOverview]: ChargerOverviewRoutedProps;
  [RouteId.Account]: undefined;
  [RouteId.Consents]: undefined;
};

export enum RouteHeaderAction {
  GO_BACK = 'GO_BACK',
}

export interface ScreenProps<T extends RouteId> {
  route: RouteProp<RoutesParamList, T>;
  navigation: StackNavigationProp<RoutesParamList, T>;
}
