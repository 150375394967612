import { Dayjs } from 'dayjs';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useA11y } from 'src/a11y';
import RefreshButton from 'src/components/RefreshButton/RefreshButton';
import { usePeriodicalUpdate } from 'src/hooks/usePeriodicalUpdate';

import * as styled from './styles';

const REFRESH_INTERVAL_MILLISECONDS = 1 * 1000;

interface Props {
  refreshing: boolean;
  nextRefreshDate?: Dayjs;
  disabled?: boolean;
  onRefresh: VoidFunction;
}

const ScheduledRefresh: FC<Props> = ({ refreshing, nextRefreshDate, disabled, onRefresh }) => {
  const a11y = useA11y();

  usePeriodicalUpdate(REFRESH_INTERVAL_MILLISECONDS);

  const handleRefreshPress = () => {
    if (!refreshing) onRefresh();
  };

  return (
    <styled.Container>
      <RefreshButton
        size={16}
        loading={refreshing}
        disabled={disabled}
        testID={a11y.formatLabel('scheduledRefresh.reload')}
        accessibilityLabel={a11y.formatLabel('scheduledRefresh.reload')}
        onPress={handleRefreshPress}
      />
      {nextRefreshDate && !refreshing && (
        <styled.Label variant="hint">
          <FormattedMessage id="scheduledRefresh.nextUpdate" values={{ time: nextRefreshDate.fromNow(true) }} />
        </styled.Label>
      )}
      {refreshing && (
        <styled.Label variant="hint">
          <FormattedMessage id="scheduledRefresh.refreshing" />
        </styled.Label>
      )}
    </styled.Container>
  );
};

export default React.memo(ScheduledRefresh);
