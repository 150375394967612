import { BatteryHalfIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import Preference from 'src/containers/device-overview/Preference/Preference';
import Property from 'src/containers/device-overview/Property/Property';
import { EnergyUnit, formatEnergy } from 'src/utils/units';

interface Props {
  capacity?: number;
  onEditPress?: VoidFunction;
}

const BatteryCapacity: FC<Props> = ({ capacity, onEditPress }) => {
  const intl = useIntl();

  return (
    <Preference
      title={intl.formatMessage({ id: 'common.deviceOverview.batteryCapacity.title' })}
      icon={BatteryHalfIcon}
      onEditPress={onEditPress}
    >
      <Property
        name={intl.formatMessage({ id: 'common.deviceOverview.batteryCapacity.label' })}
        value={capacity === undefined ? undefined : formatEnergy(capacity, EnergyUnit.KWH)}
      />
    </Preference>
  );
};

export default React.memo(BatteryCapacity);
