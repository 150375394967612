import { MessageId } from 'src/i18n';

export enum GroupType {
  CHARGER = 'CHARGER',
  VEHICLE = 'VEHICLE',
}

export interface Group {
  type: GroupType;
  nameMessageId: MessageId;
  image: number;
}
