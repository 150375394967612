import { spacings } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { ScrollView } from 'react-native';

import PriceSchedule from 'src/containers/PriceSchedule/PriceSchedule';
import TabBar from 'src/containers/TabBar/TabBar';
import { RouteId, ScreenProps } from 'src/nav/types';

import * as styled from './styles';

export interface RoutedProps {
  deviceId: string;
}

type Props = ScreenProps<RouteId.VehicleAnalytics>;

const Analytics: FC<Props> = ({ route }) => {
  const { deviceId } = route.params;

  return (
    <styled.Container>
      <ScrollView contentContainerStyle={{ flexGrow: 1, padding: spacings.xs }}>
        <PriceSchedule deviceId={deviceId} analyticsPlace="VehicleAnalytics.priceSchedule" />
      </ScrollView>
      <TabBar deviceId={deviceId} />
    </styled.Container>
  );
};

export default Analytics;
