import dayjs, { Dayjs } from 'dayjs';
import { useEffect } from 'react';

interface Options {
  enabled?: boolean;
  targetDate: Dayjs;
}

export const useScheduledCall = (callback: VoidFunction, options: Options) => {
  const { targetDate, enabled = true } = options;

  useEffect(() => {
    if (!enabled) return;

    const currentDate = dayjs();
    const timeout = targetDate.valueOf() - currentDate.valueOf();

    if (timeout <= 0) {
      callback();
      return;
    }

    const timerId = setTimeout(callback, timeout);

    return () => clearTimeout(timerId);
  }, [callback, targetDate, enabled]);
};
