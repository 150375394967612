import { ContainerProps, CopyIcon, IconButton } from '@hiven-energy/hiven-ui';
import * as Clipboard from 'expo-clipboard';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';

import * as styled from './styles';

export type Value = string | number | boolean | null | undefined;

export interface Property {
  name?: string;
  value: Value;
  copyable?: boolean;
  isApplicable?: boolean;
}

type Props = Property & ContainerProps;

const Property: FC<Props> = ({ name, value, copyable, isApplicable = true, ...containerProps }) => {
  const intl = useIntl();
  const toast = useToast();

  const formatValue = (value: Value): string => {
    if (typeof value === 'boolean') {
      return value ? intl.formatMessage({ id: 'common.yes' }) : intl.formatMessage({ id: 'common.no' });
    }
    if (typeof value === 'number') {
      return value.toString();
    }
    return value ?? '-';
  };

  const handleCopyPress = async () => {
    await Clipboard.setStringAsync(formatValue(value));
    toast.show(intl.formatMessage({ id: 'common.clipboard.copied' }), { type: 'success' });
  };

  return (
    isApplicable && (
      <styled.Container key={name} {...containerProps}>
        <styled.ValueWrapper>
          <styled.Value>{formatValue(value)}</styled.Value>
          {copyable && <IconButton type="plain" icon={CopyIcon} onPress={handleCopyPress} />}
        </styled.ValueWrapper>
        {name !== undefined && <styled.Name>{name}</styled.Name>}
      </styled.Container>
    )
  );
};

export default React.memo(Property);
