import { Auth } from '@aws-amplify/auth';

import { getSystemLanguage, Language } from 'src/i18n';

import { SignOutOptions, UserAttributes } from './types';

export const isUserAuthenticated = async (): Promise<boolean> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const userEmail = user.attributes.email;
    const userSubId = user.attributes.sub;
    console.log('Authenticated user', {
      email: userEmail,
      subscription: userSubId,
      jwt: user.signInUserSession.idToken.jwtToken,
    });
    return !!user && !!userEmail && !!userSubId;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const signUp = (email: string, password: string) =>
  Auth.signUp({
    username: email,
    password: password,
    attributes: { email, 'custom:termsAndConditions': 'true' },
  });

export const confirmSignUp = (email: string, code: string) => Auth.confirmSignUp(email, code);

export const resendCode = (email: string) => Auth.resendSignUp(email);

export const sendCode = (email: string) => Auth.forgotPassword(email);

export const resetPassword = (email: string, code: string, newPassword: string) =>
  Auth.forgotPasswordSubmit(email, code, newPassword);

export const changePassword = async (oldPassword: string, newPassword: string) => {
  const user = await Auth.currentAuthenticatedUser();
  return Auth.changePassword(user, oldPassword, newPassword);
};

export const signIn = (email: string, password: string) => {
  return Auth.signIn(email, password);
};

export const signOut = (options?: SignOutOptions) => Auth.signOut(options);

export const deleteAccount = () => Auth.deleteUser();

export const getIdJwt = async () =>
  Auth.currentSession()
    .then(session => session.getIdToken().getJwtToken())
    .catch(error => {
      console.error('Could not retrieve JWT token', error);
      throw error;
    });

export const getUserId = () =>
  getUserAttributes()
    .then(attributes => attributes.userId)
    .catch(error => {
      console.error('Could not retrieve user Id', error);
      throw error;
    });

export const getEmail = () =>
  getUserAttributes()
    .then(attributes => attributes.email)
    .catch(error => {
      console.error('Could not retrieve user email', error);
      throw error;
    });

export const getLanguage = () =>
  getUserAttributes()
    .then(attributes => attributes.language)
    .catch(error => {
      console.error('Could not retrieve language', error);
      throw error;
    });

export const updateLanguage = async (language: Language) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, { 'custom:language': language });
  } catch (error) {
    console.error('Could not update language', error);
    throw error;
  }
};

export const getTermsAndConditions = () =>
  getUserAttributes()
    .then(attributes => attributes.termsAndConditions)
    .catch(error => {
      console.error('Could not retrieve terms and conditions', error);
      throw error;
    });

export const updateTermsAndConditions = async (accepted: boolean) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, { 'custom:termsAndConditions': `${accepted}` });
  } catch (error) {
    console.error('Could not update terms and conditions', error);
    throw error;
  }
};

export const getPrivacyPolicy = () =>
  getUserAttributes()
    .then(attributes => attributes.privacyPolicy)
    .catch(error => {
      console.error('Could not retrieve privacy policy', error);
      throw error;
    });

export const updatePrivacyPolicy = async (accepted: boolean) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, { 'custom:privacyPolicy': `${accepted}` });
  } catch (error) {
    console.error('Could not update privacy policy', error);
    throw error;
  }
};

const getUserAttributes = async (): Promise<UserAttributes> =>
  Auth.currentUserInfo()
    .then(async userInfo => {
      const rawAttributes = userInfo?.attributes;
      return {
        userId: rawAttributes?.sub || '',
        email: rawAttributes?.email || '',
        language: rawAttributes?.['custom:language'] || getSystemLanguage(),
        termsAndConditions: rawAttributes?.['custom:termsAndConditions'] === 'true',
        privacyPolicy: rawAttributes?.['custom:privacyPolicy'] === 'true',
      };
    })
    .catch(error => {
      console.error('Could not fetch user attributes', error);
      throw error;
    });
