import { ChargerAttributes, ChargerManufacturer } from '@hiven-energy/hiven-client';
import { ArrowRightIcon } from '@hiven-energy/hiven-ui';
import { useNavigation } from '@react-navigation/native';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Image } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import { useAppTheme } from 'src/app-theme';
import Loader from 'src/components/Loader/Loader';
import { useConnectSuccessTarget } from 'src/hooks/useConnectSuccessTarget';
import { usePrevious } from 'src/hooks/usePrevious';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useDevices, useRegisterCharger, useWallboxChargers } from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';
import { colors } from 'src/theme';
import { findNewDevice } from 'src/utils/device';

import Success from '../ChargerConnect/Success/Success';

import * as styled from './styles';

const DEVICES_MAX_REFETCHES = 10;
const DEVICES_REFETCH_INTERVAL = 2000;

type Props = ScreenProps<RouteId.WallboxConnect>;

const WallboxConnect: FC<Props> = () => {
  const intl = useIntl();
  const toast = useToast();
  const navigation = useNavigation();

  const appTheme = useAppTheme();
  const successTarget = useConnectSuccessTarget();

  const [refetches, setRefetches] = useState(0);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const chargersQuery = useWallboxChargers();
  const registerChargerMutation = useRegisterCharger();
  const devicesQuery = useDevices({
    onSuccess: () => {
      setRefetches(refetches => refetches + 1);
    },
    refetchInterval: DEVICES_REFETCH_INTERVAL,
    enabled: shouldRefetch && refetches <= DEVICES_MAX_REFETCHES,
  });
  const previousDevices = usePrevious(devicesQuery.data);
  const { trackRegisterCharger } = useAnalytics();

  useEffect(() => {
    const device = previousDevices && findNewDevice(previousDevices, devicesQuery.data ?? []);
    if (!device) return;
    setIsSuccess(true);
  }, [devicesQuery.data, previousDevices, setIsSuccess]);

  const { data: chargers } = chargersQuery;

  const handleChargerPress = (id: string, name: string) => {
    const deviceExists = previousDevices?.find(
      device => (device.attributes as ChargerAttributes & { vendorDeviceId: string }).vendorDeviceId === id,
    );
    if (deviceExists) {
      const message = intl.formatMessage({ id: 'WallboxConnect.deviceAlreadyExists' });
      toast.show(message, { type: 'warning' });
      handleContinue();
      return;
    }

    trackRegisterCharger('Charger.title', name);
    registerChargerMutation.mutate({
      name,
      vendor: ChargerManufacturer.WALLBOX,
      vendorDeviceId: id,
      autoSetupOCPPConnection: true,
    });
    setShouldRefetch(true);
  };

  const handleContinue = () => {
    navigation.replace(...successTarget.getTarget());
  };

  if (isSuccess) {
    return <Success onContinue={handleContinue} />;
  }

  const isLoading = chargersQuery.isLoading || registerChargerMutation.isLoading || shouldRefetch;

  return (
    <styled.Container>
      <styled.Title variant="h3">
        <FormattedMessage id="WallboxConnect.title" />
      </styled.Title>
      {isLoading ? (
        <Loader color={colors.deepNavy} />
      ) : !chargers || chargers.length === 0 ? (
        <styled.NoData>
          <FormattedMessage id="WallboxConnect.noData" />
        </styled.NoData>
      ) : (
        chargers.map(({ id, name, uid, image }, index) => (
          <styled.Charger
            key={id}
            $isLast={index === chargers.length - 1}
            activeOpacity={0.5}
            onPress={() => {
              handleChargerPress(id.toString(), name);
            }}
          >
            <styled.Content>
              <Image source={{ uri: image, width: 56, height: 56 }} />
              <styled.Details>
                <styled.Name testID={name} variant="h2">
                  {name}
                </styled.Name>
                <styled.SerialNumber testID={name}>{uid}</styled.SerialNumber>
              </styled.Details>
            </styled.Content>
            <styled.Action backgroundColor={appTheme.main.color}>
              <ArrowRightIcon color={colors.white} />
            </styled.Action>
          </styled.Charger>
        ))
      )}
    </styled.Container>
  );
};

export default WallboxConnect;
