import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { PlaceDetails, PlacePrediction, Region, useMapsService } from 'src/services/maps';
import { useSession } from 'src/store/session';

import { QueryOptions } from './types';

export const usePlaceAutocompletePredictions = (
  search: string,
  options?: QueryOptions<PlacePrediction[], AxiosError>,
) => {
  const session = useSession();
  const service = useMapsService();
  return useQuery(
    ['backend', 'maps', 'predictions', 'autocomplete', search],
    async () => service.getPlaceAutocompletePredictions(search, await session.getAuthorizationToken()),
    options,
  );
};

export const usePlaceDetails = (placeId: string, options?: QueryOptions<PlaceDetails, AxiosError>) => {
  const session = useSession();
  const service = useMapsService();
  return useQuery(
    ['backend', 'maps', 'place', placeId],
    async () => service.getPlaceDetails(placeId, await session.getAuthorizationToken()),
    options,
  );
};

export const usePlaceRegionPredictions = (region: Region, options?: QueryOptions<PlacePrediction[], AxiosError>) => {
  const session = useSession();
  const service = useMapsService();
  return useQuery(
    ['backend', 'maps', 'predictions', 'region', `lat-${region.latitude} lng-${region.longitude}`],
    async () => service.getPlaceRegionPredictions(region, await session.getAuthorizationToken()),
    options,
  );
};
