import { colors } from 'src/theme';

import { Category } from './types';

export const UPCOMING_HOURS = 24;
export const HOURS_RESOLUTION = 3;

export const MAX_HEIGHT = 300;
export const ASPECT_RATIO = 0.5;

export const categoryToBarColor: Record<Category, string> = {
  charge: colors.sunshineYellow,
  overtime: colors.raspberryPink,
  wait: colors.disabledGrey,
};
