import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const Container = styled(_Container)`
  margin-top: ${spacings.xxs}px;
  margin-bottom: ${spacings.xxs}px;
`;

export const Header = styled(_Container)`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${spacings.xxxs}px;
`;

export const IconTitleWrapper = styled(_Container)`
  flex-direction: row;
  gap: ${spacings.xxxs}px;
  align-items: center;
`;

export const Title = styled(Typography).attrs({ fontWeight: 'medium' })`
  font-size: 16px;
  line-height: 24px;
`;

export const Content = styled(_Container)`
  margin-left: 30px;
`;
