import { EditIcon, IconProps, spacings } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';

import { useA11y } from 'src/a11y';
import { useAnalytics } from 'src/services/analytics';
import { AnalyticPlace } from 'src/services/analytics/types';
import { colors } from 'src/theme';

import * as styled from './styles';

interface Props {
  icon?: FC<IconProps>;
  title: string;
  children: React.ReactNode;
  contentPadding?: number;
  onEditPress?: VoidFunction;
  analyticsPlace: AnalyticPlace;
  disabledText?: string;
}

const PreferenceOverview: FC<Props> = ({
  icon: Icon,
  title,
  children,
  contentPadding = spacings.xxs,
  onEditPress,
  analyticsPlace,
  disabledText,
}) => {
  const { trackButtonClick } = useAnalytics();

  const handleEdit = () => {
    trackButtonClick('common.edit', analyticsPlace);
    if (onEditPress) {
      onEditPress();
    }
  };

  return (
    <styled.Container>
      <styled.Header>
        {Icon && <Icon size={20} color={colors.hintGrey} />}
        <styled.Title variant="h4" color={colors.deepNavy}>
          {title}
        </styled.Title>
      </styled.Header>
      {disabledText && <styled.DisabledText>{disabledText}</styled.DisabledText>}
      <styled.Content padding={!onEditPress ? contentPadding : undefined}>
        {onEditPress ? (
          <EditableContainer
            onEditPress={handleEdit}
            title={title}
            padding={contentPadding}
            disabledText={disabledText}
          >
            {children}
          </EditableContainer>
        ) : (
          children
        )}
      </styled.Content>
    </styled.Container>
  );
};

const EditableContainer: FC<
  Pick<Props, 'children' | 'onEditPress' | 'title' | 'disabledText'> & {
    padding: number;
  }
> = ({ onEditPress, title, disabledText, padding, children }) => {
  const a11y = useA11y();

  return (
    <styled.EditableContainer
      accessible={false}
      padding={padding}
      onPress={onEditPress}
      accessibilityLabel={a11y.formatLabel('common.edit', { name: title })}
      testID={a11y.formatLabel('common.edit', { name: title })}
      disabled={!!disabledText}
    >
      <styled.EditableContent>{children}</styled.EditableContent>
      <EditIcon color={disabledText ? colors.pidgeonGray : colors.deepNavy} />
    </styled.EditableContainer>
  );
};

export default React.memo(PreferenceOverview);
