import { Button } from '@hiven-energy/hiven-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';
import { z } from 'zod';

import { useA11y } from 'src/a11y';
import { TextField } from 'src/components/form-fields/TextField';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useConfirmSignUp } from 'src/queries/session';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';
import { CognitoAction } from 'src/services/session';
import * as validators from 'src/utils/validators';

import { getCognitoErrorMessageId } from '../utils';

import ResendCode from './ResendCode/ResendCode';
import * as styled from './styles';
import { ConfirmSignUpFormData } from './types';

type Props = ScreenProps<RouteId.ConfirmSignUp>;

const confirmTitle = { id: 'ConfirmSignUp.button' };

const ConfirmSignUp: FC<Props> = ({ route, navigation }) => {
  const intl = useIntl();
  const a11y = useA11y();
  const toast = useToast();

  const { trackConfirmSignUp } = useAnalytics();
  useAnalyticsTimeEvent(MixpanelEvents.SIGNED_UP_CONFIRMED);

  const schema = useMemo(
    () =>
      z.object({
        email: validators.email(intl),
        code: validators.code(intl),
      }),
    [intl],
  );

  const form = useForm<ConfirmSignUpFormData>({
    mode: 'onChange',
    defaultValues: {
      email: route.params.email || '',
      code: '',
    },
    resolver: zodResolver(schema),
  });

  const confirmSignUpMutation = useConfirmSignUp({
    onSuccess: (...args) => {
      const [_, payload] = args;
      const { email } = payload;
      navigation.navigate(RouteId.SignIn, { email });
      trackConfirmSignUp(confirmTitle.id, email);
      toast.show(intl.formatMessage({ id: 'ConfirmSignUp.success' }), { type: 'success' });
    },
    onError: error => {
      const errorMessageId = getCognitoErrorMessageId(CognitoAction.CONFIRM_SIGN_UP, error);
      toast.show(<FormattedMessage id={errorMessageId} />, { type: 'danger' });
    },
  });

  const confirmSignUp = (data: ConfirmSignUpFormData) => {
    confirmSignUpMutation.mutate(data);
  };

  return (
    <styled.Container>
      <styled.Title variant="h3" accessibilityLabel={a11y.formatLabel('ConfirmSignUp.title')}>
        <FormattedMessage id="ConfirmSignUp.title" />
      </styled.Title>
      <TextField placeholder={intl.formatMessage({ id: 'common.code' })} control={form.control} name="code" />
      <styled.Footer>
        <Button
          title={intl.formatMessage(confirmTitle)}
          loading={confirmSignUpMutation.isLoading}
          disabled={confirmSignUpMutation.isLoading || !form.formState.isValid}
          testID={a11y.formatLabel('ConfirmSignUp.button')}
          onPress={form.handleSubmit(confirmSignUp)}
        />
        <ResendCode
          sendCode={!!route.params.sendCode}
          getEmail={() => form.getValues().email}
          getEmailFieldError={() => form.formState.errors.email?.message}
        />
      </styled.Footer>
    </styled.Container>
  );
};

export default ConfirmSignUp;
