import * as sdk from '@hiven-energy/hiven-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useFocusAwareQuery } from 'src/hooks/useFocusAwareQuery';
import { useHivenClient } from 'src/services/hiven';
import { useSession } from 'src/store/session';
import { isCharger, isVehicle, sortDevices } from 'src/utils/device';

import { MutationOptions, QueryOptions } from './types';

export const PROPAGATION_DELAY = 2 * 1000;
export const NEW_DEVICE_PROPAGATION_DELAY = 4 * 1000;

export const useUserLocations = <TData = sdk.UserLocation[]>(
  options?: QueryOptions<sdk.UserLocation[], Response, TData>,
) => {
  const session = useSession();
  const client = useHivenClient();
  return useFocusAwareQuery(['userLocations'], () => client.getUserLocations(session.userId), options);
};

interface SetOrUpdateUserLocationVariables {
  id?: string;
  payload: sdk.SetUserLocationPayload;
}

export const useSetOrUpdateUserLocation = (
  options?: MutationOptions<SetOrUpdateUserLocationVariables, sdk.UserLocation>,
) => {
  const session = useSession();
  const queryClient = useQueryClient();
  const client = useHivenClient();
  return useMutation({
    ...options,
    mutationFn: ({ id, payload }) =>
      id ? client.updateUserLocation(session.userId, id, payload) : client.setUserLocation(session.userId, payload),
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
      const [newUserLocation] = args;
      queryClient.setQueryData<sdk.UserLocation[]>(['userLocations'], userLocations => {
        if (!userLocations) return undefined;

        const updateIndex = userLocations.findIndex(({ id }) => newUserLocation.id === id);

        return updateIndex === -1
          ? [...userLocations, newUserLocation]
          : [...userLocations.slice(0, updateIndex), newUserLocation, ...userLocations.slice(updateIndex + 1)];
      });
    },
  });
};

export const useSaveTariffInformation = (
  locationId: string,
  options?: MutationOptions<sdk.TariffInfo, sdk.UserLocation>,
) => {
  const session = useSession();
  const queryClient = useQueryClient();
  const client = useHivenClient();
  return useMutation({
    ...options,
    mutationFn: payload => client.setTariffInfo(session.userId, locationId, payload),
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
      queryClient.invalidateQueries(['userLocations']);
    },
  });
};

export const useUserCountryCode = (options?: QueryOptions<sdk.UserLocation[], Response, string>) =>
  useUserLocations<string>({
    ...options,
    select: ([location]) => location?.countryCode,
  });

export const useDefaultUserLocation = (
  options?: QueryOptions<sdk.UserLocation[], Response, sdk.UserLocation | undefined>,
) =>
  useUserLocations<sdk.UserLocation | undefined>({
    ...options,
    select: locations => locations[0],
  });

export const useUserLocation = (
  locationId: string,
  options?: QueryOptions<sdk.UserLocation[], Response, sdk.UserLocation | undefined>,
) =>
  useUserLocations<sdk.UserLocation | undefined>({
    ...options,
    select: locations => locations.find(({ id }) => id === locationId),
  });

export const useSetUserLocation = (options?: MutationOptions<sdk.SetUserLocationPayload, sdk.UserLocation>) => {
  const session = useSession();
  const queryClient = useQueryClient();
  const client = useHivenClient();
  return useMutation({
    ...options,
    mutationFn: payload => client.setUserLocation(session.userId, payload),
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
      queryClient.invalidateQueries(['userLocations']);
    },
  });
};

interface UpdateUserLocationVariables {
  id: string;
  payload: sdk.UpdateUserLocationPayload;
}

export const useUpdateUserLocation = (options?: MutationOptions<UpdateUserLocationVariables, sdk.UserLocation>) => {
  const session = useSession();
  const queryClient = useQueryClient();
  const client = useHivenClient();
  return useMutation({
    ...options,
    mutationFn: ({ id, payload }) => client.updateUserLocation(session.userId, id, payload),
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
      queryClient.invalidateQueries(['userLocations']);
    },
  });
};

export const useDeleteUserLocation = (options?: MutationOptions<string>) => {
  const session = useSession();
  const queryClient = useQueryClient();
  const client = useHivenClient();
  return useMutation({
    ...options,
    mutationFn: id => client.deleteUserLocation(session.userId, id),
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
      queryClient.invalidateQueries(['userLocations']);
    },
  });
};

export const useDevices = <TData = sdk.Device[]>(options?: QueryOptions<sdk.Device[], Response, TData>) => {
  const session = useSession();
  const client = useHivenClient();
  return useFocusAwareQuery(
    ['devices'],
    async () => {
      const devices = await client.getDevicesList(session.userId);
      return sortDevices(devices);
    },
    { ...options, staleTime: Infinity },
  );
};

export const useVehicles = (options?: QueryOptions<sdk.Device[], Response, sdk.Vehicle[]>) =>
  useDevices({
    ...options,
    select: devices => devices.filter(isVehicle),
  });

export const useChargers = (options?: QueryOptions<sdk.Device[], Response, sdk.Charger[]>) =>
  useDevices({
    ...options,
    select: devices => devices.filter(isCharger),
  });

export const useDevice = <TData extends sdk.Device>(
  id: string,
  options?: QueryOptions<sdk.Device[], Response, TData | undefined>,
) =>
  useDevices({
    ...options,
    select: devices => devices.find((device): device is TData => device.id === id),
  });

export const useVehicle = (id: string, options?: QueryOptions<sdk.Device[], Response, sdk.Vehicle | undefined>) =>
  useDevice(id, options);

export const useCharger = (id: string, options?: QueryOptions<sdk.Device[], Response, sdk.Charger | undefined>) =>
  useDevice(id, options);

export const useDeleteDevice = (options?: MutationOptions<string>) => {
  const queryClient = useQueryClient();
  const client = useHivenClient();
  return useMutation({
    ...options,
    mutationFn: id => client.deleteDevice(id),
    onSuccess: (...args) => {
      const [_data, id] = args;
      queryClient.setQueryData<sdk.Device[]>(['devices'], devices => {
        const filteredDevices = devices?.filter(device => device.id !== id);
        const updatedDevices = filteredDevices?.map(device =>
          isVehicle(device)
            ? {
                ...device,
                associatedChargerIds: device.associatedChargerIds.filter(
                  associatedChargerId => associatedChargerId !== id,
                ),
              }
            : device,
        );
        return updatedDevices;
      });
      options?.onSuccess?.(...args);
    },
  });
};

export const useStartImmediateCharging = (options?: MutationOptions<string>) => {
  const queryClient = useQueryClient();
  const client = useHivenClient();
  return useMutation({
    ...options,
    mutationFn: id => client.startImmediateCharging(id),
    onSuccess: (...args) => {
      const [_data, deviceId] = args;
      queryClient.invalidateQueries(['device', deviceId, 'preferences']).then(() => {
        queryClient.removeQueries(['device', deviceId, 'schedule']);
      });
      options?.onSuccess?.(...args);
    },
  });
};

export const useStopImmediateCharging = (options?: MutationOptions<string>) => {
  const queryClient = useQueryClient();
  const client = useHivenClient();
  return useMutation({
    ...options,
    mutationFn: id => client.stopImmediateCharging(id),
    onSuccess: (...args) => {
      const [_data, deviceId] = args;
      queryClient.invalidateQueries(['device', deviceId, 'preferences']).then(() => {
        queryClient.removeQueries(['device', deviceId, 'schedule']);
      });
      options?.onSuccess?.(...args);
    },
  });
};

interface RegisterVehicleVariables {
  code: string;
  redirectUrl: string;
}

export const useRegisterVehicle = (options?: MutationOptions<RegisterVehicleVariables>) => {
  const queryClient = useQueryClient();
  const client = useHivenClient();
  return useMutation({
    ...options,
    mutationFn: ({ code, redirectUrl }) => client.registerVehicle(code, redirectUrl),
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
      setTimeout(() => queryClient.invalidateQueries(['devices']), NEW_DEVICE_PROPAGATION_DELAY);
    },
  });
};

export const useVehicleStatus = (deviceId: string, options?: QueryOptions<sdk.VehicleStatus>) => {
  const client = useHivenClient();
  return useFocusAwareQuery(['device', deviceId, 'status'], () => client.getVehicleStatus(deviceId), options);
};

export const useVehiclePreferences = (deviceId: string, options?: QueryOptions<sdk.VehiclePreferences>) => {
  const client = useHivenClient();
  return useFocusAwareQuery(['device', deviceId, 'preferences'], () => client.getVehiclePreferences(deviceId), options);
};

interface UpdateVehiclePreferencesVariables {
  deviceId: string;
  preferences: sdk.VehiclePreferences;
}

export const useUpdateVehiclePreferences = (options?: MutationOptions<UpdateVehiclePreferencesVariables>) => {
  const queryClient = useQueryClient();
  const client = useHivenClient();
  return useMutation({
    ...options,
    mutationFn: ({ deviceId, preferences }) => client.setVehiclePreferences(deviceId, preferences),
    onSuccess: (...args) => {
      const [_data, { deviceId, preferences }] = args;
      queryClient.setQueryData(['device', deviceId, 'preferences'], preferences);
      queryClient.invalidateQueries(['device', deviceId, 'preferences']);
      queryClient.invalidateQueries({ queryKey: ['devices'], exact: true });
      if (preferences.chargingBehavior === sdk.VehicleChargingBehavior.SMART_CHARGE_OFF) {
        queryClient.removeQueries(['device', deviceId, 'schedule']);
      } else {
        setTimeout(() => queryClient.invalidateQueries(['device', deviceId, 'schedule']), PROPAGATION_DELAY);
      }
      options?.onSuccess?.(...args);
    },
  });
};

export const useRegisterWallboxAccount = (options?: MutationOptions<sdk.RegisterWallboxAccountPayload>) => {
  const queryClient = useQueryClient();
  const client = useHivenClient();
  return useMutation({
    ...options,
    mutationFn: (payload: sdk.RegisterWallboxAccountPayload) =>
      client.registerWallboxAccount(payload.username, payload.password),
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
      setTimeout(() => queryClient.invalidateQueries(['wallbox-chargers']), PROPAGATION_DELAY);
    },
  });
};

export const useWallboxChargers = (options?: QueryOptions<sdk.WallboxCharger[]>) => {
  const client = useHivenClient();
  return useFocusAwareQuery(['wallbox-chargers'], () => client.getWallboxChargers(), options);
};

export const useRegisterCharger = (options?: MutationOptions<sdk.RegisterChargerPayload>) => {
  const queryClient = useQueryClient();
  const client = useHivenClient();
  return useMutation({
    ...options,
    mutationFn: (payload: sdk.RegisterChargerPayload) => client.registerCharger(payload),
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
      setTimeout(() => queryClient.invalidateQueries(['devices']), NEW_DEVICE_PROPAGATION_DELAY);
    },
  });
};

export const useChargerStatus = (deviceId: string, options?: QueryOptions<sdk.ChargerStatus>) => {
  const client = useHivenClient();
  return useFocusAwareQuery(['device', deviceId, 'status'], () => client.getChargerStatus(deviceId), options);
};

export const useChargerPreferences = (deviceId: string, options?: QueryOptions<sdk.ChargerPreferences>) => {
  const client = useHivenClient();
  return useFocusAwareQuery(['device', deviceId, 'preferences'], () => client.getChargerPreferences(deviceId), options);
};

interface UpdateChargerPreferencesVariables {
  deviceId: string;
  preferences: sdk.ChargerPreferences;
}

export const useUpdateChargerPreferences = (options?: MutationOptions<UpdateChargerPreferencesVariables>) => {
  const queryClient = useQueryClient();
  const client = useHivenClient();
  return useMutation({
    ...options,
    mutationFn: ({ deviceId, preferences }) => client.setChargerPreferences(preferences, deviceId),
    onSuccess: (...args) => {
      const [_data, { deviceId, preferences }] = args;
      queryClient.setQueryData(['device', deviceId, 'preferences'], preferences);
      queryClient.invalidateQueries(['device', deviceId, 'preferences']);
      queryClient.invalidateQueries({ queryKey: ['devices'], exact: true });
      if (preferences.chargingBehavior === sdk.ChargerChargingBehavior.SMART_CHARGE_OFF) {
        queryClient.removeQueries(['device', deviceId, 'schedule']);
      } else {
        setTimeout(() => queryClient.invalidateQueries(['device', deviceId, 'schedule']), PROPAGATION_DELAY);
      }
      options?.onSuccess?.(...args);
    },
  });
};

interface UpdateChargerVehiclePreferencesVariables {
  chargerId?: string;
  vehicleId?: string;
  chargerPreferences?: sdk.ChargerPreferences;
  vehiclePreferences?: sdk.VehiclePreferences;
}

export const useUpdateChargerVehiclePreferences = (
  options?: MutationOptions<UpdateChargerVehiclePreferencesVariables>,
) => {
  const queryClient = useQueryClient();
  const client = useHivenClient();
  return useMutation({
    ...options,
    mutationFn: async ({ chargerId, vehicleId, chargerPreferences, vehiclePreferences }) => {
      if (vehicleId && vehiclePreferences) {
        await client.setVehiclePreferences(vehicleId, vehiclePreferences);
      }
      if (chargerId && chargerPreferences) {
        await client.setChargerPreferences(chargerPreferences, chargerId);
      }
    },
    onSuccess: (...args) => {
      const [_data, { chargerId, vehicleId, chargerPreferences, vehiclePreferences }] = args;
      if (chargerId && chargerPreferences) {
        queryClient.setQueryData(['device', chargerId, 'preferences'], {
          ...chargerPreferences,
          associatedDeviceId:
            chargerPreferences.associatedDeviceId === 'DISSOCIATE' ? undefined : chargerPreferences.associatedDeviceId,
        });
        if (chargerPreferences.chargingBehavior === sdk.ChargerChargingBehavior.SMART_CHARGE_OFF) {
          queryClient.removeQueries(['device', chargerId, 'schedule']);
        } else {
          setTimeout(() => {
            queryClient.invalidateQueries(['device', chargerId, 'schedule']);
          }, PROPAGATION_DELAY);
        }
      }
      if (vehicleId && vehiclePreferences) {
        queryClient.setQueryData(['device', vehicleId, 'preferences'], vehiclePreferences);
        if (vehiclePreferences.chargingBehavior === sdk.VehicleChargingBehavior.SMART_CHARGE_OFF) {
          queryClient.removeQueries(['device', vehicleId, 'schedule']);
        } else {
          setTimeout(() => {
            queryClient.invalidateQueries(['device', vehicleId, 'schedule']);
          }, PROPAGATION_DELAY);
        }
      }
      if (chargerId) {
        queryClient.invalidateQueries(['device', chargerId, 'preferences']);
        queryClient.invalidateQueries({ queryKey: ['device', chargerId], exact: true });
      }
      if (vehicleId) {
        queryClient.invalidateQueries(['device', vehicleId, 'preferences']);
        queryClient.invalidateQueries({ queryKey: ['device', vehicleId], exact: true });
      }
      queryClient.invalidateQueries({ queryKey: ['devices'], exact: true });
      options?.onSuccess?.(...args);
    },
  });
};

export const useChargingSchedule = (deviceId: string, options?: QueryOptions<sdk.ChargingSchedule>) => {
  const client = useHivenClient();
  return useFocusAwareQuery(['device', deviceId, 'schedule'], () => client.getChargingSchedule(deviceId), options);
};

export const useChargingSessions = (deviceId: string, options?: QueryOptions<sdk.ChargingSession[]>) => {
  const client = useHivenClient();
  return useFocusAwareQuery(
    ['device', deviceId, 'sessions'],
    () => client.getChargingSessionHistory(deviceId),
    options,
  );
};

export const useSupportedCountries = (options?: QueryOptions<string[]>) => {
  const client = useHivenClient();
  return useFocusAwareQuery(['supported-countries'], () => client.getSupportedCountries(), options);
};

export const useSteveUrl = (options?: QueryOptions<sdk.SteveUrl>) => {
  const client = useHivenClient();
  return useFocusAwareQuery(['steve-url'], () => client.getSteveUrl(), options);
};

export const useSpotPrices = (area: sdk.SpotPriceArea, options?: QueryOptions<Record<string, number>>) => {
  const client = useHivenClient();
  return useFocusAwareQuery(['spot-prices', area], () => client.getSpotPrices(area), options);
};

export const useElectricalUtilityProviders = <TData = string[]>(options?: QueryOptions<string[], Response, TData>) => {
  const client = useHivenClient();
  return useFocusAwareQuery(['electrical-utility-providers'], () => client.getElectricalUtilityProviders(), options);
};
