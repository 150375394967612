import { ChargerChargingBehavior } from '@hiven-energy/hiven-client';
import { FlashIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import Preference from 'src/containers/device-overview/Preference/Preference';
import Property from 'src/containers/device-overview/Property/Property';

interface Props {
  chargingBehavior?: ChargerChargingBehavior;
  onEditPress?: VoidFunction;
}

const ChargingBehavior: FC<Props> = ({ chargingBehavior, onEditPress }) => {
  const intl = useIntl();

  const chargingBehaviorToLabel: Record<ChargerChargingBehavior, string> = {
    [ChargerChargingBehavior.SMART_CHARGE_OFF]: intl.formatMessage({ id: 'charger.chargingBehavior.SMART_CHARGE_OFF' }),
    [ChargerChargingBehavior.SMART_CHARGE_ON]: intl.formatMessage({ id: 'charger.chargingBehavior.SMART_CHARGE_ON' }),
  };

  const label = chargingBehavior && chargingBehaviorToLabel[chargingBehavior];

  return (
    <Preference
      title={intl.formatMessage({ id: 'common.deviceOverview.chargingBehavior.title' })}
      icon={FlashIcon}
      onEditPress={onEditPress}
    >
      <Property value={label} />
    </Preference>
  );
};

export default React.memo(ChargingBehavior);
