import { Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Setting = styled(Container)<{ $noMargin?: boolean }>`
  padding: ${spacings.xxs}px;
  border: 1px dashed ${colors.pidgeonGray};
  border-radius: ${spacings.xxs}px;
  margin-bottom: ${p => (p.$noMargin ? spacings.none : spacings.xxs)}px;
`;

export const NameWrapper = styled(Container)`
  display: flex;
  align-items: center;
  margin-bottom: ${spacings.xxs}px;
`;

export const Name = Typography;

export const Icon = styled(TouchableOpacity)`
  margin-bottom: ${spacings.xxxxs}px;
`;

export const Value = styled(Typography)`
  text-align: center;
`;
