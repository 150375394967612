import { isMessageId, MessageId } from 'src/i18n';
import { CognitoAction } from 'src/services/session';

export type CognitoError = Error & { code: string };

export const getCognitoErrorMessageId = (action: CognitoAction, error: CognitoError): MessageId => {
  const errorCode = error.code || error.name || 'UnknownError';
  const commonMessageId = `cognito.error.${errorCode}`;
  const actionMessageId = `cognito.error.${action}.${errorCode}`;

  if (isMessageId(actionMessageId)) return actionMessageId;
  if (isMessageId(commonMessageId)) return commonMessageId;

  return 'cognito.error.UnknownError';
};
