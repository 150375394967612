import { KeyboardAvoidingView as _KeyboardAvoidingView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import * as theme from 'src/theme';

export const Container = styled(SafeAreaView)`
  flex: 1;
`;

export const KeyboardAvoidingView = styled(_KeyboardAvoidingView)`
  flex: 1;
  align-self: center;
  width: 100%;
  max-width: ${theme.MAX_CONTENT_WIDTH}px;
`;
