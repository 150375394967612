import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import { Image as NativeImage } from 'react-native';
import styled, { css } from 'styled-components/native';

export const Container = styled(_Container)`
  flex: 1;
`;

export const Box = styled(_Container)<{ $last?: boolean }>`
  flex: 3;

  ${props =>
    props.$last &&
    css`
      flex: 1;
      padding: ${spacings.s}px ${spacings.xs}px ${spacings.xs}px;
      display: flex;
      flex-direction: column;
    `};
`;

export const Image = styled(NativeImage)`
  width: 100%;
  height: 100%;
`;

export const Title = styled(Typography)`
  margin-bottom: ${spacings.xxs}px;
`;
