import { VehicleChargingBehavior } from '@hiven-energy/hiven-client';
import { Button, Radio, Typography } from '@hiven-energy/hiven-ui';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import { useAnalytics } from 'src/services/analytics';

import * as styled from './styles';

interface Props {
  locationSupported: boolean;
  value: VehicleChargingBehavior;
  saving?: boolean;
  onConfirm: (value: VehicleChargingBehavior) => void;
}

const ChargingBehavior: FC<Props> = ({ locationSupported, value, saving, onConfirm }) => {
  const intl = useIntl();
  const a11y = useA11y();

  const { trackButtonClick } = useAnalytics();
  const [chargingBehavior, setChargingBehavior] = useState(value);

  useEffect(() => {
    setChargingBehavior(value);
  }, [value]);

  const handleChange = (value: VehicleChargingBehavior) => {
    setChargingBehavior(value);
  };

  const handleSubmit = () => {
    trackButtonClick('common.continue', 'VehiclePreferences.chargingBehavior');
    onConfirm(chargingBehavior);
  };

  return (
    <styled.Container>
      <styled.Content>
        <Typography variant="h3">
          <FormattedMessage id="VehiclePreferences.chargingBehavior.preference.title" />
        </Typography>
        <styled.ToggleWrapper>
          {locationSupported && (
            <Radio
              checked={chargingBehavior === VehicleChargingBehavior.SMART_CHARGE_AT_LOCATION}
              label={intl.formatMessage({ id: 'vehicle.chargingBehavior.SMART_CHARGE_AT_LOCATION' })}
              onChange={() => handleChange(VehicleChargingBehavior.SMART_CHARGE_AT_LOCATION)}
            />
          )}
          <Radio
            checked={chargingBehavior === VehicleChargingBehavior.SMART_CHARGE_ANYWHERE}
            label={intl.formatMessage({ id: 'vehicle.chargingBehavior.SMART_CHARGE_ANYWHERE' })}
            onChange={() => handleChange(VehicleChargingBehavior.SMART_CHARGE_ANYWHERE)}
          />
          <Radio
            checked={chargingBehavior === VehicleChargingBehavior.SMART_CHARGE_OFF}
            label={intl.formatMessage({ id: 'vehicle.chargingBehavior.SMART_CHARGE_OFF' })}
            onChange={() => handleChange(VehicleChargingBehavior.SMART_CHARGE_OFF)}
          />
        </styled.ToggleWrapper>
      </styled.Content>
      <Button
        title={intl.formatMessage({ id: 'common.continue' })}
        testID={a11y.formatLabel('common.continue')}
        loading={saving}
        disabled={saving}
        onPress={handleSubmit}
      />
    </styled.Container>
  );
};

export default React.memo(ChargingBehavior);
