import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import TabBar, { TabOption, TabValue } from 'src/components/TabBar/TabBar';
import { useAnalytics } from 'src/services/analytics';

export enum Tab {
  CHARGING_SETUP = 'CHARGING_SETUP',
  DEVICE_DETAILS = 'DEVICE_DETAILS',
}

interface Props {
  active: Tab;
  onChange: (tab: Tab) => void;
}

const Tabs: FC<Props> = ({ active, onChange }) => {
  const intl = useIntl();
  const { trackButtonClick } = useAnalytics();

  const options: TabOption<Tab>[] = [
    {
      label: intl.formatMessage({ id: 'common.deviceOverview.tabs.CHARGING_SETUP' }),
      value: Tab.CHARGING_SETUP,
    },
    {
      label: intl.formatMessage({ id: 'common.deviceOverview.tabs.DEVICE_DETAILS' }),
      value: Tab.DEVICE_DETAILS,
    },
  ];

  const handleChange = (tab: TabValue) => {
    trackButtonClick(`common.deviceOverview.tabs.${tab}`);
    onChange(tab as Tab);
  };

  return <TabBar active={active} options={options} onChange={handleChange} />;
};

export default React.memo(Tabs);
