import { FetchStatus } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import { Analytics, useAnalytics } from 'src/services/analytics/index';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';

export const useTrackSuccessUpdate = (
  {
    isInitialLoading,
    isSuccess,
    fetchStatus,
  }: {
    isInitialLoading: boolean;
    isSuccess: boolean;
    fetchStatus: FetchStatus;
  },
  event: MixpanelEvents,
) => {
  const trackEventRef = useRef(false);

  const isSuccessRef = useRefValue(isSuccess);
  const isInitialLoadingRef = useRefValue(isInitialLoading);
  const { trackUserWasWaitingForSchedule } = useAnalytics();

  useEffect(() => {
    // initiate on start to sum time for initial load.
    Analytics.timeEvent(event);
  }, []);

  useEffect(() => {
    if (trackEventRef.current && fetchStatus === 'idle' && isSuccessRef.current) {
      trackUserWasWaitingForSchedule(true);
      trackEventRef.current = false;
    }
  }, [fetchStatus]);

  useEffect(() => {
    // initiate tracking after initial load to skip tracking of initial load.
    if (!isInitialLoading && !trackEventRef.current) {
      trackEventRef.current = true;
    }
  }, [isInitialLoading]);

  useEffect(() => {
    // initiate tracking when tracking is already done but current update is not success and set time.
    if (!isSuccess && !trackEventRef.current && !isInitialLoadingRef.current) {
      trackEventRef.current = true;
      Analytics.timeEvent(event);
    }
  }, [isSuccess]);

  useEffect(() => {
    return () => {
      if (trackEventRef.current) {
        trackUserWasWaitingForSchedule(isSuccessRef.current);
      }
    };
  }, []);
};

const useRefValue = <T>(value: T) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};
