import { Container, ContainerStyle } from '@hiven-energy/hiven-ui';
import clamp from 'lodash/clamp';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { FlatList, Platform } from 'react-native';
import GestureRecognizer from 'react-native-swipe-gestures';

import { useAppTheme } from 'src/app-theme';
import { useScreenDimensions } from 'src/hooks/useScreenDimensions';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';
import * as OnboardingStorage from 'src/storage/onboarding';

import StepComponent from './Step/Step';
import Stepper from './Stepper/Stepper';
import * as styled from './styles';
import { Step } from './types';

type Props = ScreenProps<RouteId.Onboarding>;

const Onboarding: FC<Props> = ({ navigation }) => {
  const listRef = useRef<FlatList>(null);
  const intl = useIntl();
  const [screenWidth] = useScreenDimensions();
  const { trackOnboard } = useAnalytics();

  const appTheme = useAppTheme();

  const [stepIndex, setStepIndex] = useState(0);

  useAnalyticsTimeEvent(MixpanelEvents.USER_ONBOARDING, [stepIndex]);

  useEffect(() => {
    listRef.current?.scrollToIndex({ index: stepIndex });
  }, [stepIndex]);

  const isWeb = Platform.OS === 'web';

  const steps: Step[] = useMemo(
    () => [
      {
        image: appTheme.assets.onboarding.step1,
        title: intl.formatMessage({ id: 'Onboarding.step1.title' }),
        content: intl.formatMessage({ id: 'Onboarding.step1.content' }),
      },
      {
        image: appTheme.assets.onboarding.step2,
        title: intl.formatMessage({ id: 'Onboarding.step2.title' }),
        content: intl.formatMessage({ id: 'Onboarding.step2.content' }),
      },
      {
        image: appTheme.assets.onboarding.step3,
        title: intl.formatMessage({ id: 'Onboarding.step3.title' }),
        content: intl.formatMessage({ id: 'Onboarding.step3.content' }),
      },
    ],
    [],
  );

  const handleFinish = () => {
    OnboardingStorage.setOnboardingComplete();
    trackOnboard(stepIndex, stepIndex === steps.length - 1);
    navigation.navigate(RouteId.GetStarted);
  };

  const handleSwipeLeft = () => {
    setStepIndex(stepIndex => clamp(stepIndex + 1, 0, steps.length - 1));
    trackOnboard(stepIndex, false);
  };

  const handleSwipeRight = () => {
    setStepIndex(stepIndex => clamp(stepIndex - 1, 0, steps.length - 1));
    trackOnboard(stepIndex, false);
  };

  return (
    <styled.Container>
      <Stepper count={steps.length} current={stepIndex} onChange={setStepIndex} onFinish={handleFinish}>
        <GestureRecognizer onSwipeLeft={handleSwipeLeft} onSwipeRight={handleSwipeRight} style={{ flex: 1 }}>
          <FlatList
            ref={listRef}
            data={steps}
            renderItem={({ item: { image, title, content } }) => (
              <styled.Item
                style={{
                  ...(isWeb
                    ? {
                        maxWidth: '100%' as unknown as number,
                      }
                    : {
                        width: screenWidth,
                      }),
                }}
              >
                <StepComponent image={image} title={title} content={content} />
              </styled.Item>
            )}
            keyExtractor={item => item.title}
            horizontal
            scrollEnabled={false}
            {...(isWeb && {
              contentContainerStyle: {
                maxWidth: '100%',
              },
              CellRendererComponent: props => (
                <Container
                  {...props}
                  style={[props.style as ContainerStyle, { maxWidth: '100%' as unknown as number }]}
                />
              ),
            })}
          />
        </GestureRecognizer>
      </Stepper>
    </styled.Container>
  );
};

export default Onboarding;
