import { Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Content = styled(Container)`
  gap: ${spacings.xxs}px;
`;

export const ChargingLocation = Container;

export const WarningLabel = styled(Typography).attrs({ fontWeight: 'medium' })`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.stoneGrey};
`;
