import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import { initialPreferences } from 'src/screens/vehicle/constants';
import { Preferences } from 'src/screens/vehicle/types';

import Preference from './Preference/Preference';
import * as styled from './styles';

interface Props {
  deviceName?: string;
  preferences: Preferences;
  onSetPreferencesPress: VoidFunction;
}

const MissingPreferences: FC<Props> = ({
  deviceName = '',
  preferences: { chargingLocations, readyTime, chargeLevels },
  onSetPreferencesPress,
}) => {
  const a11y = useA11y();
  const intl = useIntl();

  return (
    <styled.Container>
      <styled.Title variant="h4">
        <FormattedMessage id="vehicle.Dashboard.missingPreferences.title" />
      </styled.Title>
      <styled.Content>
        <FormattedMessage id="vehicle.Dashboard.missingPreferences.content" />
      </styled.Content>
      <styled.Preferences>
        <Preference
          label={intl.formatMessage({ id: 'vehicle.Dashboard.missingPreferences.preference.chargingLocation' })}
          isSet={chargingLocations.length > 0}
        />
        <Preference
          label={intl.formatMessage({ id: 'vehicle.Dashboard.missingPreferences.preference.readyTime' })}
          isSet={readyTime !== initialPreferences.readyTime}
        />
        <Preference
          label={intl.formatMessage({ id: 'vehicle.Dashboard.missingPreferences.preference.chargeLevels' })}
          isSet={chargeLevels !== initialPreferences.chargeLevels}
        />
      </styled.Preferences>
      <styled.Button
        title={intl.formatMessage({ id: 'vehicle.Dashboard.missingPreferences.setPreferences' })}
        onPress={onSetPreferencesPress}
        accessibilityLabel={a11y.formatLabel('missingPreferences.setPreferences', { name: deviceName })}
        testID={a11y.formatLabel('missingPreferences.setPreferences', { name: deviceName })}
      />
    </styled.Container>
  );
};

export default React.memo(MissingPreferences);
