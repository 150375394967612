import { useNavigationState } from '@react-navigation/native';

import { INITIAL_TARGET } from './constants';
import { NavigationState, RouteWithParams, UseConnectSuccessTargetResult } from './types';
import { getNavigationTarget } from './utils';

const SESSION_STORAGE_KEY = 'connectSuccessTarget';

export const useConnectSuccessTarget = (): UseConnectSuccessTargetResult => {
  const state = useNavigationState(state => state) as NavigationState;

  const getTarget = () => getNavigationTarget(state) ?? getSessionStorageTarget() ?? INITIAL_TARGET;

  const getSessionStorageTarget = (): RouteWithParams | undefined => {
    const data = sessionStorage.getItem(SESSION_STORAGE_KEY);
    try {
      return data ? JSON.parse(data) : undefined;
    } catch (_error) {
      return undefined;
    }
  };

  const saveTarget = () => {
    const target = getNavigationTarget(state) ?? INITIAL_TARGET;
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(target));
  };

  return { getTarget, saveTarget };
};
