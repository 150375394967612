import { Time } from '@hiven-energy/hiven-client';
import { Button, ClockIcon, TimePicker } from '@hiven-energy/hiven-ui';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BottomSheetRef } from 'react-native-sheet';

import { useA11y } from 'src/a11y';
import BaseView from 'src/components/BaseView/BaseView';
import { BottomSheet } from 'src/components/BottomSheet/BottomSheet';
import { ScreenScrollView } from 'src/components/ScreenScrollView/ScreenScrollView';
import * as styled from 'src/containers/ready-time/Preference/styles';
import { ReadyTimeByType } from 'src/containers/ready-time/types';
import { defaultReadyTime } from 'src/containers/ready-time/utils';
import { useWeekDays, WeekDay } from 'src/hooks/useWeekDays';
import { colors } from 'src/theme';
import { parseTime, prepareTime, prepareTimeToDisplay } from 'src/utils/time';

interface Props {
  initialTime: ReadyTimeByType;
  saving?: boolean;
  onConfirm: (newReadyTime: ReadyTimeByType) => void;
}

interface TimePickerTime {
  hour: number;
  minute: number;
}

const defaultParsedTime = parseTime(defaultReadyTime);

export const ReadyTime: FC<Props> = ({ initialTime, saving, onConfirm }) => {
  const intl = useIntl();
  const a11y = useA11y();

  const weekDays = useWeekDays();
  const sheetRef = useRef<BottomSheetRef>(null);

  const initialAllDayTime = useMemo(() => {
    const [initialHour, initialMinute] =
      initialTime.type === 'all_day' ? parseTime(initialTime.time) : defaultParsedTime;
    return {
      hour: initialHour,
      minute: initialMinute,
    };
  }, [initialTime]);

  const initialWeekDayTime = useMemo(() => {
    return weekDays.reduce<Record<WeekDay, TimePickerTime>>((acc, [weekDayType]) => {
      const [initialHour, initialMinute] =
        initialTime.type !== 'all_day' ? parseTime(initialTime.time[weekDayType]) : defaultParsedTime;
      acc[weekDayType] = {
        hour: initialHour,
        minute: initialMinute,
      };
      return acc;
    }, {} as unknown as Record<WeekDay, TimePickerTime>);
  }, [initialTime]);

  const [allDayToggle, setAllDayToggle] = useState(initialTime.type === 'all_day');
  const [allDayTime, setAllDayTime] = useState<TimePickerTime>(initialAllDayTime);
  const [weekDayTime, setWeekDayTime] = useState(initialWeekDayTime);
  const [currentWeekDay, setCurrentWeekDay] = useState<{
    weekDay: [WeekDay, string];
    time: TimePickerTime;
  } | null>(null);

  useEffect(() => {
    setAllDayTime(initialAllDayTime);
  }, [initialAllDayTime]);

  useEffect(() => {
    setWeekDayTime(initialWeekDayTime);
  }, [initialWeekDayTime]);

  useEffect(() => {
    setAllDayToggle(initialTime.type === 'all_day');
  }, [initialTime.type]);

  const handleAllDayTogglePress = () => {
    setAllDayToggle(flag => !flag);
  };

  const handlePressContinue = () => {
    const readyTime: ReadyTimeByType = allDayToggle
      ? {
          type: 'all_day',
          time: prepareTime(allDayTime.hour, allDayTime.minute),
        }
      : {
          type: 'days_of_week',
          time: weekDays.reduce<Record<WeekDay, Time>>((acc, [weekDayType]) => {
            acc[weekDayType] = prepareTime(weekDayTime[weekDayType].hour, weekDayTime[weekDayType].minute);
            return acc;
          }, {} as unknown as Record<WeekDay, Time>),
        };

    onConfirm(readyTime);
  };

  const handleWeekDayHourChange = (hour: number) =>
    setCurrentWeekDay(prevState => {
      if (prevState) {
        return { ...prevState, time: { hour, minute: prevState.time.minute } };
      }
      return null;
    });

  const handleWeekDayMinuteChange = (minute: number) =>
    setCurrentWeekDay(prevState => {
      if (prevState) {
        return { ...prevState, time: { hour: prevState.time.hour, minute } };
      }
      return null;
    });

  const handleSetTime = () => {
    if (currentWeekDay) {
      setWeekDayTime(prevState => ({
        ...prevState,
        [currentWeekDay.weekDay[0]]: currentWeekDay.time,
      }));
    }
    sheetRef?.current?.hide();
  };

  return (
    <BaseView safeAreaEdges={['bottom']}>
      <ScreenScrollView>
        <styled.Description fontWeight="medium">
          <FormattedMessage id="common.preferences.readyTime.Description" />
        </styled.Description>
        <styled.AllDaySwitchContainer>
          <ClockIcon color={colors.hintGrey} />
          <styled.AllDayToggle
            checked={allDayToggle}
            onChange={handleAllDayTogglePress}
            label={intl.formatMessage({ id: 'common.preferences.readyTime.toggleLabel' })}
          />
        </styled.AllDaySwitchContainer>
        {allDayToggle ? (
          <styled.TimePickerContainer>
            <TimePicker
              hour={allDayTime.hour}
              minute={allDayTime.minute}
              onHourChange={hour => setAllDayTime(prevState => ({ hour, minute: prevState.minute }))}
              onMinuteChange={minute => setAllDayTime(prevState => ({ hour: prevState.hour, minute }))}
            />
          </styled.TimePickerContainer>
        ) : (
          <Days
            time={weekDayTime}
            onWeekDayPress={weekDay => {
              setCurrentWeekDay({ weekDay, time: weekDayTime[weekDay[0]] });
              sheetRef?.current?.show();
            }}
          />
        )}
      </ScreenScrollView>
      <styled.ContinueButtonContainer>
        <Button
          title={intl.formatMessage({ id: 'common.continue' })}
          testID={a11y.formatLabel('common.continue')}
          loading={saving}
          disabled={saving}
          onPress={handlePressContinue}
        />
      </styled.ContinueButtonContainer>
      <BottomSheet height={408} ref={sheetRef} showDragIcon={false} draggable={false}>
        {currentWeekDay && (
          <>
            <styled.OverlayDescriptionText fontWeight="medium">
              {intl.formatMessage(
                { id: 'common.preferences.readyTime.overlay.title' },
                { day: currentWeekDay.weekDay[1] },
              )}
            </styled.OverlayDescriptionText>
            <styled.TimePickerContainer>
              <TimePicker
                animateOnStart={false}
                hour={currentWeekDay.time.hour}
                minute={currentWeekDay.time.minute}
                onHourChange={handleWeekDayHourChange}
                onMinuteChange={handleWeekDayMinuteChange}
              />
            </styled.TimePickerContainer>
            <styled.SetTimeButtonContainer>
              <Button
                title={intl.formatMessage({ id: 'common.preferences.readyTime.overlay.setTime' })}
                onPress={handleSetTime}
              />
            </styled.SetTimeButtonContainer>
          </>
        )}
      </BottomSheet>
    </BaseView>
  );
};

interface DaysProps {
  time: Record<WeekDay, TimePickerTime>;
  onWeekDayPress: (weekDayType: [WeekDay, string]) => void;
}

const Days: FC<DaysProps> = ({ time, onWeekDayPress }) => {
  const weekDays = useWeekDays();

  return (
    <styled.DaysContainer>
      {weekDays.map(([weekDayType, weekDay]) => (
        <styled.DayItemContainer key={weekDayType}>
          <styled.DayItem onPress={() => onWeekDayPress([weekDayType, weekDay])}>
            <styled.DayTextLine>
              <styled.DayText fontWeight="medium">{weekDay}</styled.DayText>
              <styled.DayText fontWeight="medium">
                {prepareTimeToDisplay(time[weekDayType].hour, time[weekDayType].minute)}
              </styled.DayText>
            </styled.DayTextLine>
          </styled.DayItem>
        </styled.DayItemContainer>
      ))}
    </styled.DaysContainer>
  );
};
