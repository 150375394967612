import { UserLocation } from '@hiven-energy/hiven-client';
import { ChevronRightIcon, IconButton } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';

import * as styled from './styles';

interface Props {
  location: UserLocation;
  onEditPress: VoidFunction;
}

const Location: FC<Props> = ({ location, onEditPress }) => (
  <styled.Container>
    <styled.AddressWrapper>
      <styled.Name>{location.name}</styled.Name>
      <styled.AddressLine>{location.streetAddress}</styled.AddressLine>
      <styled.AddressLine>
        {location.postalCode} {location.city}
      </styled.AddressLine>
    </styled.AddressWrapper>
    <IconButton type="plain" icon={ChevronRightIcon} onPress={onEditPress} />
  </styled.Container>
);

export default React.memo(Location);
