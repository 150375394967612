import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import BaseView from 'src/components/BaseView/BaseView';
import ChargeLevels from 'src/containers/charge-levels/Overview/ChargeLevels';
import ReadyTime from 'src/containers/ready-time/Overview/ReadyTime';
import { useVehicles } from 'src/queries/sdk';

import { chargeLevelUnit } from '../../constants';
import { Preferences, PreferenceType, SetupType } from '../../types';

import BatteryCapacity from './BatteryCapacity/BatteryCapacity';
import ChargingBehavior from './ChargingBehavior/ChargingBehavior';
import ChargingLocation from './ChargingLocation/ChargingLocation';
import DevicePairing from './DevicePairing/DevicePairing';
import * as styled from './styles';

interface Props {
  preferences: Preferences;
  batteryCapacityInputRequired: boolean;
  saveAllowed: boolean;
  saving: boolean;
  onSavePress: (clickText: string) => void;
  onPreferenceEditPress: (preferenceType: PreferenceType) => void;
  onTariffInfoEditPress: VoidFunction;
}

const Overview: FC<Props> = ({
  preferences,
  batteryCapacityInputRequired,
  saveAllowed,
  saving,
  onSavePress,
  onPreferenceEditPress,
  onTariffInfoEditPress,
}) => {
  const intl = useIntl();
  const a11y = useA11y();

  const vehiclesQuery = useVehicles();

  const handleSave = () => {
    onSavePress('common.save');
  };

  return (
    <BaseView>
      <styled.Scroll contentContainerStyle={{ gap: 32, paddingTop: 24 }}>
        <ChargingLocation
          chargingLocation={preferences.chargingLocation}
          onEditPress={() => onPreferenceEditPress(PreferenceType.YOUR_CHARGING_LOCATIONS)}
          analyticsPlace="ChargerPreferences.chargingLocation"
          onTariffInfoEditPress={onTariffInfoEditPress}
        />
        {!!vehiclesQuery.data?.length && (
          <DevicePairing
            vehicleId={preferences.associatedVehicleId}
            onEditPress={() => onPreferenceEditPress(PreferenceType.DEVICE_PAIRING)}
          />
        )}
        {preferences.setupType === SetupType.ASSOCIATED && batteryCapacityInputRequired && (
          <BatteryCapacity
            capacity={preferences.batteryCapacity}
            onEditPress={() => onPreferenceEditPress(PreferenceType.BATTERY_CAPACITY)}
          />
        )}
        <ReadyTime
          timeByType={preferences.readyTime}
          onEditPress={() => onPreferenceEditPress(PreferenceType.READY_TIME)}
        />
        <ChargeLevels
          minimum={preferences.chargeLevels.minimum}
          maximum={preferences.chargeLevels.maximum}
          unit={chargeLevelUnit[preferences.setupType]}
          onEditPress={() => onPreferenceEditPress(PreferenceType.CHARGE_LEVELS)}
          analyticsPlace="ChargerPreferences.chargeLevels"
        />
        <ChargingBehavior
          value={preferences.chargingBehavior}
          onEditPress={() => onPreferenceEditPress(PreferenceType.CHARGING_BEHAVIOR)}
        />
      </styled.Scroll>
      <styled.SaveButton
        title={intl.formatMessage({ id: 'common.save' })}
        loading={saving}
        disabled={!saveAllowed}
        onPress={handleSave}
        testID={a11y.formatLabel('common.save')}
      />
    </BaseView>
  );
};

export default React.memo(Overview);
