import { IconProps } from '@hiven-energy/hiven-ui';
import React, { FC, PropsWithChildren } from 'react';
import { View } from 'react-native';

import { colors } from 'src/theme';

import * as styled from './styles';

interface LabelValueProps {
  label: string;
  value?: string;
  icon?: FC<IconProps>;
}

export const LabelValue: FC<PropsWithChildren<LabelValueProps>> = ({ label, value, icon: Icon, children }) => (
  <styled.Container>
    <styled.Content>
      {Icon && <Icon size={24} color={colors.sunshineYellow} />}
      <styled.Label lineHeight="normal" fontWeight="medium" $withIcon={!!Icon}>
        {label}
      </styled.Label>
      {value && (
        <styled.Value variant="h4" lineHeight="normal">
          {value}
        </styled.Value>
      )}
    </styled.Content>
    {children && <styled.Container $withLeftMargin>{children}</styled.Container>}
  </styled.Container>
);

interface LabelValueLineProps {
  label: string;
  value: string;
}

export const LabelValueLine: FC<LabelValueLineProps> = ({ label, value }) => (
  <styled.Content>
    <styled.LabelInLine lineHeight="normal">{label}</styled.LabelInLine>
    {value && <styled.Value variant="h4">{value}</styled.Value>}
  </styled.Content>
);

interface LabelValueRowProps {
  label: string;
  value?: string;
}

export const LabelValueRow: FC<LabelValueRowProps> = ({ label, value }) => (
  <View>
    {value && <styled.ValueInRow variant="h3">{value}</styled.ValueInRow>}
    <styled.LabelInRow>{label}</styled.LabelInRow>
  </View>
);
