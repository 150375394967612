import { Container as _Container } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const Container = styled(_Container)<{ interactive: boolean }>`
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: -1;
  position: relative;
  pointer-events: ${p => (p.interactive ? 'auto' : 'none')};
`;
