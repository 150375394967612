import { TimelineChart } from '@hiven-energy/hiven-charts';
import { ChargingSchedule } from '@hiven-energy/hiven-client';
import { useIsFocused } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import Complete from './Complete/Complete';
import { categoryToBarStyle, UPCOMING_HOURS } from './constants';
import Placeholder from './Placeholder/Placeholder';
import * as styled from './styles';
import { Datum } from './types';
import { getScheduleDomain, parseSchedule } from './utils';

interface Props {
  chargingSchedule?: ChargingSchedule;
  disabled?: boolean;
  complete?: boolean;
}

const TimeSchedule: FC<Props> = ({ chargingSchedule, disabled = false, complete = false }) => {
  /* useIsFocused is needed to enforce chart rerender due to issue with ForeignObject
  reference: https://github.com/software-mansion/react-native-svg/issues/1357 */
  useIsFocused();

  const domain = useMemo(getScheduleDomain, []);

  const readyDate = useMemo(
    () => chargingSchedule && new Date(chargingSchedule.readyTime),
    [chargingSchedule?.readyTime],
  );

  const data = useMemo(
    () => (chargingSchedule ? parseSchedule(chargingSchedule, domain) : []),
    [chargingSchedule, domain],
  );

  const overtimeDatum = useMemo(() => data.find(datum => datum.category === 'overtime'), [data]);

  const timeLabelFormatter = useCallback(
    (date: Date) => {
      const timeZone = chargingSchedule?.timeZone === 'Z' ? undefined : chargingSchedule?.timeZone;
      return dayjs(date).tz(timeZone).format('H:mm');
    },
    [chargingSchedule?.timeZone],
  );

  const showChart = !!data.length || disabled;
  const showComplete = complete && !showChart;
  const showPlaceholder = !showChart && !showComplete;
  const chartWrapperTestId = getChartWrapperTestId(data, disabled);

  return (
    <styled.Container>
      <styled.Header>
        <styled.Title variant="h3">
          <FormattedMessage id="timeSchedule.title" />
        </styled.Title>
        {showChart && (
          <styled.Period>
            {disabled ? '--' : <FormattedMessage id="timeSchedule.period" values={{ hours: UPCOMING_HOURS }} />}
          </styled.Period>
        )}
      </styled.Header>
      <styled.Content>
        {disabled && (
          <styled.Hint variant="hint">
            <FormattedMessage id="timeSchedule.disabled.hint" />
          </styled.Hint>
        )}
        {showChart && (
          <styled.ChartWrapper testID={chartWrapperTestId} accessibilityLabel={chartWrapperTestId}>
            <TimelineChart.Chart
              data={data}
              domain={domain}
              target={readyDate}
              variant={disabled ? TimelineChart.Variant.DISABLED : TimelineChart.Variant.DEFAULT}
              timeLabelFormatter={timeLabelFormatter}
              barStyleGetter={barStyleGetter}
            />
          </styled.ChartWrapper>
        )}
        {showComplete && <Complete />}
        {showPlaceholder && <Placeholder />}
        {overtimeDatum && (
          <styled.OvertimeChargingHint>
            <FormattedMessage
              id="timeSchedule.overtimeCharging.hint"
              values={{ time: timeLabelFormatter(new Date(overtimeDatum.end)) }}
            />
          </styled.OvertimeChargingHint>
        )}
      </styled.Content>
    </styled.Container>
  );
};

const barStyleGetter: TimelineChart.BarStyleGetter<TimelineChart.Datum> = datum => {
  const { category } = datum as Datum;
  return categoryToBarStyle[category];
};

const getChartWrapperTestId = (data: Datum[], disabled: boolean) =>
  disabled ? 'TimeSchedule-Chart-disabled' : `TimeSchedule-Chart-data-${JSON.stringify(data)}`;

export default React.memo(TimeSchedule);
