import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import { Platform } from 'react-native';
import { EdgeInsets } from 'react-native-safe-area-context';
import styled, { css } from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)<{
  maxHeight: number;
}>`
  align-self: stretch;
  position: relative;
  ${p =>
    Platform.OS === 'web' &&
    css`
      overflow: hidden;
      max-height: ${p.maxHeight}px;
    `}
`;

export const StateContent = styled(_Container)<{
  insets: EdgeInsets;
  backgroundColor: string;
}>`
  padding-top: ${p => p.insets.top + spacings.xs}px;
  background-color: ${p => p.backgroundColor};
`;

export const RoundedBottom = styled(_Container)<{
  backgroundColor: string;
}>`
  background-color: ${p => p.backgroundColor};
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  align-self: stretch;
  height: ${spacings.xs}px;
`;

export const Label = styled(Typography)`
  text-align: center;
  color: ${colors.white};
`;

export const Content = styled(_Container)`
  padding-right: ${spacings.xs}px;
  padding-left: ${spacings.xs}px;
`;

export const Status = styled(Typography).attrs({ variant: 'hint' })`
  margin-top: ${spacings.xxxxs}px;
  color: ${colors.disabledGrey};
  align-self: center;
`;

export const StateOfCharge = styled(Typography)`
  margin-top: ${spacings.xs}px;
  line-height: 50px;
  color: ${colors.white};
  align-self: center;
`;

export const StatusRow = styled(_Container)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${spacings.xs}px;
  margin-bottom: ${spacings.xxxs}px;
`;

export const IconLabelWrapper = styled(_Container)`
  flex-direction: row;
`;

export const StatusLabel = styled(Typography).attrs({ variant: 'h4' })`
  margin-left: ${spacings.xxxxs}px;
  color: ${colors.white};
`;

export const TargetLabel = styled(Typography)`
  color: ${colors.pidgeonGray};
`;
