import { Button, spacings, Typography } from '@hiven-energy/hiven-ui';
import { ScrollView } from 'react-native';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Description = styled(Typography)`
  color: ${colors.deepNavy};
`;

export const Scroll = styled(ScrollView)`
  margin-bottom: ${spacings.xs}px;
  padding: 0 ${spacings.xs}px;
`;

export const SaveButton = styled(Button)`
  margin: 0 ${spacings.s}px ${spacings.s}px ${spacings.s}px;
`;
