import { TariffInfo as TariffInfoType } from '@hiven-energy/hiven-client';
import { EditIcon, IconButton } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import Property from 'src/containers/device-overview/Property/Property';

import * as styled from './styles';

interface Props {
  tariffInfo?: TariffInfoType;
  onEditPress: VoidFunction;
}

const TariffInfo: FC<Props> = ({ tariffInfo, onEditPress }) => {
  const intl = useIntl();

  return (
    <styled.Container>
      <styled.Content>
        <Property
          name={intl.formatMessage({ id: 'common.deviceOverview.chargingLocations.tariff.meterId' })}
          value={tariffInfo?.meterId}
          mt={0}
        />
        <Property
          name={intl.formatMessage({ id: 'common.deviceOverview.chargingLocations.tariff.type' })}
          value={tariffInfo?.tariff}
        />
        <Property
          name={intl.formatMessage({ id: 'common.deviceOverview.chargingLocations.tariff.utilityProvider' })}
          value={tariffInfo?.electricalUtilityProvider}
        />
      </styled.Content>
      <IconButton type="plain" icon={EditIcon} onPress={onEditPress} />
    </styled.Container>
  );
};

export default React.memo(TariffInfo);
