import { BatteryHalfIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import PreferenceOverview from 'src/components/PreferenceOverview/PreferenceOverview';
import { AnalyticPlace } from 'src/services/analytics/types';

import { ChargeLevelUnit } from '../types';

import * as styled from './styles';

enum ChargeLevelType {
  MINIMUM,
  MAXIMUM,
}

interface Props {
  minimum: number;
  maximum: number;
  unit: ChargeLevelUnit;
  onEditPress: VoidFunction;
  analyticsPlace: AnalyticPlace;
}

const ChargeLevels: FC<Props> = ({ minimum, maximum, unit, onEditPress, analyticsPlace }) => {
  const intl = useIntl();

  return (
    <PreferenceOverview
      title={intl.formatMessage({ id: 'common.preferences.chargeLevels.overview.title' })}
      icon={BatteryHalfIcon}
      onEditPress={onEditPress}
      analyticsPlace={analyticsPlace}
    >
      <styled.Content>
        <ChargeLevel type={ChargeLevelType.MINIMUM} value={minimum} unit={unit} />
        <ChargeLevel type={ChargeLevelType.MAXIMUM} value={maximum} unit={unit} />
      </styled.Content>
    </PreferenceOverview>
  );
};

interface ChargeLevelProps {
  type: ChargeLevelType;
  value: number;
  unit: string;
}

const ChargeLevel: FC<ChargeLevelProps> = ({ value, type, unit }) => {
  const intl = useIntl();

  const label = {
    [ChargeLevelType.MINIMUM]: intl.formatMessage({ id: 'common.preferences.chargeLevels.overview.minimum.label' }),
    [ChargeLevelType.MAXIMUM]: intl.formatMessage({ id: 'common.preferences.chargeLevels.overview.maximum.label' }),
  }[type];

  return (
    <styled.ChargeLevel>
      <styled.Value variant="h4">
        {value} {unit}
      </styled.Value>
      <styled.Label>{label}</styled.Label>
    </styled.ChargeLevel>
  );
};

export default React.memo(ChargeLevels);
