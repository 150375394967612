import { Container as _Container, spacings, TouchableOpacityContainer, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import Loader from 'src/components/Loader/Loader';
import { colors } from 'src/theme';

export const ItemContainer = styled(TouchableOpacityContainer)`
  flex: 1;
  flex-direction: row;
  flex-basis: auto;
`;

export const NewItemContainer = styled(_Container)`
  flex: 1;
  flex-direction: row;
  flex-basis: auto;
`;

export const DeviceLoader = styled(Loader)`
  margin: ${spacings.xxs}px;
`;

export const LocationContainer = styled(_Container)`
  flex: 1;
  flex-basis: auto;
`;

export const LocationHeaderText = styled(Typography)`
  color: ${colors.deepNavy};
`;

export const LocationAddressText = styled(Typography)`
  color: ${colors.stoneGrey};
`;

export const ChevronIconContainer = styled(_Container)`
  justify-content: center;
`;

export const BinIconContainer = styled(TouchableOpacityContainer)`
  justify-content: center;
`;

export const LocationList = styled(_Container)`
  gap: ${spacings.xs}px;
`;

export const AddLocationOption = styled(TouchableOpacityContainer)<{ $withTopMargin: boolean }>`
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
  margin-top: ${({ $withTopMargin }) => ($withTopMargin ? `${spacings.xs}px` : '0px')};
`;
export const AddLocationText = styled(Typography)`
  color: ${colors.deepNavy};
`;
