import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';

export const Container = styled(BaseView)`
  padding: ${spacings.s}px;
`;

export const Title = styled(Typography)`
  margin-bottom: ${spacings.xxs}px;
`;

export const LinkContainer = styled(_Container)`
  align-items: flex-end;
  justify-content: flex-start;
  flex: 1;
`;

export const Footer = styled(_Container)`
  flex: 1;
  justify-content: flex-end;
`;

export const SignUpQuestion = styled(_Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${spacings.xs}px;
`;

export const SignUpText = styled(Typography)`
  margin-right: ${spacings.xxxxs}px;
`;
