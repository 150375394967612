import { useEffect, useState } from 'react';

const DEFAULT_RESET_TIME = 3000;

export const usePressCounter = (resetTime = DEFAULT_RESET_TIME) => {
  const [count, setCount] = useState(0);

  const handlePress = () => {
    setCount(c => c + 1);
  };

  const reset = () => {
    setCount(0);
  };

  useEffect(() => {
    const timerId = setTimeout(reset, resetTime);
    return () => {
      clearTimeout(timerId);
    };
  }, [resetTime, count]);

  return {
    count,
    handlePress,
    reset,
  };
};
