import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Time = styled(Typography)`
  color: ${colors.deepNavy};
`;

export const ScheduleType = styled(Typography)`
  color: ${colors.stoneGrey};
`;

export const WeekDaysContainer = styled(_Container)`
  gap: ${spacings.xxs}px;
`;
