import accessibility from './accessibility.json';

export type Accessibility = typeof accessibility;

export type AccessibilityLabelId = keyof Accessibility;

export const useA11y = () => {
  const formatLabel = (id: AccessibilityLabelId, params?: Record<string, string>) => {
    let value = accessibility[id];

    if (!value) {
      return 'Not Found';
    }

    if (params) {
      Object.keys(params).map(param => {
        value = value.replace(new RegExp(`{${param}}`, 'gi'), params[param]);
      });
      return value;
    }

    return value;
  };

  return {
    formatLabel,
  };
};
