import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const Container = styled(_Container)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${spacings.xs}px;
`;

export const ResendCodeQuestion = styled(Typography)`
  margin-right: ${spacings.xxxxs}px;
`;
