import { BatteryFullIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import PreferenceOverview from 'src/components/PreferenceOverview/PreferenceOverview';

import * as styled from './styles';

interface Props {
  capacity: number | undefined;
  onEditPress: VoidFunction;
}

const BatteryCapacity: FC<Props> = ({ capacity, onEditPress }) => {
  const intl = useIntl();

  return (
    <PreferenceOverview
      title={intl.formatMessage({ id: 'ChargerPreferences.vehicleBatteryCapacity.overview.title' })}
      icon={BatteryFullIcon}
      onEditPress={onEditPress}
      analyticsPlace="ChargerPreferences.vehicleBatteryCapacity"
    >
      <styled.Capacity variant="h4">{capacity || '-'} kWh</styled.Capacity>
      <styled.Label>
        {intl.formatMessage({ id: 'ChargerPreferences.vehicleBatteryCapacity.overview.label' })}
      </styled.Label>
    </PreferenceOverview>
  );
};

export default React.memo(BatteryCapacity);
