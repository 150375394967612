import { Spinner, SpinnerProps } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';

import { useAppTheme } from 'src/app-theme';
import * as theme from 'src/theme';

import * as styled from './styles';

const DEFAULT_LOADER_SIZE = 96;

type Props = SpinnerProps & {
  fullScreen?: boolean;
};

const Loader: FC<Props> = ({ fullScreen = false, ...spinnerProps }) => {
  const appTheme = useAppTheme();

  return fullScreen ? (
    <styled.FullScreenContainer backgroundColor={appTheme.main.color}>
      <Spinner size={DEFAULT_LOADER_SIZE} color={theme.colors.white} {...spinnerProps} />
    </styled.FullScreenContainer>
  ) : (
    <styled.Container>
      <Spinner color={theme.colors.white} {...spinnerProps} />
    </styled.Container>
  );
};

export default React.memo(Loader);
