import OutfitBlack from 'src/assets/Outfit/OT-TTF/Outfit-Black.ttf';
import OutfitBold from 'src/assets/Outfit/OT-TTF/Outfit-Bold.ttf';
import OutfitExtraBold from 'src/assets/Outfit/OT-TTF/Outfit-ExtraBold.ttf';
import OutfitExtraLight from 'src/assets/Outfit/OT-TTF/Outfit-ExtraLight.ttf';
import OutfitLight from 'src/assets/Outfit/OT-TTF/Outfit-Light.ttf';
import OutfitMedium from 'src/assets/Outfit/OT-TTF/Outfit-Medium.ttf';
import OutfitRegular from 'src/assets/Outfit/OT-TTF/Outfit-Regular.ttf';
import OutfitSemiBold from 'src/assets/Outfit/OT-TTF/Outfit-SemiBold.ttf';
import OutfitThin from 'src/assets/Outfit/OT-TTF/Outfit-Thin.ttf';

export const fonts = {
  Outfit: OutfitRegular,
  'Outfit-Black': OutfitBlack,
  'Outfit-Bold': OutfitBold,
  'Outfit-ExtraBold': OutfitExtraBold,
  'Outfit-ExtraLight': OutfitExtraLight,
  'Outfit-Light': OutfitLight,
  'Outfit-Medium': OutfitMedium,
  'Outfit-Regular': OutfitRegular,
  'Outfit-SemiBold': OutfitSemiBold,
  'Outfit-Thin': OutfitThin,
};
