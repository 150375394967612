import 'src/polyfills/timezone';

import { ThemeProvider, Theme as UiTheme } from '@hiven-energy/hiven-ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback } from 'react';
import { Platform, View } from 'react-native';
import { ToastProvider } from 'react-native-toast-notifications';

import { AppTheme, AppThemeProvider, hivenAppTheme, uniperSeAppTheme, wallboxAppTheme } from 'src/app-theme';
import { ReactQueryDevtools } from 'src/components/ReactQueryDevtools';
import Toast from 'src/components/Toast/Toast';
import LandingView from 'src/containers/LandingView/LandingView';
import { usePartner } from 'src/hooks/usePartner';
import I18NProvider from 'src/i18n/I18NProvider';
import { NotificationsProvider } from 'src/notifications';
import { configureAuthentication } from 'src/services/session';
import { ModeProvider } from 'src/store/mode';
import { SessionProvider } from 'src/store/session';
import { fonts, hivenUiTheme, uniperSeTheme, wallboxUiTheme } from 'src/theme';
import { Partner } from 'src/types/partner';
import { configureDateLibrary } from 'src/utils/date';
import { ErrorReason, getErrorReason } from 'src/utils/queries';

const QUERY_RETRY_LIMIT = 3;
const TOAST_TOP_OFFSET = 64;
const TOAST_ANIMATION_DELAY = 700;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, response) =>
        getErrorReason(response as Response) !== ErrorReason.NOT_FOUND && failureCount < QUERY_RETRY_LIMIT,
    },
  },
});

// See https://github.com/expo/expo/issues/6536
if (Platform.OS === 'android') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (typeof (Intl as any).__disableRegExpRestore === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (Intl as any).__disableRegExpRestore();
  }
}

// Show the Splash Screen when app is not ready
SplashScreen.preventAutoHideAsync();

configureAuthentication();
configureDateLibrary();

const partnerToUiThemeMap: Record<Partner, UiTheme> = {
  [Partner.HIVEN]: hivenUiTheme,
  [Partner.WALLBOX]: wallboxUiTheme,
  [Partner.UNIPER_SE]: uniperSeTheme,
};

const partnerToAppThemeMap: Record<Partner, AppTheme> = {
  [Partner.HIVEN]: hivenAppTheme,
  [Partner.WALLBOX]: wallboxAppTheme,
  [Partner.UNIPER_SE]: uniperSeAppTheme,
};

const App = () => {
  const [fontsLoaded, fontsError] = useFonts(fonts);

  const { partner } = usePartner();

  const appReady = fontsLoaded || !!fontsError;

  const handleLayoutChange = useCallback(async () => {
    if (appReady) {
      await SplashScreen.hideAsync();
    }
  }, [appReady]);

  if (!appReady) {
    return null;
  }

  return (
    <View onLayout={handleLayoutChange} style={{ flex: 1 }}>
      <AppThemeProvider theme={partnerToAppThemeMap[partner]}>
        <ThemeProvider theme={partnerToUiThemeMap[partner]}>
          <ModeProvider>
            <SessionProvider>
              <I18NProvider>
                {/* @ts-ignore:disable-next-line */}
                <ToastProvider
                  placement="top"
                  animationType="slide-in"
                  offsetTop={TOAST_TOP_OFFSET}
                  animationDuration={TOAST_ANIMATION_DELAY}
                  renderToast={({ type, message }) => <Toast type={type} message={message} />}
                >
                  <QueryClientProvider client={queryClient}>
                    <NotificationsProvider>
                      <LandingView />
                    </NotificationsProvider>
                    <ReactQueryDevtools />
                  </QueryClientProvider>
                </ToastProvider>
                <StatusBar style="light" />
              </I18NProvider>
            </SessionProvider>
          </ModeProvider>
        </ThemeProvider>
      </AppThemeProvider>
    </View>
  );
};

export default App;
