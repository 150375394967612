import random from 'lodash/random';
import range from 'lodash/range';

import { delayedResponse, log } from 'src/utils/demo';

import { PlaceDetails, PlacePrediction, Region } from './types';

export const getPlaceAutocompletePredictions = async (value: string, _jwt: string): Promise<PlacePrediction[]> => {
  const predictions: PlacePrediction[] = range(2).map(index => ({
    placeId: `id-${index}`,
    formattedAddress: value + (Math.random() + 1).toString(36).substring(random(7, 10)),
  }));
  return delayedResponse(value ? predictions : []).then(payload => {
    log('Retrieved place autocomplete predictions', payload);
    return payload;
  });
};

export const getPlaceDetails = async (placeId: string, _jwt: string): Promise<PlaceDetails> => {
  const placeDetails: PlaceDetails = {
    placeId,
    address: {
      city: 'Stockholm',
      postalCode: '111 20',
      countryCode: 'SE',
    },
    formattedAddress: '111 20 Stockholm, Sweden',
    region: {
      latitude: 59.3293,
      longitude: 18.0686,
    },
  };
  return delayedResponse(placeDetails).then(payload => {
    log('Retrieved place details', payload);
    return payload;
  });
};

export const getPlaceRegionPredictions = async (_region: Region, _jwt: string): Promise<PlacePrediction[]> => {
  const prediction: PlacePrediction = {
    placeId: 'place-id',
    formattedAddress: '111 20 Stockholm, Sweden',
  };
  return delayedResponse([prediction]).then(payload => {
    log('Retrieved place region predictions', payload);
    return payload;
  });
};
