import React, { createContext, FC, PropsWithChildren, useContext, useState } from 'react';

import { useAppBackendService } from 'src/services/app-backend';
import { useSession } from 'src/store/session';

import { requestDevicePushToken } from './utils';

interface NotificationsState {
  token: string | undefined;
  isError: boolean;
  requestToken: () => Promise<string | undefined>;
  registerToken: () => Promise<void>;
}

const NotificationsContext = createContext<NotificationsState>({
  token: undefined,
  isError: false,
  requestToken: async () => undefined,
  registerToken: async () => undefined,
});

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => (
  <NotificationsContext.Provider value={useNotificationsProvider()}>{children}</NotificationsContext.Provider>
);

const useNotificationsProvider = (): NotificationsState => {
  const session = useSession();
  const appBackendService = useAppBackendService();

  const [token, setToken] = useState<string>();
  const [isError, setIsError] = useState(false);

  const requestToken = async () => {
    const token = await requestDevicePushToken();
    setToken(token);
    return token;
  };

  const registerToken = async () => {
    const deviceToken = await requestToken();
    const sessionToken = await session.getAuthorizationToken();

    if (!deviceToken) return;
    try {
      await appBackendService.registerDevicePushToken(deviceToken, sessionToken);
      setIsError(false);
    } catch (_error) {
      setIsError(true);
      setToken(undefined);
    }
  };

  return {
    token,
    isError,
    requestToken,
    registerToken,
  };
};

export const useNotifications = () => useContext(NotificationsContext);
