import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export const Container = styled(_Container)`
  margin-top: ${spacings.xxxs}px;
`;

export const ValueWrapper = styled(_Container)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  flex-shrink: 1;
  ${Platform.OS === 'web' &&
  css`
    min-width: 0;
  `}
`;

export const Name = Label;

export const Value = styled(Label).attrs({ fontWeight: 'bold' })`
  margin-right: ${spacings.xxs}px;
`;
