import { Container as _Container, Button, spacings, Typography, TypographyStyle } from '@hiven-energy/hiven-ui';
import { StyleProp } from 'react-native';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Form = styled(_Container)`
  position: relative;
  margin: ${spacings.xxl}px;
`;

export const Unit = styled(Typography)`
  position: absolute;
  top: 20px;
  right: ${spacings.xs}px;
`;

export const Hint = styled(_Container)`
  border-radius: 12px;
  border: 1px dashed ${colors.pidgeonGray};
  padding: ${spacings.xs}px;
`;

export const IconWrapper = styled(_Container)`
  margin-bottom: ${spacings.xxxs}px;
  align-items: center;
`;

export const Title = styled(Typography)`
  margin-bottom: ${spacings.xxxs}px;
  line-height: 24px;
`;

export const Text = styled(Typography)`
  color: ${colors.stoneGrey};
`;

export const SaveButton = styled(Button)`
  margin: 0 ${spacings.xs}px ${spacings.xs}px ${spacings.xs}px;
`;

export const getInputStyles = (): StyleProp<TypographyStyle> => ({
  paddingLeft: spacings.xs,
  paddingRight: 92,
  height: 72,
  fontSize: 24,
  color: colors.deepNavy,
  fontWeight: 'bold',
});
