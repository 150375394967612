import { EditIcon, IconButton, IconProps } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';

import { colors } from 'src/theme';

import * as styled from './styles';

interface Props {
  title: string;
  icon: FC<IconProps>;
  children: React.ReactNode;
  onEditPress?: VoidFunction;
}

const Preference: FC<Props> = ({ title, icon: Icon, children, onEditPress }) => (
  <styled.Container>
    <styled.Header>
      <styled.IconTitleWrapper>
        <Icon size={20} color={colors.hintGrey} />
        <styled.Title>{title}</styled.Title>
      </styled.IconTitleWrapper>
      {onEditPress && <IconButton type="plain" icon={EditIcon} onPress={onEditPress} />}
    </styled.Header>
    <styled.Content>{children}</styled.Content>
  </styled.Container>
);

export default React.memo(Preference);
