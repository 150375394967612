import React, { FC, PropsWithChildren } from 'react';
// import order is important in this file
// possible errors if changed: https://github.com/expo/expo/issues/6536
import 'intl';
import 'intl/locale-data/jsonp/en';
import { IntlProvider } from 'react-intl';

import { EnMessages, FiMessages } from 'src/i18n';
import { useSession } from 'src/store/session';

import { Language } from './types';

const I18NProvider: FC<PropsWithChildren> = ({ children }) => {
  const session = useSession();

  return (
    <IntlProvider locale={session.language} messages={session.language === Language.FI ? FiMessages : EnMessages}>
      {children}
    </IntlProvider>
  );
};

export default I18NProvider;
