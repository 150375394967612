import { Container as _Container, spacings, TouchableOpacityContainer, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)``;

export const Content = styled(_Container)`
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
  border-width: 1px;
  border-color: ${colors.pidgeonGray};
`;

export const Header = styled(_Container)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: ${spacings.xxxs}px;
`;

export const Title = styled(Typography)`
  flex-grow: 1;
  margin-left: ${spacings.xxxs}px;
`;

export const DisabledText = styled(Typography)`
  color: ${colors.stoneGrey};
  margin-bottom: ${spacings.xs}px;
`;

export const EditableContainer = styled(TouchableOpacityContainer)`
  flex: 1;
  flex-basis: auto;
  flex-direction: row;
  gap: ${spacings.xxxs}px;
`;

export const EditableContent = styled(_Container)`
  flex: 1;
`;
