import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: ${spacings.xxxs}px;
  padding: ${spacings.xxs}px;
`;

export const Touchable = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
`;

export const Details = styled(_Container)`
  flex: 1;
  margin-left: ${spacings.xxs}px;
`;

export const Name = styled(Typography)`
  color: ${colors.white};
`;

export const Hint = styled(Typography)`
  color: ${colors.hintGrey};
`;

export const Chargers = styled(_Container)`
  margin-top: ${spacings.xxs}px;
  gap: ${spacings.xxs}px;
`;

export const CheckIconWrapper = styled(_Container)`
  background-color: ${colors.sunshineYellow};
  border-radius: 12px;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin-left: ${spacings.xxs}px;
`;
