import * as Location from 'expo-location';
import { useEffect, useState } from 'react';

import { Region } from 'src/services/maps';

export const useCurrentPosition = () => {
  const [region, setRegion] = useState<Region>();

  const request = async () => {
    const { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') return;
    const location = await Location.getCurrentPositionAsync({});
    setRegion({ latitude: location.coords.latitude, longitude: location.coords.longitude });
  };

  useEffect(() => {
    request();
  }, []);

  return region;
};
