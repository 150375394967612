import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  margin-top: ${spacings.xs}px;
`;

export const Content = styled(_Container)`
  margin-top: ${spacings.xxxs}px;
  border: 1px solid ${colors.pidgeonGray};
  border-radius: 12px;
  padding: ${spacings.xxxs}px ${spacings.xs}px ${spacings.xs}px ${spacings.xs}px;
`;

export const Hint = styled(Typography)`
  margin-top: ${spacings.xs}px;
  color: ${colors.punchRed};
`;

export const Description = styled(Typography)`
  margin-top: ${spacings.xs}px;
`;
