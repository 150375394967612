import { PinIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';

import * as styled from './styles';

interface Props {
  onAddNewLocationPress: VoidFunction;
}

const NoLocations: FC<Props> = ({ onAddNewLocationPress }) => {
  const a11y = useA11y();
  const intl = useIntl();

  return (
    <styled.Container>
      <PinIcon size={40} />
      <styled.Title variant="h4">
        <FormattedMessage id="MyChargingLocations.noLocations.title" />
      </styled.Title>
      <styled.Content>
        <FormattedMessage id="MyChargingLocations.noLocations.content" />
      </styled.Content>
      <styled.Button
        title={intl.formatMessage({ id: 'MyChargingLocations.addNewLocation' })}
        onPress={onAddNewLocationPress}
        accessibilityLabel={a11y.formatLabel('MyChargingLocations.addNewLocation')}
        testID={a11y.formatLabel('MyChargingLocations.addNewLocation')}
      />
    </styled.Container>
  );
};

export default React.memo(NoLocations);
