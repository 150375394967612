import React, { createContext, FC, ReactNode, useContext } from 'react';

import { AppTheme } from './types';

interface ThemeProviderProps {
  theme: AppTheme;
  children: ReactNode;
}

export const AppThemeContext = createContext<AppTheme>({} as AppTheme);

export const AppThemeProvider: FC<ThemeProviderProps> = ({ theme, children }) => (
  <AppThemeContext.Provider value={theme}>{children}</AppThemeContext.Provider>
);

export const useAppTheme = () => useContext(AppThemeContext);
