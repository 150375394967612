import { Link, TouchableOpacityContainer } from '@hiven-energy/hiven-ui';
import { StatusBar } from 'expo-status-bar';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import DebugModal from 'src/containers/DebugModal/DebugModal';
import { usePressCounter } from 'src/hooks/usePressCounter';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useAnalytics } from 'src/services/analytics';
import * as OnboardingStorage from 'src/storage/onboarding';
import { Mode, useMode } from 'src/store/mode';
import { colors } from 'src/theme';

import * as styled from './styles';

const PRESS_COUNT_LIMIT = 10;

type Props = ScreenProps<RouteId.GetStarted>;

const GetStarted: FC<Props> = ({ navigation }) => {
  const intl = useIntl();
  const a11y = useA11y();
  const [_mode, changeMode] = useMode();

  const titlePressCounter = usePressCounter();
  const descriptionPressCounter = usePressCounter();
  const { trackButtonClick } = useAnalytics();

  const [showDebugModal, setShowDebugModal] = useState(false);

  useEffect(() => {
    if (titlePressCounter.count !== PRESS_COUNT_LIMIT) return;
    changeMode(Mode.DEMO);
    titlePressCounter.reset();
  }, [titlePressCounter.count]);

  useEffect(() => {
    if (descriptionPressCounter.count !== PRESS_COUNT_LIMIT) return;
    descriptionPressCounter.reset();
    openDebugModal();
  }, [descriptionPressCounter.count]);

  useEffect(() => {
    const checkIfOnboardingComplete = async () => {
      const complete = await OnboardingStorage.isOnboardingComplete();
      if (!complete) {
        navigation.navigate(RouteId.Onboarding);
      }
    };
    checkIfOnboardingComplete();
  }, []);

  const openDebugModal = () => {
    setShowDebugModal(true);
  };

  const closeDebugModal = () => {
    setShowDebugModal(false);
  };

  const handleGetStartedPress = () => {
    trackButtonClick('GetStarted.button');
    navigation.navigate(RouteId.SignUp);
  };

  const handleSignInPress = () => {
    trackButtonClick('GetStarted.signInLink');
    navigation.navigate(RouteId.SignIn);
  };

  return (
    <styled.Container>
      <DebugModal visible={showDebugModal} onClose={closeDebugModal} />
      <TouchableOpacityContainer
        accessibilityLabel={a11y.formatLabel('GetStarted.header')}
        onPress={titlePressCounter.handlePress}
      >
        <styled.Title variant="h1">
          <FormattedMessage id="GetStarted.header" />
        </styled.Title>
      </TouchableOpacityContainer>
      <TouchableOpacityContainer
        accessibilityLabel={a11y.formatLabel('GetStarted.description')}
        onPress={descriptionPressCounter.handlePress}
      >
        <styled.Content>
          {intl.formatMessage(
            { id: 'GetStarted.description' },
            {
              // Broken typings for react-intl library with RN18
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              sub: (subscript): any => <styled.Subscript>{subscript}</styled.Subscript>,
            },
          )}
        </styled.Content>
      </TouchableOpacityContainer>
      <styled.GetStartedButton
        title={intl.formatMessage({ id: 'GetStarted.button' })}
        type="tertiary"
        onPress={handleGetStartedPress}
        testID={a11y.formatLabel('GetStarted.button')}
      />
      <styled.SignInQuestion>
        <styled.SignInText accessibilityLabel={a11y.formatLabel('GetStarted.signInQn')}>
          <FormattedMessage id="GetStarted.signInQuestion" />
        </styled.SignInText>
        <Link
          onPress={handleSignInPress}
          accessibilityLabel={a11y.formatLabel('GetStarted.signInLink')}
          styles={{ title: { color: colors.white } }}
        >
          <FormattedMessage id="GetStarted.signInLink" />
        </Link>
      </styled.SignInQuestion>
      <StatusBar style="light" />
    </styled.Container>
  );
};

export default GetStarted;
