import { Button as HivenButton, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

export const Container = styled(BaseView)`
  padding: ${spacings.xs}px;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(Typography)`
  color: ${colors.white};
  padding: 0 ${spacings.xs}px;
  margin-top: ${spacings.m}px;
  margin-bottom: ${spacings.l}px;
  text-align: center;
`;

export const Button = styled(HivenButton)`
  width: 100%;
  margin-top: ${spacings.l}px;
  margin-bottom: ${spacings.xs}px;
`;
