import { ContainerStyle, PinIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { PlacePrediction } from 'src/services/maps/types';

import * as styled from './styles';

const LIST_MAX_HEIGHT = 170;

interface Props {
  value: string;
  options: PlacePrediction[];
  onValueChange: (value: string) => void;
  onOptionSelect: (placeId: string) => void;
}

const PlaceAutocomplete: FC<Props> = ({ value, options, onValueChange, onOptionSelect }) => {
  const intl = useIntl();

  const handleChange = async (value: string, selected: boolean) => {
    if (selected) {
      const selectedOption = options.find(option => option.formattedAddress === value);
      if (selectedOption) onOptionSelect(selectedOption.placeId);
    } else {
      onValueChange(value);
    }
  };

  const listStyle: ContainerStyle = { maxHeight: LIST_MAX_HEIGHT };

  return (
    <styled.Autocomplete
      value={value}
      options={options.map(option => ({ value: option.formattedAddress }))}
      placeholder={intl.formatMessage({
        id: 'common.preferences.chargingLocation.preference.autocomplete.placeholder',
      })}
      icon={PinIcon}
      onChange={handleChange}
      styles={{ list: listStyle }}
    />
  );
};

export default React.memo(PlaceAutocomplete);
