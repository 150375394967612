import { createNavigationContainerRef, ParamListBase } from '@react-navigation/native';

import { RouteId, RoutesParamList } from './types';

export const navigationRef = createNavigationContainerRef<ParamListBase>();

export const navigate = (
  name: RouteId extends keyof RoutesParamList ? RouteId : undefined,
  params?: RoutesParamList[keyof RoutesParamList],
) => {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params);
  }
};
