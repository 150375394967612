export const email = 'example@fortum.com';

export const cognitoUser = {
  attributes: {
    email,
    sub: 'someSubId',
  },
  signInUserSession: {
    idToken: {
      jwtToken: 'testJWT',
    },
  },
};
