import { UserLocation } from '@hiven-energy/hiven-client';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';

import Loader from 'src/components/Loader/Loader';
import ChargingLocation from 'src/containers/charging-location/Preference/ChargingLocation';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useSetOrUpdateUserLocation, useSupportedCountries, useUserLocation } from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';
import { Address, Region } from 'src/services/maps/types';
import { ErrorReason } from 'src/utils/queries';

const DEFAULT_REGION = {
  latitude: 59.3293,
  longitude: 18.0686,
};

export interface RoutedProps {
  locationId?: string;
}

type Props = ScreenProps<RouteId.SetChargingLocation>;

const SetChargingLocation: FC<Props> = ({ navigation, route }) => {
  const intl = useIntl();
  const toast = useToast();

  useAnalyticsTimeEvent(MixpanelEvents.USER_LOCATION_SAVED);
  const { trackButtonClick, trackUserLocationSaved } = useAnalytics();

  const {
    params: { locationId },
  } = route;
  const locationQuery = useUserLocation(locationId!, { enabled: !!locationId });

  const [region, setRegion] = useState<UserLocation['coordinates']>(locationQuery.data?.coordinates ?? DEFAULT_REGION);
  const [address, setAddress] = useState<Required<Address> | undefined>(
    locationQuery.data && {
      city: locationQuery.data.city,
      countryCode: locationQuery.data.countryCode,
      postalCode: locationQuery.data.postalCode,
      streetAddress: locationQuery.data.streetAddress,
    },
  );

  const supportedCountriesQuery = useSupportedCountries();

  const locationMutation = useSetOrUpdateUserLocation({
    onSuccess: location => {
      trackUserLocationSaved(location);
      if (locationId) {
        navigation.navigate(RouteId.ChargingLocation, { locationId });
      } else {
        navigation.navigate(RouteId.MyChargingLocations);
      }
    },
    onError: response => {
      if (response.status === ErrorReason.BAD_REQUEST) {
        toast.show(intl.formatMessage({ id: 'SetChargingLocation.update.address.error' }), {
          type: 'danger',
        });
      } else {
        toast.show(intl.formatMessage({ id: 'SetChargingLocation.update.tryAgain.error' }), {
          type: 'danger',
        });
      }
    },
  });

  const { data: supportedCountries } = supportedCountriesQuery;

  const isLocationSupported = useMemo(
    () => (address?.countryCode ? supportedCountries?.includes(address.countryCode) : undefined),
    [address, supportedCountries],
  );

  useEffect(() => {
    if (locationQuery.isError) {
      navigation.navigate(RouteId.ChargingLocation, { locationId: locationId! });
    }
  }, [locationQuery.isError]);

  useEffect(() => {
    if (locationQuery.data && region === DEFAULT_REGION) {
      setRegion(locationQuery.data.coordinates);
      setAddress({
        city: locationQuery.data.city,
        countryCode: locationQuery.data.countryCode,
        postalCode: locationQuery.data.postalCode,
        streetAddress: locationQuery.data.streetAddress,
      });
    }
  }, [locationQuery.data]);

  useEffect(() => {
    if (isLocationSupported !== false) return;
    toast.show(intl.formatMessage({ id: 'SetChargingLocation.country.support.error' }), {
      type: 'danger',
    });
  }, [isLocationSupported]);

  const handleConfirm = (region: Region, address: Required<Address>, name: string) => {
    trackButtonClick('SetChargingLocation.confirmation.submit');
    if (address) {
      locationMutation.mutate({
        id: locationQuery.data?.id,
        payload: {
          tariffInfo: undefined,
          ...locationQuery.data,
          ...address,
          coordinates: region,
          name,
        },
      });
      return;
    }
    toast.show(intl.formatMessage({ id: 'SetChargingLocation.validation.address.error' }), {
      type: 'danger',
    });
  };

  if (locationId && locationQuery.isLoading) {
    return <Loader fullScreen />;
  }

  return (
    <ChargingLocation
      region={region}
      id={locationQuery.data?.id}
      name={locationQuery.data?.name}
      isLoading={locationMutation.isLoading}
      address={address}
      analyticsPlace="SetChargingLocation"
      onConfirm={handleConfirm}
    />
  );
};

export default SetChargingLocation;
