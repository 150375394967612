import {
  ChargerChargingBehavior,
  ChargerPreferences,
  Unit,
  VehicleChargingBehavior,
  VehiclePreferences,
} from '@hiven-energy/hiven-client';

import * as userLocation from './userLocation';

export const vehiclePreferences: VehiclePreferences = {
  chargingBehavior: VehicleChargingBehavior.SMART_CHARGE_ANYWHERE,
  readyTime: '14:30',
  targetSoC: {
    unit: Unit.PERCENT,
    value: 100,
  },
  minimalSoC: {
    unit: Unit.PERCENT,
    value: 0,
  },
  chargingLocationIds: [userLocation.USER_LOCATION_ID],
  defaultSpotPriceAreaId: userLocation.userLocation.spotPriceAreaId,
};

export const chargerPreferences: ChargerPreferences = {
  chargingBehavior: ChargerChargingBehavior.SMART_CHARGE_ON,
  targetSoC: {
    value: 100,
    unit: Unit.PERCENT,
  },
  minimalSoC: {
    value: 20,
    unit: Unit.PERCENT,
  },
  readyTime: '20:00',
  locationId: userLocation.USER_LOCATION_ID,
};
