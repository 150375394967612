import { ReloadIcon, TouchableOpacityContainer, TouchableOpacityContainerProps } from '@hiven-energy/hiven-ui';
import React, { FC, useEffect, useState } from 'react';
import { Animated } from 'react-native';

import { useAnimatedValue } from 'src/hooks/useAnimatedValue';
import { colors } from 'src/theme';

interface OwnProps {
  loading?: boolean;
  size?: number;
  color?: string;
}

type Props = OwnProps & TouchableOpacityContainerProps;

const RefreshButton: FC<Props> = ({ loading, size, color = colors.stoneGrey, ...containerProps }) => {
  const value = useAnimatedValue();
  const [isRotating, setIsRotating] = useState(false);

  useEffect(() => {
    if (!loading || isRotating) return;
    setIsRotating(true);
    Animated.timing(value, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start(() => {
      value.setValue(0);
      setIsRotating(false);
    });
  }, [loading, isRotating, value]);

  const rotation = value.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  return (
    <TouchableOpacityContainer {...containerProps}>
      <Animated.View style={{ transform: [{ rotate: rotation }] }}>
        <ReloadIcon size={size} color={color} />
      </Animated.View>
    </TouchableOpacityContainer>
  );
};

export default React.memo(RefreshButton);
