import { Container } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

const Divider = styled(Container)`
  width: 100%;
  height: 1px;
  background-color: ${colors.disabledGrey};
`;

export default Divider;
