import AsyncStorage from '@react-native-async-storage/async-storage';

const IS_USER_AUTHENTICATED_KEY = '@demo:isUserAuthenticated';
const USER_EMAIL_KEY = '@demo:user:email';

export const isUserAuthenticated = async () => (await AsyncStorage.getItem(IS_USER_AUTHENTICATED_KEY)) === 'true';

export const setUserAuthenticated = (authenticated: boolean) =>
  AsyncStorage.setItem(IS_USER_AUTHENTICATED_KEY, String(authenticated));

export const getUserEmail = () => AsyncStorage.getItem(USER_EMAIL_KEY) || '';

export const setUserEmail = (email: string) => AsyncStorage.setItem(USER_EMAIL_KEY, email);

export const clearStorage = () => AsyncStorage.multiRemove([IS_USER_AUTHENTICATED_KEY, USER_EMAIL_KEY]);
