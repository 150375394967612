import { Button } from '@hiven-energy/hiven-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';
import { z } from 'zod';

import { TextField } from 'src/components/form-fields/TextField';
import { useChangePassword } from 'src/queries/session';
import { useAnalytics } from 'src/services/analytics';
import { CognitoAction } from 'src/services/session';
import * as validators from 'src/utils/validators';

import { getCognitoErrorMessageId } from '../utils';

import * as styled from './styles';
import { ChangePasswordForm } from './types';

const ChangePassword: FC = () => {
  const intl = useIntl();
  const toast = useToast();

  const { trackButtonClick } = useAnalytics();

  const schema = useMemo(
    () =>
      z.object({
        oldPassword: validators.password(intl, false),
        newPassword: validators.password(intl, false),
      }),
    [intl],
  );

  const form = useForm<ChangePasswordForm>({
    mode: 'onBlur',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
    resolver: zodResolver(schema),
  });

  const changePasswordMutation = useChangePassword({
    onSuccess: () => {
      toast.show(intl.formatMessage({ id: 'ChangePassword.success' }), { type: 'success' });
    },
    onError: error => {
      const errorMessageId = getCognitoErrorMessageId(CognitoAction.CHANGE_PASSWORD, error);
      toast.show(<FormattedMessage id={errorMessageId} />, { type: 'danger' });
    },
  });

  const handleChangePasswordConfirm = (form: ChangePasswordForm) => {
    trackButtonClick('ChangePassword.confirm');
    changePasswordMutation.mutate(form);
  };

  return (
    <styled.Container>
      <TextField
        placeholder={intl.formatMessage({ id: 'common.oldPassword' })}
        control={form.control}
        name="oldPassword"
        password
      />
      <TextField
        placeholder={intl.formatMessage({ id: 'common.newPassword' })}
        control={form.control}
        name="newPassword"
        password
      />
      <styled.Footer>
        <Button
          title={intl.formatMessage({ id: 'common.save' })}
          loading={changePasswordMutation.isLoading}
          disabled={changePasswordMutation.isLoading || !form.formState.isValid}
          onPress={form.handleSubmit(handleChangePasswordConfirm)}
        />
      </styled.Footer>
    </styled.Container>
  );
};

export default ChangePassword;
