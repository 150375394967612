import { useEffect } from 'react';

import { Analytics } from 'src/services/analytics/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';

export const useAnalyticsTimeEvent = (event: MixpanelEvents, deps?: unknown[]) => {
  useEffect(() => {
    Analytics.timeEvent(event);
  }, [...(deps ?? [])]);
};
