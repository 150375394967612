import { RouteProp } from '@react-navigation/native';
import React, { FC, useMemo } from 'react';

import Header from 'src/nav/components/Header/Header';
import { RouteHeaderAction, RouteId, RoutesParamList } from 'src/nav/types';
import { useUserLocations } from 'src/queries/sdk';

interface Props {
  route: RouteProp<RoutesParamList, RouteId.ChargingLocation>;
}

const ChargingLocationHeader: FC<Props> = ({ route }) => {
  const userLocationsQuery = useUserLocations();

  const userLocation = useMemo(
    () => userLocationsQuery.data?.find(({ id }) => id === route.params.locationId),
    [userLocationsQuery.data],
  );

  return <Header title={userLocation?.name ?? ''} action={RouteHeaderAction.GO_BACK} route={route} />;
};

export default ChargingLocationHeader;
