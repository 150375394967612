import React, { FC, ReactNode } from 'react';

import { colors } from 'src/theme';

import * as styled from './styles';

interface Props {
  color?: string;
  children: ReactNode;
}

const Hexagon: FC<Props> = ({ color = colors.aqua, children }) => (
  <styled.Container>
    <styled.Inner $color={color}>{children}</styled.Inner>
    <styled.Before $color={color} />
    <styled.After $color={color} />
  </styled.Container>
);

export default Hexagon;
