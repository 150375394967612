import { Region } from 'src/services/maps';

export const convertRegionToLatLng = ({ latitude, longitude }: Region): google.maps.LatLngLiteral => ({
  lat: latitude,
  lng: longitude,
});

export const convertLatLngToRegion = ({ lat, lng }: google.maps.LatLng): Region => ({
  latitude: lat(),
  longitude: lng(),
});
