import { Container as _Container, spacings } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

export const Container = styled(_Container)`
  margin-top: -${spacings.s}px;
  z-index: -1;
`;

export const Legend = styled(_Container)`
  flex-direction: row;
  align-items: center;
`;

export const Circle = styled(_Container)<{ $color: string }>`
  width: 10px;
  height: 10px;
  background-color: ${p => p.$color};
  border-radius: 10px;
  margin-right: ${spacings.xxxs}px;
`;
