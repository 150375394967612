import { Button, Overlay, spacings, Typography } from '@hiven-energy/hiven-ui';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import BaseView from 'src/components/BaseView/BaseView';
import { ScreenScrollView } from 'src/components/ScreenScrollView/ScreenScrollView';
import { useToggle } from 'src/hooks/useToggle';
import { colors } from 'src/theme';

import { ChargeLevelUnit } from '../types';

import Card from './Card/Card';
import * as styled from './styles';

interface Props {
  minimum: number;
  maximum: number;
  unit: ChargeLevelUnit;
  limit: number;
  minimumHintThreshold: number;
  translations: Translations;
  saving?: boolean;
  onConfirm: (minimum: number, maximum: number) => void;
}

interface Translations {
  'minimum.label': string;
  'minimum.description': string;
  'maximum.label': string;
  'maximum.description': string;
}

const ChargeLevels: FC<Props> = props => {
  const intl = useIntl();
  const a11y = useA11y();

  const [minimum, setMinimum] = useState(props.minimum);
  const [maximum, setMaximum] = useState(props.maximum);
  const [showResetOverlay, toggleShowResetOverlay] = useToggle(false);

  useEffect(() => {
    setMinimum(props.minimum);
  }, [props.minimum]);

  useEffect(() => {
    setMaximum(props.maximum);
  }, [props.maximum]);

  const handleResetPress = () => {
    setMinimum(props.minimum);
    setMaximum(props.maximum);
    toggleShowResetOverlay();
  };

  const handleSubmitPress = () => {
    props.onConfirm(minimum, maximum);
  };

  const minimumChargeLevelHint =
    minimum >= props.minimumHintThreshold
      ? intl.formatMessage({ id: 'common.preferences.chargeLevels.preference.minimum.hint' })
      : undefined;

  const areChargeLevelsInvalid = maximum <= minimum;

  const maximumChargeLevelHint = areChargeLevelsInvalid
    ? intl.formatMessage({ id: 'common.preferences.chargeLevels.preference.maximum.hint' })
    : undefined;

  return (
    <BaseView>
      <ScreenScrollView>
        <Typography variant="h3">
          <FormattedMessage id="common.preferences.chargeLevels.preference.title" />
        </Typography>
        <Card
          label={props.translations['minimum.label']}
          description={props.translations['minimum.description']}
          value={minimum}
          unit={props.unit}
          limit={props.limit}
          hint={minimumChargeLevelHint}
          onChange={setMinimum}
        />
        <Card
          label={props.translations['maximum.label']}
          description={props.translations['maximum.description']}
          value={maximum}
          unit={props.unit}
          limit={props.limit}
          hint={maximumChargeLevelHint}
          sliderColor={colors.sunshineYellow}
          onChange={setMaximum}
        />
      </ScreenScrollView>
      <styled.ButtonContainer>
        <Button
          title={intl.formatMessage({ id: 'common.save' })}
          loading={props.saving}
          disabled={areChargeLevelsInvalid || props.saving}
          testID={a11y.formatLabel('common.save')}
          onPress={handleSubmitPress}
        />
        <styled.ButtonGap />
        <Button
          type="secondary"
          title={intl.formatMessage({ id: 'common.preferences.chargeLevels.preference.defaults.button.label' })}
          onPress={toggleShowResetOverlay}
        />
        <Overlay
          visible={showResetOverlay}
          title={intl.formatMessage({ id: 'common.preferences.chargeLevels.preference.defaults.overlay.title' })}
          cancelButtonProps={{
            title: intl.formatMessage({ id: 'common.cancel' }),
            onPress: toggleShowResetOverlay,
          }}
          submitButtonProps={{
            title: intl.formatMessage({ id: 'common.confirm' }),
            onPress: handleResetPress,
          }}
          styles={{
            title: {
              marginBottom: spacings.xs,
            },
          }}
        />
      </styled.ButtonContainer>
    </BaseView>
  );
};

export default React.memo(ChargeLevels);
