import { TimelineChart } from '@hiven-energy/hiven-charts';

import { colors } from 'src/theme';

import { Category } from './types';

export const UPCOMING_HOURS = 24;
export const SCHEDULE_REFETCH_INTERVAL = 60 * 1000; // 1 minute

export const categoryToBarStyle: Record<Category, TimelineChart.BarStyle> = {
  charge: {
    fill: '#FFCB62',
    stroke: colors.sunshineYellow,
  },
  overtime: {
    fill: colors.raspberryPink,
    stroke: colors.raspberryPink,
  },
};
