import { Container as _Container, Button, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { ScreenScrollView } from 'src/components/ScreenScrollView/ScreenScrollView';
import { colors } from 'src/theme';

export const Container = styled(BaseView)`
  background-color: ${colors.white};
`;

export const Title = styled(Typography)`
  color: ${colors.deepNavy};
  font-size: 18px;
  margin-bottom: ${spacings.xs}px;
`;

export const FormContent = styled(ScreenScrollView)`
  padding-left: ${spacings.s}px;
  padding-right: ${spacings.s}px;
`;

export const FormGroup = styled(_Container)`
  flex: 1;
`;

export const SaveButton = styled(Button)`
  margin: ${spacings.xxs}px;
`;
