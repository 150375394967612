import { Link as _Link, Modal as _Modal, Container, ModalProps, spacings } from '@hiven-energy/hiven-ui';
import { FC } from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';

import { MAX_CONTENT_WIDTH } from 'src/theme';

export const Modal = styled<FC<ModalProps & { isWeb: boolean; insetTop: number; overlayBackgroundColor: string }>>(
  _Modal,
).attrs(props => ({
  styles: {
    overlay: {
      paddingTop: props.isWeb ? 30 : props.insetTop + 30,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      backgroundColor: props.overlayBackgroundColor,
    },
    root: {
      ...(props.isWeb && {
        maxWidth: MAX_CONTENT_WIDTH,
        maxHeight: '100%' as unknown as number,
      }),
      borderRadius: 24,
    },
  },
}))``;

export const Content = styled(Container)`
  gap: ${spacings.xs}px;
  margin-bottom: ${spacings.xs}px;
`;

export const Addresses = styled(Container)`
  flex-direction: row;
`;

export const Link = styled(_Link).attrs({
  styles: {
    title: {
      position: 'relative',
      top: Platform.OS === 'web' ? 0 : 2.5,
    },
  },
})``;
