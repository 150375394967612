import { Button } from '@hiven-energy/hiven-ui';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';

import { useA11y } from 'src/a11y';
import TermsModal from 'src/components/TermsModal/TermsModal';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useTermsAndConditions, useUpdateTermsAndConditions } from 'src/queries/session';
import { useAnalytics } from 'src/services/analytics';

import * as styled from './styles';

type Props = ScreenProps<RouteId.Consents>;

const Consents: FC<Props> = ({ navigation }) => {
  const a11y = useA11y();
  const { trackButtonClick } = useAnalytics();
  const intl = useIntl();
  const toast = useToast();

  const [termsConsent, setTermsConsent] = useState(false);
  const [termsModalVisible, setTermsModalVisible] = useState(false);

  useTermsAndConditions({
    onSuccess: termsConsent => {
      setTermsConsent(termsConsent);
    },
  });

  const upateTermsAndConditionsMutation = useUpdateTermsAndConditions({
    onSuccess: () => {
      toast.show(intl.formatMessage({ id: 'Consents.termsAndConditions.success' }), {
        type: 'success',
      });
      navigation.navigate(RouteId.Account);
    },
    onError: error => {
      console.error('Could not update terms and conditions', error);
      toast.show(intl.formatMessage({ id: 'Consents.termsAndConditions.error' }), {
        type: 'danger',
      });
    },
  });

  const toggleTermsConsent = () => {
    trackButtonClick('Consents.termsConsent');
    setTermsConsent(state => !state);
  };

  const toggleTermsModal = () => {
    trackButtonClick('Consents.temsLink');
    setTermsModalVisible(state => !state);
  };

  const handleSave = () => {
    upateTermsAndConditionsMutation.mutate(termsConsent);
  };

  return (
    <styled.Container>
      <styled.Consents>
        <styled.Consent>
          <styled.Checkbox
            label={intl.formatMessage({ id: 'common.termsAndConditions' })}
            checked={termsConsent}
            onPress={toggleTermsConsent}
          />
          <styled.Hint>
            <styled.Text>
              <FormattedMessage id="Consents.termsAndConditions.hint" />
            </styled.Text>
            <styled.Link onPress={toggleTermsModal} accessibilityLabel={a11y.formatLabel('common.termsAndConditions')}>
              <FormattedMessage id="common.here" />
            </styled.Link>
          </styled.Hint>
          {termsModalVisible && <TermsModal onClose={toggleTermsModal} />}
        </styled.Consent>
      </styled.Consents>
      <styled.Footer>
        <Button title={intl.formatMessage({ id: 'common.save' })} onPress={handleSave} />
      </styled.Footer>
    </styled.Container>
  );
};

export default Consents;
