import { spacings, TouchableOpacityContainer, Typography } from '@hiven-energy/hiven-ui';
import { ImageBackground as NativeImageBackground } from 'react-native';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

export const Container = styled(BaseView)`
  padding: ${spacings.xs}px;
`;

export const Group = styled(TouchableOpacityContainer)<{ $isLast: boolean }>`
  border-radius: ${spacings.xxxs}px;
  overflow: hidden;
  height: 170px;
  justify-content: center;
  margin-bottom: ${props => (props.$isLast ? spacings.none : spacings.xs)}px;
`;

export const ImageBackground = styled(NativeImageBackground)`
  flex: 1;
  align-self: flex-end;
  width: 121px;
  height: 100%;
  margin-right: ${spacings.xs}px;
`;

export const Name = styled(Typography)`
  color: ${colors.white};
  padding-left: ${spacings.xs}px;
  position: absolute;
`;
