import { APP_ENV, MIXPANEL_TOKEN } from '@env';
import Constants, { ExecutionEnvironment } from 'expo-constants';

import { AnalyticsManager } from './types';

export const debugAnalytics = APP_ENV !== 'production';

const isExpoGo = Constants.executionEnvironment === ExecutionEnvironment.StoreClient;

export const isAnalyticsEnabled = !!MIXPANEL_TOKEN && !isExpoGo;

export const dummyAnalytics: AnalyticsManager = {
  async loggedIn() {},
  async setUser() {},
  loggedOut() {},
  signUp() {},
  confirmSignUp() {},
  pageView() {},
  pageViewStarted() {},
  registerCharger() {},
  registerWallboxAccount() {},
  deleteAccount() {},
  saveVehiclePreferences() {},
  saveChargerPreferences() {},
  saveTariffInfo() {},
  deleteDevice() {},
  userInteracted() {},
  userOnboard() {},
  vehicleAdded() {},
  retryVehicleConnect() {},
  retryVehicleConnectCancel() {},
  vehicleAssociated() {},
  timeEvent() {},
  userWasWaitingForSchedule() {},
  userLocationSaved() {},
  languageChanged() {},
};
