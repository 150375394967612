import { Address, AddressComponent } from './types';

export const parseAddress = (components: AddressComponent[]): Address => {
  const result: Address = {};
  let streetName: string | undefined;
  let streetNumber: string | undefined;

  for (const { types, short_name: shortName, long_name: longName } of components) {
    if (types.includes('postal_code')) {
      result.postalCode = shortName;
    } else if (types.includes('route')) {
      streetName = longName;
    } else if (types.includes('street_number') || types.includes('premise')) {
      streetNumber = longName;
    } else if (types.includes('country')) {
      result.countryCode = shortName;
      // Note: postal_town represents city in UK and Sweden
    } else if (types.includes('locality') || types.includes('postal_town')) {
      result.city = longName;
    }
  }

  result.streetAddress = streetName && streetNumber ? `${streetName} ${streetNumber}` : streetName || streetNumber;

  return result;
};
