import { Container as _Container, spacings, TouchableOpacityContainer, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors, MAX_CONTENT_WIDTH } from 'src/theme';

export const Container = styled(_Container)`
  height: 88px;
  align-items: center;
  justify-content: center;
  border-top-width: 1px;
  border-top-color: ${colors.pidgeonGray};
`;

export const TabBar = styled(_Container)`
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
  flex-direction: row;
`;

export const Tab = styled(TouchableOpacityContainer)`
  flex: 1;
  align-items: center;
`;

export const TabName = styled(Typography)<{ $color: string }>`
  color: ${props => props.$color};
  margin-top: ${spacings.xxxxs}px;
`;
