import { SpotPriceArea, UserLocation } from '@hiven-energy/hiven-client';

export const USER_LOCATION_ID = 'userLocationId';

export const userLocation: UserLocation = {
  id: USER_LOCATION_ID,
  name: 'Home',
  coordinates: {
    latitude: 50.25,
    longitude: 55.67,
  },
  countryCode: 'PL',
  postalCode: '12-123',
  spotPriceAreaId: SpotPriceArea.PL,
  streetAddress: 'streetAddress',
  city: 'city',
  tariffInfo: {
    tariff: 'tariff',
    meterId: 'meterId',
    electricalUtilityProvider: 'electricalUtilityProvider',
  },
};
