import { Typography } from '@hiven-energy/hiven-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as styled from './styles';

interface Props {
  title: string;
  lines: string[];
}

const Address: React.FC<Props> = ({ title, lines }) => (
  <styled.Address>
    <Typography variant="h3">
      <FormattedMessage id={title} />
    </Typography>
    {lines.map((line, index) => (
      <Typography key={index}>
        <FormattedMessage id={line} />
      </Typography>
    ))}
  </styled.Address>
);

export default Address;
