import { ChargerChargingBehavior, ChargerChargingStatus } from '@hiven-energy/hiven-client';
import {
  BatteryChargingIcon,
  BatteryFullIcon,
  BatteryUnavailableIcon,
  IconProps,
  PlugIcon,
  ProgressVariant,
} from '@hiven-energy/hiven-ui';
import { FC } from 'react';
import { defineMessages } from 'react-intl';

import { ChargeLevelUnit } from 'src/containers/charge-levels/types';
import { defaultReadyTime } from 'src/containers/ready-time/utils';
import { MessageDescriptor } from 'src/i18n';
import { colors } from 'src/theme';

import { ChargeLevels, Preferences, PreferenceType, SetupType } from './types';

export const chargeLevelLimit: Record<SetupType, number> = {
  [SetupType.STANDALONE]: 120,
  [SetupType.ASSOCIATED]: 100,
};

export const chargeLevelUnit: Record<SetupType, ChargeLevelUnit> = {
  [SetupType.STANDALONE]: 'kWh',
  [SetupType.ASSOCIATED]: '%',
};

export const minimumChargeLevelHintThreshold: Record<SetupType, number> = {
  [SetupType.STANDALONE]: 40,
  [SetupType.ASSOCIATED]: 60,
};

export const initialChargeLevels: Record<SetupType, ChargeLevels> = {
  [SetupType.STANDALONE]: {
    minimum: 10,
    maximum: 30,
  },
  [SetupType.ASSOCIATED]: {
    minimum: 15,
    maximum: 80,
  },
};

export const initialPreferences: Preferences = {
  setupType: SetupType.STANDALONE,
  chargingLocation: {
    region: {
      latitude: 59.3293,
      longitude: 18.0686,
    },
    address: undefined,
    name: '',
    tariffInfo: undefined,
  },
  associatedVehicleId: undefined,
  batteryCapacity: undefined,
  readyTime: { type: 'all_day', time: defaultReadyTime },
  chargeLevels: initialChargeLevels[SetupType.STANDALONE],
  chargingBehavior: ChargerChargingBehavior.SMART_CHARGE_ON,
};

export const chargingStatusToStateOfChargeColor: Record<ChargerChargingStatus, string> = {
  [ChargerChargingStatus.AVAILABLE]: colors.pidgeonGray,
  [ChargerChargingStatus.PREPARING]: colors.pidgeonGray,
  [ChargerChargingStatus.CHARGING]: colors.sunshineYellow,
  [ChargerChargingStatus.SUSPENDED_EVSE]: colors.pidgeonGray,
  [ChargerChargingStatus.SUSPENDED_EV]: colors.pidgeonGray,
  [ChargerChargingStatus.FINISHING]: colors.sunshineYellow,
  [ChargerChargingStatus.RESERVED]: colors.pidgeonGray,
  [ChargerChargingStatus.UNAVAILABLE]: colors.pidgeonGray,
  [ChargerChargingStatus.FAULTED]: colors.pidgeonGray,
};

export const chargingStatusToProgressVariant: Record<ChargerChargingStatus, ProgressVariant> = {
  [ChargerChargingStatus.AVAILABLE]: ProgressVariant.STANDARD,
  [ChargerChargingStatus.PREPARING]: ProgressVariant.STANDARD,
  [ChargerChargingStatus.CHARGING]: ProgressVariant.GROWING_VALUE,
  [ChargerChargingStatus.SUSPENDED_EVSE]: ProgressVariant.STANDARD,
  [ChargerChargingStatus.SUSPENDED_EV]: ProgressVariant.STANDARD,
  [ChargerChargingStatus.FINISHING]: ProgressVariant.STANDARD,
  [ChargerChargingStatus.RESERVED]: ProgressVariant.STANDARD,
  [ChargerChargingStatus.UNAVAILABLE]: ProgressVariant.STANDARD,
  [ChargerChargingStatus.FAULTED]: ProgressVariant.STANDARD,
};

export const chargingStatusToMessage = defineMessages<ChargerChargingStatus, MessageDescriptor>({
  [ChargerChargingStatus.AVAILABLE]: { id: 'charger.status.available' },
  [ChargerChargingStatus.PREPARING]: { id: 'charger.status.preparing' },
  [ChargerChargingStatus.CHARGING]: { id: 'charger.status.charging' },
  [ChargerChargingStatus.SUSPENDED_EVSE]: { id: 'charger.status.suspendedEvse' },
  [ChargerChargingStatus.SUSPENDED_EV]: { id: 'charger.status.suspendedEv' },
  [ChargerChargingStatus.FINISHING]: { id: 'charger.status.finishing' },
  [ChargerChargingStatus.RESERVED]: { id: 'charger.status.reserved' },
  [ChargerChargingStatus.UNAVAILABLE]: { id: 'charger.status.unavailable' },
  [ChargerChargingStatus.FAULTED]: { id: 'charger.status.faulted' },
});

export const chargingStatusToIcon: Record<ChargerChargingStatus, FC<IconProps>> = {
  [ChargerChargingStatus.AVAILABLE]: PlugIcon,
  [ChargerChargingStatus.PREPARING]: PlugIcon,
  [ChargerChargingStatus.CHARGING]: BatteryChargingIcon,
  [ChargerChargingStatus.SUSPENDED_EVSE]: PlugIcon,
  [ChargerChargingStatus.SUSPENDED_EV]: PlugIcon,
  [ChargerChargingStatus.FINISHING]: BatteryFullIcon,
  [ChargerChargingStatus.RESERVED]: PlugIcon,
  [ChargerChargingStatus.UNAVAILABLE]: BatteryUnavailableIcon,
  [ChargerChargingStatus.FAULTED]: BatteryUnavailableIcon,
};

export const chargerPreferenceTitles = defineMessages<PreferenceType, MessageDescriptor>({
  [PreferenceType.ADD_CHARGING_LOCATION]: {
    id: 'navigation.screen.ChargerPreferences.AddChargingLocation',
  },
  [PreferenceType.DEVICE_PAIRING]: {
    id: 'navigation.screen.ChargerPreferences.DevicePairing',
  },
  [PreferenceType.BATTERY_CAPACITY]: {
    id: 'navigation.screen.ChargerPreferences.BatteryCapacity',
  },
  [PreferenceType.READY_TIME]: {
    id: 'navigation.screen.ChargerPreferences.ReadyTime',
  },
  [PreferenceType.CHARGE_LEVELS]: {
    id: 'navigation.screen.ChargerPreferences.ChargeLevels',
  },
  [PreferenceType.CHARGING_BEHAVIOR]: {
    id: 'navigation.screen.ChargerPreferences.ChargingBehavior',
  },
  [PreferenceType.TARIFF_INFORMATION]: {
    id: 'navigation.screen.ChargerPreferences.TariffInformation',
  },
  [PreferenceType.YOUR_CHARGING_LOCATIONS]: {
    id: 'navigation.screen.ChargerPreferences.YourChargingLocations',
  },
});
