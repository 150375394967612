import { Button, spacings } from '@hiven-energy/hiven-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { z } from 'zod';

import { TextField } from 'src/components/form-fields/TextField';
import { DetachConfirmation } from 'src/containers/charging-location/Preference/DetachConfirmation/DetachConfirmation';

import * as styled from './styles';

interface Props {
  visible: boolean;
  loading?: boolean;
  onConfirm: (submitForm: NameForm) => void;
  initialName?: string;
  onDetachChargingLocation?: VoidFunction;
}

interface NameForm {
  name: string;
}

const Confirmation: FC<Props> = ({ visible, loading, onConfirm, initialName, onDetachChargingLocation }) => {
  const intl = useIntl();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const schema = useMemo(
    () =>
      z.object({
        name: z
          .string()
          .trim()
          .min(1, intl.formatMessage({ id: 'validation.name.required' })),
      }),
    [intl],
  );

  const form = useForm({
    mode: 'onBlur',
    defaultValues: { name: initialName ?? '' },
    resolver: zodResolver(schema),
  });

  return (
    <styled.ConfirmationOverlay
      visible={visible}
      title={intl.formatMessage({ id: 'common.preferences.chargingLocation.confirmation.title' })}
      styles={{
        content: {
          marginTop: spacings.xxs,
          marginBottom: spacings.xxs,
        },
      }}
    >
      <TextField<NameForm>
        control={form.control}
        placeholder={intl.formatMessage({ id: 'common.preferences.chargingLocation.confirmation.name.placeholder' })}
        withErrorHeight
        name="name"
      />
      <styled.ButtonsContainer>
        <Button
          title={intl.formatMessage({ id: 'common.preferences.chargingLocation.confirmation.submit' })}
          onPress={form.handleSubmit(onConfirm)}
          loading={loading}
          disabled={loading || !form.formState.isValid}
        />
        {onDetachChargingLocation && (
          <>
            <Button
              type="secondary"
              title={intl.formatMessage({ id: 'common.preferences.chargingLocation.confirmation.detach' })}
              loading={loading}
              onPress={() => setShowConfirmation(true)}
            />
            {showConfirmation && (
              <DetachConfirmation onConfirm={onDetachChargingLocation} onCancel={() => setShowConfirmation(false)} />
            )}
          </>
        )}
      </styled.ButtonsContainer>
    </styled.ConfirmationOverlay>
  );
};

export default React.memo(Confirmation);
