import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import { Animated, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { colors } from '../../theme';

export const Container = styled(_Container)`
  align-items: center;
  align-self: stretch;
  width: 100%;
`;

export const Header = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  gap: ${spacings.xxxs}px;
`;

export const ToggleContent = styled(Animated.View)`
  align-self: stretch;
`;

export const SubTitle = styled(Typography).attrs({ variant: 'hint' })`
  color: ${colors.pidgeonGray};
`;

export const Title = styled(Typography).attrs({ variant: 'h1' })`
  text-align: center;
  color: ${colors.white};
`;
