import React, { ComponentProps, FC, PropsWithChildren } from 'react';
import { RefreshControl } from 'react-native';

import { useUserRefresh } from 'src/hooks/useUserRefresh';
import { colors } from 'src/theme';

import * as styled from './styles';

interface OwnProps {
  refresh: () => Promise<void>;
}

type Props = PropsWithChildren<OwnProps & ComponentProps<typeof styled.ScrollView>>;

const Scroll: FC<Props> = ({ refresh, children, ...scrollViewProps }) => {
  const [refreshing, handleRefresh] = useUserRefresh(refresh);

  return (
    <styled.ScrollView
      {...scrollViewProps}
      scrollEventThrottle={16}
      refreshControl={<RefreshControl tintColor={colors.deepNavy} refreshing={refreshing} onRefresh={handleRefresh} />}
    >
      {children}
    </styled.ScrollView>
  );
};

export default React.memo(Scroll);
