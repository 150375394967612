import { APP_BACKEND_URL } from '@env';
import axios from 'axios';

import {
  PlaceAutocompleteResponse,
  PlaceDetails,
  PlaceDetailsResponse,
  PlacePrediction,
  Region,
  ReverseGeocodeResponse,
} from './types';
import { parseAddress } from './utils';

const client = axios.create({ baseURL: [APP_BACKEND_URL, 'maps'].join('/') });

export const _client = client;

export const getPlaceAutocompletePredictions = async (input: string, jwt: string): Promise<PlacePrediction[]> =>
  client
    .get<PlaceAutocompleteResponse>('maps/api/place/autocomplete/json', {
      params: {
        input,
        location_type: 'ROOFTOP',
      },
      headers: {
        Authorization: jwt,
      },
    })
    .then(response => {
      if (response.data.status === 'ZERO_RESULTS') {
        return [];
      }
      if (response.data.status !== 'OK') {
        throw new Error(`Place autocomplete request failed, status: ${response.data.status}`);
      }
      return response.data.predictions.map(prediction => ({
        placeId: prediction.place_id,
        formattedAddress: prediction.description,
      }));
    })
    .catch(error => {
      console.error('Cannot fetch place autocomplete', error);
      throw error;
    });

export const getPlaceDetails = async (placeId: string, jwt: string): Promise<PlaceDetails> =>
  client
    .get<PlaceDetailsResponse>('maps/api/place/details/json', {
      params: {
        place_id: placeId,
      },
      headers: {
        Authorization: jwt,
      },
    })
    .then(response => {
      if (response.data.status !== 'OK') {
        throw new Error(`Place details request failed, status: ${response.data.status}`);
      }
      const { place_id, formatted_address, geometry, address_components } = response.data.result;
      return {
        placeId: place_id,
        address: parseAddress(address_components),
        formattedAddress: formatted_address,
        region: {
          latitude: geometry.location.lat,
          longitude: geometry.location.lng,
        },
      };
    })
    .catch(error => {
      console.error('Cannot fetch place details', error);
      throw error;
    });

export const getPlaceRegionPredictions = async (region: Region, jwt: string): Promise<PlacePrediction[]> =>
  client
    .get<ReverseGeocodeResponse>('maps/api/geocode/json', {
      params: {
        latlng: `${region.latitude},${region.longitude}`,
        location_type: 'ROOFTOP',
      },
      headers: {
        Authorization: jwt,
      },
    })
    .then(response => {
      if (response.data.status === 'ZERO_RESULTS') {
        return [];
      }
      if (response.data.status !== 'OK') {
        throw new Error(`Address lookup request failed, status: ${response.data.status}`);
      }
      return response.data.results.map(result => ({
        placeId: result.place_id,
        formattedAddress: result.formatted_address,
      }));
    })
    .catch(error => {
      console.error('Cannot fetch address lookup', error);
      throw error;
    });
